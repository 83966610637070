import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { EinstellungenService } from '@data/api-gateway/service/einstellungen.service';
import { LackmessungPosition, ProduktStatus } from '@data/domain/schema/enum';
import { Lackmessung, LackmessungInput, LackmessungMessung, Notiz } from '@data/domain/schema/type';
import { ProduktLackmessungService } from '@data/domain/service/feature';
import { ProduktDetailFeatureInputComponent } from '@modules/produkt/component/produkt-detail-feature/produkt-detail-feature.component';
import { FeatureFieldArray, FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ModelFileConfig } from '@modules/produkt/config/produkt-model-config';
import { ProduktDetailLackmessungFormViewFactory } from '@modules/produkt/factory/lackmessung/produkt-detail-lackmessung-form-view.factory';
import { ProduktDetailLackmessungMessungFormViewFactory } from '@modules/produkt/factory/lackmessung/produkt-detail-lackmessung-messung-form-view.factory';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ModelFileService } from '@modules/produkt/service/model-file.service';
import { ProduktConfigResolveService } from '@modules/produkt/service/produkt-config-resolve.service';
import { ProduktDetailFeatureNotizenService } from '@modules/produkt/service/produkt-detail-feature-notizen.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { ExpansionPanelCustomComponent } from '@shared/component/layout/expansion/expansion-panel-custom/expansion-panel-custom.component';
import { ModelLoadResult } from '@shared/component/three/gltf/gltf.component';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { AbstractViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { EnumValues } from '@shared/helper/values';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { Viewport, ViewportService } from '@shared/service/viewport.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';

enum ProduktDetailLackmessungDisplay {
    NONE = 0,
    MODELL = 1,
    MANUELL = 2,
    GESAMT = 3,
}

interface ProduktDetailLackmessungDialogData {
    form: ViewFormGroup;
    fields: FeatureFields;
}

@Component({
    selector: 'app-produkt-detail-lackmessung',
    templateUrl: './produkt-detail-lackmessung.component.html',
    styleUrls: ['./produkt-detail-lackmessung.component.scss'],
    providers: [ProduktDetailFeatureNotizenService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailLackmessungComponent
    extends ProduktDetailFeatureInputComponent<Lackmessung, LackmessungInput>
    implements OnInit, OnDestroy {
    private messungenName = PRODUKT_CONFIG_FEATURES.Lackmessung.fields.Messungen.name;
    name = PRODUKT_CONFIG_FEATURES.Lackmessung.name;

    trackByField = TrackBy.trackByField;

    notizen$: Observable<Notiz[]>;
    viewport$: Observable<Viewport>;
    viewport = Viewport;
    mobileLandscapeOptimization: Subscription;
    statusEnum = ProduktStatus;

    messungen: ViewFormArray;
    messungenFields: FeatureFields;

    rows$: Observable<AbstractViewFormControl[]>;

    modelFileConfigs: ModelFileConfig[];
    modelDisabled$ = new BehaviorSubject<boolean>(false);

    display = ProduktDetailLackmessungDisplay.NONE;
    displayEnum = ProduktDetailLackmessungDisplay;
    positionen = new EnumValues(LackmessungPosition);

    @ViewChild('dialog', { static: true })
    dialogTemplate: TemplateRef<any>;

    constructor(
        produktConfigResolveService: ProduktConfigResolveService,
        produktDetailResolveService: ProduktDetailResolveService,
        produktLackmessungService: ProduktLackmessungService,
        private readonly notizenService: ProduktDetailFeatureNotizenService,
        private readonly formViewFactory: ProduktDetailLackmessungFormViewFactory,
        private readonly messungformViewFactory: ProduktDetailLackmessungMessungFormViewFactory,
        private readonly modelFileService: ModelFileService,
        private readonly snackBarService: SnackBarService,
        private readonly viewportService: ViewportService,
        private readonly einstellungenService: EinstellungenService,
        private readonly templateDialogService: TemplateDialogService,
    ) {
        super(produktConfigResolveService, produktDetailResolveService, produktLackmessungService);
        Assert.notNullOrUndefined(notizenService, 'notizenService');
        Assert.notNullOrUndefined(formViewFactory, 'formViewFactory');
        Assert.notNullOrUndefined(messungformViewFactory, 'messungformViewFactory');
        Assert.notNullOrUndefined(modelFileService, 'formViewFactory');
        Assert.notNullOrUndefined(snackBarService, 'modelFileService');
        Assert.notNullOrUndefined(viewportService, 'viewportService');
        Assert.notNullOrUndefined(einstellungenService, 'einstellungenService');
        Assert.notNullOrUndefined(templateDialogService, 'templateDialogService');
    }

    ngOnInit(): void {
        this.notizen$ = this.notizenService.init(this.produkt, this.name);
        this.viewport$ = this.viewportService.observe();
        this.modelFileConfigs = this.modelFileService.get(
            this.produkt.fahrzeug.fahrzeugart,
            this.produkt.fahrzeug.bauform,
        );
        this.mobileLandscapeOptimization = this.viewportService.mobileLandscapeOptimization();
        this.setModelAndDisplay();
        this.init(this.name);
    }

    ngOnDestroy(): void {
        this.mobileLandscapeOptimization.unsubscribe();
        super.ngOnDestroy();
    }

    onDisplayChange(display: ProduktDetailLackmessungDisplay): void {
        Assert.notNullOrUndefined(display, 'display');
        this.display = display;
    }

    onModelLoad(modelLoadResult: ModelLoadResult): void {
        Assert.notNullOrUndefined(modelLoadResult, 'modelLoadResult');
        if (modelLoadResult === ModelLoadResult.None) {
            this.modelDisabled$.next(true);
            this.onDisplayChange(ProduktDetailLackmessungDisplay.MANUELL);
            this.snackBarService.warning('modell.couldNotLoad');
        } else if (modelLoadResult === ModelLoadResult.Fallback) {
            this.snackBarService.info('modell.fallback');
        }
    }

    onNotizenChange(notizen: Notiz[]): void {
        Assert.notNullOrUndefined(notizen, 'notizen');
        this.notizenService.save(notizen).pipe(take(1)).subscribe();
    }

    onPositionSelect(position: LackmessungPosition): void {
        Assert.notNullOrUndefined(position, 'position');
        this.createMessung(position);
    }

    onPositionAdd(bezeichnung: string): void {
        this.createMessung(bezeichnung);
    }

    onRowOpen(row: ViewFormGroup): void {
        Assert.notNullOrUndefined(row, 'row');
        const index = this.messungen.controls.indexOf(row);
        this.editMessung(index, row.getRawValue());
    }

    onRowOpenByIndex(index: number, item: any, panel: ExpansionPanelCustomComponent, $event: MouseEvent): void {
        Assert.notNullOrUndefined(index, 'index');
        $event.stopPropagation();
        this.editMessung(index, item);
    }

    onRowRemoveByIndex(index: number, panel: ExpansionPanelCustomComponent, $event: MouseEvent): void {
        Assert.notNullOrUndefined(index, 'index');
        panel.close();
        $event.stopPropagation();
        this.messungen.removeAt(index);
    }

    onRowRemove(row: ViewFormGroup): void {
        Assert.notNullOrUndefined(row, 'row');
        const index = this.messungen.controls.indexOf(row);
        this.messungen.removeAt(index);
    }

    drop(event: CdkDragDrop<string[]>): void {
        const item = this.messungen.at(event.previousIndex);
        this.messungen.controls.splice(event.previousIndex, 1);
        this.messungen.controls.splice(event.currentIndex, 0, item);
        this.messungen.updateValueAndValidity();
    }

    protected createForm(): ViewFormGroup {
        const form = this.formViewFactory.create(this.produkt.lackmessung, this.fields);
        this.messungen = form.get(this.messungenName) as ViewFormArray;
        this.messungenFields = (
            this.fields.find((x: FeatureFieldArray) => x.arrayName === this.messungenName) as FeatureFieldArray
        ).fields;
        this.rows$ = this.getRows$();
        return form;
    }

    private createMessung(position: LackmessungPosition | string): void {
        let title = '';
        let form = null;
        if (typeof position === 'string') {
            title = position;
            form = this.messungformViewFactory.create({ position: null, bezeichnung: position }, this.messungenFields);
        } else {
            title = `${this.name}.${LackmessungPosition[position].toLowerCase()}`;
            form = this.messungformViewFactory.create({ position }, this.messungenFields);
        }

        const fields = this.messungenFields;
        const buttons = [
            this.templateDialogService.getCancelButtonSetting(),
            this.templateDialogService.getSaveButtonSetting(),
        ];
        const data: ProduktDetailLackmessungDialogData = { form, fields };

        this.templateDialogService
            .openTemplate(title, buttons, this.dialogTemplate, data)
            .pipe(take(1))
            .subscribe((result) => {
                if (result?.name === this.templateDialogService.getSaveButtonSetting().title) {
                    this.messungen.push(result.data.form);
                }
            });
    }

    private editMessung(index: number, messung: LackmessungMessung): void {
        let title = '';
        if (messung.position) {
            title = `${this.name}.${LackmessungPosition[messung.position].toLowerCase()}`;
        } else {
            title = messung.bezeichnung;
        }

        const fields = this.messungenFields;
        const form = this.messungformViewFactory.create(messung, fields);
        const buttons = [
            this.templateDialogService.getCancelButtonSetting(),
            this.templateDialogService.getSaveButtonSetting(),
        ];
        const data: ProduktDetailLackmessungDialogData = { form, fields };

        this.templateDialogService
            .openTemplate(title, buttons, this.dialogTemplate, data)
            .pipe(take(1))
            .subscribe((result) => {
                if (result?.name === this.templateDialogService.getSaveButtonSetting().title) {
                    (this.messungen.controls[index] as ViewFormGroup) = result.data.form;
                    this.messungen.updateValueAndValidity();
                }
            });
    }

    private getRows$(): Observable<AbstractViewFormControl[]> {
        return this.messungen.valueChanges.pipe(
            startWith({}),
            map(() => [...this.messungen.controls] as AbstractViewFormControl[]),
        );
    }

    private setModelAndDisplay() {
        this.einstellungenService
            .getBenutzer()
            .pipe(take(1))
            .subscribe((benutzerEinstellungen) => {
                if (benutzerEinstellungen?.deactivate3dModel) {
                    this.modelDisabled$.next(benutzerEinstellungen.deactivate3dModel);
                    this.display = ProduktDetailLackmessungDisplay.MANUELL;
                } else {
                    this.modelDisabled$.next(false);
                    this.display = ProduktDetailLackmessungDisplay.MODELL;
                }
            });
    }
}
