import { DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { AuftragArt, ProduktArt, ProduktStatus } from '@data/domain/schema/enum';
import { Auftrag, AuftragInput, Notiz, Produkt } from '@data/domain/schema/type';
import { ProduktAuftragService } from '@data/domain/service/feature';
import { TextbausteineService } from '@data/domain/service/textbausteine.service';
import { ProduktDetailFeatureInputComponent } from '@modules/produkt/component/produkt-detail-feature/produkt-detail-feature.component';
import { PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ProduktDetailAuftragFormViewFactory } from '@modules/produkt/factory/auftrag/produkt-detail-auftrag-form-view.factory';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ProduktConfigResolveService } from '@modules/produkt/service/produkt-config-resolve.service';
import { ProduktDetailFeatureNotizenService } from '@modules/produkt/service/produkt-detail-feature-notizen.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { TextbausteineComponent } from '@shared/component/form-controls/textbausteine/textbausteine.component';
import { Assert } from '@shared/helper/assert';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { EnumValues } from '@shared/helper/values';
import { Viewport, ViewportService } from '@shared/service/viewport.service';
import { Observable, take } from 'rxjs';

@Component({
    selector: 'app-produkt-detail-auftrag',
    templateUrl: './produkt-detail-auftrag.component.html',
    styleUrls: ['./produkt-detail-auftrag.component.scss'],
    providers: [ProduktDetailFeatureNotizenService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailAuftragComponent
    extends ProduktDetailFeatureInputComponent<Auftrag, AuftragInput>
    implements OnInit, AfterViewInit {
    trackByField = TrackBy.trackByField;
    trackByInstance = TrackBy.trackByInstance;

    feature = PRODUKT_CONFIG_FEATURES.Auftrag.name;
    fieldAuftragsbeschreibung = PRODUKT_CONFIG_FEATURES.Auftrag.fields.Auftragsbeschreibung.name;
    fieldBesichtigungsbedingungen = PRODUKT_CONFIG_FEATURES.Auftrag.fields.Besichtigungsbedingungen.name;
    fieldBemerkungen = PRODUKT_CONFIG_FEATURES.Auftrag.fields.Bemerkungen.name;
    produktArt = ProduktArt;
    parentProdukt: Produkt = null;

    notizen$: Observable<Notiz[]>;

    art = new EnumValues(AuftragArt);
    artDisabled = {
        [AuftragArt.Zentral]: true,
    };

    @ViewChild('auftragsbeschreibung')
    auftragsbeschreibungElement: TextbausteineComponent;

    @ViewChild('besichtigungsbedingungen')
    besichtigungsbedingungenElement: TextbausteineComponent;

    @ViewChild('bemerkungen')
    bemerkungenElement: TextbausteineComponent;

    protected viewport$: Observable<Viewport>;
    protected viewport = Viewport;

    constructor(
        produktConfigResolveService: ProduktConfigResolveService,
        produktDetailResolveService: ProduktDetailResolveService,
        produktAuftragService: ProduktAuftragService,
        private readonly datePipe: DatePipe,
        private readonly textbausteineService: TextbausteineService,
        private readonly formViewFactory: ProduktDetailAuftragFormViewFactory,
        private readonly viewportService: ViewportService,
        private readonly notizenService: ProduktDetailFeatureNotizenService,
    ) {
        super(produktConfigResolveService, produktDetailResolveService, produktAuftragService);
        Assert.notNullOrUndefined(formViewFactory, 'formViewFactory');
        Assert.notNullOrUndefined(notizenService, 'notizenService');
    }

    ngOnInit(): void {
        const name = PRODUKT_CONFIG_FEATURES.Auftrag.name;
        this.notizen$ = this.notizenService.init(this.produkt, name);
        this.initHinweise();
        this.init(name);
        this.viewport$ = this.viewportService.observe();
    }

    ngAfterViewInit(): void {
        this.prefillForm();
    }

    onNotizenChange(notizen: Notiz[]): void {
        Assert.notNullOrUndefined(notizen, 'notizen');
        this.notizenService.save(notizen).pipe(take(1)).subscribe();
    }

    protected createForm(): ViewFormGroup {
        return this.formViewFactory.create(this.produkt.auftrag, this.fields, this.produkt.art);
    }

    private initHinweise(): void {
        this.produkt.auftrag.hinweise = this.produkt.auftrag.hinweise || [
            {
                __typename: null,
                id: null,
                name: 'besichtigungsbedingungen',
                vorhanden: null,
            },
            {
                __typename: null,
                id: null,
                name: 'besichtigungsbedingungenAusreichend',
                vorhanden: null,
            },
            {
                __typename: null,
                id: null,
                name: 'besichtigungsbedingungenNichtAusreichend',
                vorhanden: null,
            },
            {
                __typename: null,
                id: null,
                name: 'probefahrt',
                vorhanden: null,
            },
            {
                __typename: null,
                id: null,
                name: 'motorprobelauf',
                vorhanden: null,
            },
            {
                __typename: null,
                id: null,
                name: 'fahrzeugboden',
                vorhanden: null,
            },
            {
                __typename: null,
                id: null,
                name: 'fehlerspeicher',
                vorhanden: null,
            },
        ];
    }

    private prefillDatumUhrzeit(day: string, time: string, addHours: number = 0): void {
        if (this.produkt.status === ProduktStatus.Offen && !this.form.get(day).value) {
            const currentDate = new Date();
            currentDate.setMinutes(5 * Math.ceil(currentDate.getMinutes() / 5));
            currentDate.setHours(currentDate.getHours() + addHours);
            this.form.patchValue({ [day]: currentDate });

            if (!this.form.get(time).value) {
                this.form.patchValue({ [time]: this.datePipe.transform(currentDate, 'HH:mm') });
            }
        }
    }

    private prefillForm(): void {
        if (this.form?.get(this.fieldAuftragsbeschreibung) && this.produkt?.auftrag?.auftragsbeschreibung === null) {
            this.textbausteineService.prefillWithStandardTextbausteine(
                this.feature,
                this.fieldAuftragsbeschreibung,
                this.produkt.art,
                this.form,
            );
        }
        if (
            this.form?.get(this.fieldBesichtigungsbedingungen) &&
            this.produkt?.auftrag?.besichtigungsbedingungen === null
        ) {
            this.textbausteineService.prefillWithStandardTextbausteine(
                this.feature,
                this.fieldBesichtigungsbedingungen,
                this.produkt.art,
                this.form,
            );
        }
        if (this.form?.get(this.fieldBemerkungen) && this.produkt.auftrag?.bemerkungen === null) {
            this.textbausteineService.prefillWithStandardTextbausteine(
                this.feature,
                this.fieldBemerkungen,
                this.produkt.art,
                this.form,
            );
        }
    }
}
