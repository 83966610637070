import { ChangeDetectionStrategy, Component } from '@angular/core';
import { GridTile } from '@shared/component';

@Component({
    selector: 'app-verwaltung',
    templateUrl: './verwaltung.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerwaltungComponent {
    tiles: GridTile[] = [
        {
            icon: 'settings_my',
            link: '/verwaltung/einstellungen',
            id: 'id-meine-einstellungen',
            title: 'Meine Einstellungen',
        },
        {
            icon: 'text-module-settings',
            link: '/verwaltung/textbausteine',
            id: 'id-textbausteine',
            title: 'Textbausteine',
        },
        {
            icon: 'settings_my',
            link: '/verwaltung/kalkulationFaktoren',
            id: 'id-kalkulation-faktoren',
            title: 'Kalkulatorische Faktoren',
        },
    ];
}
