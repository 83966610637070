import { ProduktArt } from '@data/domain/schema/enum';
import { ProduktFeatureConfig } from '@modules/produkt/config/produkt-feature-config';

export interface FeatureField {
    name: string;
}

export interface FeatureFieldGroup {
    groupName: string;
    fields: FeatureField[];
}

export type FeatureFields = (FeatureField | FeatureFieldGroup | FeatureFieldArray)[];

export interface ProduktFeatureFieldsMap {
    [name: string]: FeatureFields;
}

export interface FeatureFieldArray {
    arrayName: string;
    fields: FeatureFields;
}

export interface Feature {
    name: string;
    fields: FeatureFields;
}

export interface ProduktConfig {
    features: Feature[];
}

export interface ProduktConfigFeatures {
    [key: string]: {
        name: string;
        fields: {
            [key: string]: {
                name: string;
                fields?: {
                    [key: string]: {
                        name: string;
                        fields?: {
                            [key: string]: {
                                name: string;
                                fields?: {
                                    [key: string]: {
                                        name: string;
                                        fields?: {
                                            [key: string]: {
                                                name: string;
                                            };
                                        };
                                    };
                                };
                            };
                        };
                    };
                };
            };
        };
    };
}

export const PRODUKT_CONFIG_FEATURES: ProduktConfigFeatures = {
    Auftrag: {
        name: 'auftrag',
        fields: {
            Art: {
                name: 'art',
            },
            Nummer: {
                name: 'nummer',
            },
            Vorgangsnummer: {
                name: 'vorgangsnummer',
            },
            Auftragstyp: {
                name: 'auftragstyp',
            },
            BezugsAuftragsnummer: {
                name: 'bezugsAuftragsnummer',
            },
            Kommentar: {
                name: 'kommentar',
            },
            ErstellungsTag: {
                name: 'erstellungsTag',
            },
            ErstellungsUhrzeit: {
                name: 'erstellungsUhrzeit',
            },
            BesichtigungsTag: {
                name: 'besichtigungsTag',
            },
            BesichtigungsUhrzeit: {
                name: 'besichtigungsUhrzeit',
            },
            FertigstellungsTag: {
                name: 'fertigstellungsTag',
            },
            FertigstellungsUhrzeit: {
                name: 'fertigstellungsUhrzeit',
            },
            Bemerkungen: {
                name: 'bemerkungen',
                fields: {
                    Id: {
                        name: 'id',
                    },
                    Kurztext: {
                        name: 'kurztext',
                    },
                    Langtext: {
                        name: 'langtext',
                    },
                    Verfuegbarkeit: {
                        name: 'verfuegbarkeit',
                    },
                    Produkte: {
                        name: 'produkte',
                    },
                    Feature: {
                        name: 'feature',
                    },
                    Feld: {
                        name: 'feld',
                    },
                    Tags: {
                        name: 'tags',
                    },
                    Standard: {
                        name: 'standard',
                    },
                    ErstelltAm: {
                        name: 'erstelltAm',
                    },
                    ErstelltVon: {
                        name: 'erstelltVon',
                    },
                },
            },
            Auftragsbeschreibung: {
                name: 'auftragsbeschreibung',
                fields: {
                    Id: {
                        name: 'id',
                    },
                    Kurztext: {
                        name: 'kurztext',
                    },
                    Langtext: {
                        name: 'langtext',
                    },
                    Verfuegbarkeit: {
                        name: 'verfuegbarkeit',
                    },
                    Produkte: {
                        name: 'produkte',
                    },
                    Feature: {
                        name: 'feature',
                    },
                    Feld: {
                        name: 'feld',
                    },
                    Tags: {
                        name: 'tags',
                    },
                    Standard: {
                        name: 'standard',
                    },
                    ErstelltAm: {
                        name: 'erstelltAm',
                    },
                    ErstelltVon: {
                        name: 'erstelltVon',
                    },
                },
            },
            Besichtigungsbedingungen: {
                name: 'besichtigungsbedingungen',
                fields: {
                    Id: {
                        name: 'id',
                    },
                    Kurztext: {
                        name: 'kurztext',
                    },
                    Langtext: {
                        name: 'langtext',
                    },
                    Verfuegbarkeit: {
                        name: 'verfuegbarkeit',
                    },
                    Produkte: {
                        name: 'produkte',
                    },
                    Feature: {
                        name: 'feature',
                    },
                    Feld: {
                        name: 'feld',
                    },
                    Tags: {
                        name: 'tags',
                    },
                    Standard: {
                        name: 'standard',
                    },
                    ErstelltAm: {
                        name: 'erstelltAm',
                    },
                    ErstelltVon: {
                        name: 'erstelltVon',
                    },
                },
            },
            Bemerkung: {
                name: 'bemerkung',
            },
            Hinweise: {
                name: 'hinweise',
                fields: {
                    Name: {
                        name: 'name',
                    },
                    Vorhanden: {
                        name: 'vorhanden',
                    },
                },
            },
        },
    },
    VtiAuftrag: {
        name: 'vtiAuftrag',
        fields: {
            Art: {
                name: 'art',
            },
            Nummer: {
                name: 'nummer',
            },
            Vorgangsnummer: {
                name: 'vorgangsnummer',
            },
            Auftragstyp: {
                name: 'auftragstyp',
            },
            BezugsAuftragsnummer: {
                name: 'bezugsAuftragsnummer',
            },
            Bemerkung: {
                name: 'bemerkung',
            },
        },
    },
    Fahrzeug: {
        name: 'fahrzeug',
        fields: {
            Kennzeichen: {
                name: 'kennzeichen',
            },
            Identnummer: {
                name: 'identnummer',
            },
            TypCode: {
                name: 'typCode',
            },
            HerstellerCode: {
                name: 'herstellerCode',
            },
            Erstzulassung: {
                name: 'erstzulassung',
            },
            LetzteZulassung: {
                name: 'letzteZulassung',
            },
            Laufleistung: {
                name: 'laufleistung',
            },
            LaufleistungEinheit: {
                name: 'laufleistungEinheit',
            },
            LaufleistungBeschreibung: {
                name: 'laufleistungBeschreibung',
            },
            Laufleistungen: {
                name: 'laufleistungen',
                fields: {
                    Id: {
                        name: 'id',
                    },
                    Wert: {
                        name: 'wert',
                    },
                    Einheit: {
                        name: 'einheit',
                    },
                    Beschreibung: {
                        name: 'beschreibung',
                    },
                },
            },
            Fahrzeugart: {
                name: 'fahrzeugart',
            },
            FahrzeugartManuell: {
                name: 'fahrzeugartManuell',
            },
            Bauform: {
                name: 'bauform',
            },
            BauformManuell: {
                name: 'bauformManuell',
            },
            Hersteller: {
                name: 'hersteller',
            },
            Modell: {
                name: 'modell',
            },
            Untertyp: {
                name: 'untertyp',
            },
            Lackcode: {
                name: 'lackcode',
            },
            Innenausstattung: {
                name: 'innenausstattung',
            },
            Polsterfarbe: {
                name: 'polsterfarbe',
            },
            Polstermaterial: {
                name: 'polstermaterial',
            },
            Hubraum: {
                name: 'hubraum',
            },
            Leistung: {
                name: 'leistung',
            },
            Zylinder: {
                name: 'zylinder',
            },
            Schadstoffklasse: {
                name: 'schadstoffklasse',
            },
            Leergewicht: {
                name: 'leergewicht',
            },
            ZulaessigesGesamtgewicht: {
                name: 'zulaessigesGesamtgewicht',
            },
            MasseLaenge: {
                name: 'masseLaenge',
            },
            MasseBreite: {
                name: 'masseBreite',
            },
            MasseHoehe: {
                name: 'masseHoehe',
            },
            AnzahlTueren: {
                name: 'anzahlTueren',
            },
            AnzahlSitze: {
                name: 'anzahlSitze',
            },
            Antriebstechnologie: {
                name: 'antriebstechnologie',
            },
            Antriebsart: {
                name: 'antriebsart',
            },
            Getriebeart: {
                name: 'getriebeart',
            },
            AnzahlGaenge: {
                name: 'anzahlGaenge',
            },
            AnzahlVorbesitzer: {
                name: 'anzahlVorbesitzer',
            },
            ScheckheftGepflegt: {
                name: 'scheckheftGepflegt',
            },
            HvBatterie: {
                name: 'hvBatterie',
            },
            AcFahrzeugart: {
                name: 'acFahrzeugart',
            },
            AcBauform: {
                name: 'acBauform',
            },
            AcLackfarbe: {
                name: 'acLackfarbe',
            },
            AcLackierung: {
                name: 'acLackierung',
            },
            AcAntriebstechnologie: {
                name: 'acAntriebstechnologie',
            },
            AcGetriebeart: {
                name: 'acGetriebeart',
            },
            AcSchadstoffklasse: {
                name: 'acSchadstoffklasse',
            },
            AcEhemaligeNutzung: {
                name: 'acEhemaligeNutzung',
            },
            Bemerkungen: {
                name: 'bemerkungen',
                fields: {
                    Id: {
                        name: 'id',
                    },
                    Kurztext: {
                        name: 'kurztext',
                    },
                    Langtext: {
                        name: 'langtext',
                    },
                    Verfuegbarkeit: {
                        name: 'verfuegbarkeit',
                    },
                    Produkte: {
                        name: 'produkte',
                    },
                    Feature: {
                        name: 'feature',
                    },
                    Feld: {
                        name: 'feld',
                    },
                    Tags: {
                        name: 'tags',
                    },
                    Standard: {
                        name: 'standard',
                    },
                    ErstelltAm: {
                        name: 'erstelltAm',
                    },
                    ErstelltVon: {
                        name: 'erstelltVon',
                    },
                },
            },
            DatECode: {
                name: 'datECode',
            },
            FahrzeugExternalServiceReference: {
                name: 'fahrzeugExternalServiceReference',
            },
            VinAbfrageErfolgt: {
                name: 'vinAbfrageErfolgt',
            },
        },
    },
    Adressen: {
        name: 'adressen',
        fields: {
            Adressen: {
                name: 'adressen',
                fields: {
                    Id: {
                        name: 'id',
                    },
                    CreatedAt: {
                        name: 'createdAt',
                    },
                    Firma: {
                        name: 'firma',
                    },
                    Anrede: {
                        name: 'anrede',
                    },
                    AnredeFreitext: {
                        name: 'anredeFreitext',
                    },
                    Vorname: {
                        name: 'vorname',
                    },
                    Name: {
                        name: 'name',
                    },
                    StrasseNr: {
                        name: 'strasseNr',
                    },
                    Postleitzahl: {
                        name: 'postleitzahl',
                    },
                    Ort: {
                        name: 'ort',
                    },
                    Telefon: {
                        name: 'telefon',
                    },
                    Mobil: {
                        name: 'mobil',
                    },
                    Email: {
                        name: 'email',
                    },
                    Arten: {
                        name: 'arten',
                    },
                    ExternalId: {
                        name: 'externalId',
                    },
                    Bestellnummer: {
                        name: 'bestellnummer',
                    },
                    // Calculated Fields
                    NameGroup: {
                        name: 'nameGroup',
                    },
                    DruckArt: {
                        name: 'druckArt',
                    },
                    Versand: {
                        name: 'versand',
                    },
                },
            },
            // Calculated Fields
            Auftraggeber: {
                name: 'auftraggeber',
            },
        },
    },
    Uebersicht: {
        name: 'uebersicht',
        fields: {
            Deckblatt: {
                name: 'deckblatt',
            },
            VorneLinks: {
                name: 'vorneLinks',
            },
            VorneRechts: {
                name: 'vorneRechts',
            },
            HintenLinks: {
                name: 'hintenLinks',
            },
            HintenRechts: {
                name: 'hintenRechts',
            },
            Innenraum: {
                name: 'innenraum',
            },
            Sonstiges: {
                name: 'sonstiges',
                fields: {
                    Bilder: {
                        name: 'bilder',
                    },
                    Bezeichnung: {
                        name: 'bezeichnung',
                    },
                    Bezeichnungen: {
                        name: 'bezeichnungen',
                        fields: {
                            Id: {
                                name: 'id',
                            },
                            Kurztext: {
                                name: 'kurztext',
                            },
                            Langtext: {
                                name: 'langtext',
                            },
                            Verfuegbarkeit: {
                                name: 'verfuegbarkeit',
                            },
                            Produkte: {
                                name: 'produkte',
                            },
                            Feature: {
                                name: 'feature',
                            },
                            Feld: {
                                name: 'feld',
                            },
                            Tags: {
                                name: 'tags',
                            },
                            Standard: {
                                name: 'standard',
                            },
                            ErstelltAm: {
                                name: 'erstelltAm',
                            },
                            ErstelltVon: {
                                name: 'erstelltVon',
                            },
                        },
                    },
                },
            },
            FahrzeugVorhanden: {
                name: 'fahrzeugVorhanden',
            },
        },
    },
    Raeder: {
        name: 'raeder',
        fields: {
            RadSaetze: {
                name: 'radSaetze',
                fields: {
                    Rads: {
                        name: 'rads',
                        fields: {
                            RadPosition: {
                                name: 'radPosition',
                            },
                            Hersteller: {
                                name: 'hersteller',
                            },
                            ReifenTyp: {
                                name: 'reifenTyp',
                            },
                            RadFelge: {
                                name: 'radFelge',
                            },
                            Reifenbreite: {
                                name: 'reifenbreite',
                            },
                            Querschnitt: {
                                name: 'querschnitt',
                            },
                            Bauart: {
                                name: 'bauart',
                            },
                            Felgengroesse: {
                                name: 'felgengroesse',
                            },
                            Tragfaehigkeit1: {
                                name: 'tragfaehigkeit1',
                            },
                            Geschwindigkeitsindex: {
                                name: 'geschwindigkeitsindex',
                            },
                            Profil: {
                                name: 'profil',
                            },
                            Zulaessig: {
                                name: 'zulaessig',
                            },
                            Felgenhersteller: {
                                name: 'felgenhersteller',
                            },
                            Runflat: {
                                name: 'runflat',
                            },
                            Dot: {
                                name: 'dot',
                            },
                        },
                    },
                    Lagerort: {
                        name: 'lagerort',
                    },
                },
            },
            Notlaufeinrichtung: {
                name: 'notlaufeinrichtung',
            },
            NotlaufeinrichtungProfiltiefe: {
                name: 'notlaufeinrichtungProfiltiefe',
            },
            Bemerkungen: {
                name: 'bemerkungen',
                fields: {
                    Id: {
                        name: 'id',
                    },
                    Kurztext: {
                        name: 'kurztext',
                    },
                    Langtext: {
                        name: 'langtext',
                    },
                    Verfuegbarkeit: {
                        name: 'verfuegbarkeit',
                    },
                    Produkte: {
                        name: 'produkte',
                    },
                    Feature: {
                        name: 'feature',
                    },
                    Feld: {
                        name: 'feld',
                    },
                    Tags: {
                        name: 'tags',
                    },
                    Standard: {
                        name: 'standard',
                    },
                    ErstelltAm: {
                        name: 'erstelltAm',
                    },
                    ErstelltVon: {
                        name: 'erstelltVon',
                    },
                },
            },
        },
    },
    NfzRaeder: {
        name: 'nfzRaeder',
        fields: {
            RadSaetze: {
                name: 'radSaetze',
                fields: {
                    Achsen: {
                        name: 'achsen',
                        fields: {
                            Bewertung: {
                                name: 'bewertung',
                            },
                            Seiten: {
                                name: 'seiten',
                                fields: {
                                    Seite: {
                                        name: 'seite',
                                    },
                                    AchsenRaeder: {
                                        name: 'achsenRaeder',
                                        fields: {
                                            Position: {
                                                name: 'position',
                                            },
                                            Hersteller: {
                                                name: 'hersteller',
                                            },
                                            Typ: {
                                                name: 'typ',
                                            },
                                            Felge: {
                                                name: 'felge',
                                            },
                                            Reifenbreite: {
                                                name: 'reifenbreite',
                                            },
                                            Querschnitt: {
                                                name: 'querschnitt',
                                            },
                                            Bauart: {
                                                name: 'bauart',
                                            },
                                            Felgengroesse: {
                                                name: 'felgengroesse',
                                            },
                                            Tragfaehigkeit: {
                                                name: 'tragfaehigkeit',
                                            },
                                            Geschwindigkeitsindex: {
                                                name: 'geschwindigkeitsindex',
                                            },
                                            Zustand: {
                                                name: 'zustand',
                                            },
                                            Profiltiefe: {
                                                name: 'profiltiefe',
                                            },
                                            ReifenDruck: {
                                                name: 'reifenDruck',
                                            },
                                            Felgenhersteller: {
                                                name: 'felgenhersteller',
                                            },
                                            Runflat: {
                                                name: 'runflat',
                                            },
                                            Dot: {
                                                name: 'dot',
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                    Lagerort: {
                        name: 'lagerort',
                    },
                },
            },
            Notlaufeinrichtung: {
                name: 'notlaufeinrichtung',
            },
            NotlaufeinrichtungProfiltiefe: {
                name: 'notlaufeinrichtungProfiltiefe',
            },
            Bemerkungen: {
                name: 'bemerkungen',
                fields: {
                    Id: {
                        name: 'id',
                    },
                    Kurztext: {
                        name: 'kurztext',
                    },
                    Langtext: {
                        name: 'langtext',
                    },
                    Verfuegbarkeit: {
                        name: 'verfuegbarkeit',
                    },
                    Produkte: {
                        name: 'produkte',
                    },
                    Feature: {
                        name: 'feature',
                    },
                    Feld: {
                        name: 'feld',
                    },
                    Tags: {
                        name: 'tags',
                    },
                    Standard: {
                        name: 'standard',
                    },
                    ErstelltAm: {
                        name: 'erstelltAm',
                    },
                    ErstelltVon: {
                        name: 'erstelltVon',
                    },
                },
            },
        },
    },
    Vorschaden: {
        name: 'vorschaden',
        fields: {
            Positionen: {
                name: 'positionen',
                fields: {
                    Obergruppe: {
                        name: 'obergruppe',
                    },
                    Untergruppe: {
                        name: 'untergruppe',
                    },
                    Art: {
                        name: 'art',
                    },
                    Reparatur: {
                        name: 'reparatur',
                    },
                    Bilder: {
                        name: 'bilder',
                    },
                    Schadendatum: {
                        name: 'schadendatum',
                    },
                    Schadenhoehe: {
                        name: 'schadenhoehe',
                    },
                    Wertminderung: {
                        name: 'wertminderung',
                    },
                    ExternalId: {
                        name: 'externalId',
                    },
                    Extern: {
                        name: 'extern',
                    },
                    Bestaetigt: {
                        name: 'bestaetigt',
                    },
                },
            },
        },
    },
    Schaden: {
        name: 'schaden',
        fields: {
            Positionen: {
                name: 'positionen',
                fields: {
                    Obergruppe: {
                        name: 'obergruppe',
                    },
                    Untergruppe: {
                        name: 'untergruppe',
                    },
                    Beschaedigungart: {
                        name: 'beschaedigungart',
                    },
                    Reparaturweg: {
                        name: 'reparaturweg',
                    },
                    Bilder: {
                        name: 'bilder',
                    },
                    Intensitaet: {
                        name: 'intensitaet',
                    },
                    Preis: {
                        name: 'preis',
                    },
                    Minderwert: {
                        name: 'minderwert',
                    },
                    RelativerWert: {
                        name: 'relativerWert',
                    },
                    ExternalId: {
                        name: 'externalId',
                    },
                    Extern: {
                        name: 'extern',
                    },
                    Bestaetigt: {
                        name: 'bestaetigt',
                    },
                },
            },
            Beschreibungen: {
                name: 'beschreibungen',
                fields: {
                    Id: {
                        name: 'id',
                    },
                    Kurztext: {
                        name: 'kurztext',
                    },
                    Langtext: {
                        name: 'langtext',
                    },
                    Verfuegbarkeit: {
                        name: 'verfuegbarkeit',
                    },
                    Produkte: {
                        name: 'produkte',
                    },
                    Feature: {
                        name: 'feature',
                    },
                    Feld: {
                        name: 'feld',
                    },
                    Tags: {
                        name: 'tags',
                    },
                    Standard: {
                        name: 'standard',
                    },
                    ErstelltAm: {
                        name: 'erstelltAm',
                    },
                    ErstelltVon: {
                        name: 'erstelltVon',
                    },
                },
            },
        },
    },
    CgFeststellung: {
        name: 'cgFeststellung',
        fields: {
            Versicherungsart: {
                name: 'versicherungsart',
            },
            PositionenBauteileversichert: {
                name: 'positionenBauteileversichert',
                fields: {
                    Bezeichnung: {
                        name: 'bezeichnung',
                    },
                    Baugruppe: {
                        name: 'baugruppe',
                    },
                    Prueftext: {
                        name: 'prueftext',
                    },
                    Bilder: {
                        name: 'bilder',
                    },
                },
            },
            PositionenFahrzeugversichert: {
                name: 'positionenFahrzeugversichert',
                fields: {
                    Bezeichnung: {
                        name: 'bezeichnung',
                    },
                    Baugruppe: {
                        name: 'baugruppe',
                    },
                    Prueftext: {
                        name: 'prueftext',
                    },
                    Bilder: {
                        name: 'bilder',
                    },
                },
            },
            Verschleissbedingt: {
                name: 'verschleissbedingt',
            },
            Diagnose: {
                name: 'diagnose',
            },
            InternerKommentar: {
                name: 'internerKommentar',
            },
        },
    },
    Feststellungen: {
        name: 'feststellungen',
        fields: {
            Umfaenge: {
                name: 'umfaenge',
                fields: {
                    Obergruppe: {
                        name: 'obergruppe',
                    },
                    ObergruppeName: {
                        name: 'obergruppeName',
                    },
                    Beschreibung: {
                        name: 'beschreibung',
                        fields: {
                            Id: {
                                name: 'id',
                            },
                            Kurztext: {
                                name: 'kurztext',
                            },
                            Langtext: {
                                name: 'langtext',
                            },
                            Verfuegbarkeit: {
                                name: 'verfuegbarkeit',
                            },
                            Produkte: {
                                name: 'produkte',
                            },
                            Feature: {
                                name: 'feature',
                            },
                            Feld: {
                                name: 'feld',
                            },
                            Tags: {
                                name: 'tags',
                            },
                            Standard: {
                                name: 'standard',
                            },
                            ErstelltAm: {
                                name: 'erstelltAm',
                            },
                            ErstelltVon: {
                                name: 'erstelltVon',
                            },
                        },
                    },
                },
            },
            Positionen: {
                name: 'positionen',
                fields: {
                    Obergruppe: {
                        name: 'obergruppe',
                    },
                    Untergruppe: {
                        name: 'untergruppe',
                    },
                    Zustand: {
                        name: 'zustand',
                        fields: {
                            Id: {
                                name: 'id',
                            },
                            Kurztext: {
                                name: 'kurztext',
                            },
                            Langtext: {
                                name: 'langtext',
                            },
                            Verfuegbarkeit: {
                                name: 'verfuegbarkeit',
                            },
                            Produkte: {
                                name: 'produkte',
                            },
                            Feature: {
                                name: 'feature',
                            },
                            Feld: {
                                name: 'feld',
                            },
                            Tags: {
                                name: 'tags',
                            },
                            Standard: {
                                name: 'standard',
                            },
                            ErstelltAm: {
                                name: 'erstelltAm',
                            },
                            ErstelltVon: {
                                name: 'erstelltVon',
                            },
                        },
                    },
                    Beschaedigungart: {
                        name: 'beschaedigungart',
                    },
                    Bilder: {
                        name: 'bilder',
                    },
                    Preis: {
                        name: 'preis',
                    },
                    ExternalId: {
                        name: 'externalId',
                    },
                    Extern: {
                        name: 'extern',
                    },
                    Bestaetigt: {
                        name: 'bestaetigt',
                    },
                },
            },
            Beschreibungen: {
                name: 'beschreibungen',
                fields: {
                    Id: {
                        name: 'id',
                    },
                    Kurztext: {
                        name: 'kurztext',
                    },
                    Langtext: {
                        name: 'langtext',
                    },
                    Verfuegbarkeit: {
                        name: 'verfuegbarkeit',
                    },
                    Produkte: {
                        name: 'produkte',
                    },
                    Feature: {
                        name: 'feature',
                    },
                    Feld: {
                        name: 'feld',
                    },
                    Tags: {
                        name: 'tags',
                    },
                    Standard: {
                        name: 'standard',
                    },
                    ErstelltAm: {
                        name: 'erstelltAm',
                    },
                    ErstelltVon: {
                        name: 'erstelltVon',
                    },
                },
            },
        },
    },
    Ausstattung: {
        name: 'ausstattung',
        fields: {
            Gruppen: {
                name: 'gruppen',
                fields: {
                    Name: {
                        name: 'name',
                    },
                    Sonderausstattung: {
                        name: 'sonderausstattung',
                    },
                    Teile: {
                        name: 'teile',
                        fields: {
                            Name: {
                                name: 'name',
                            },
                            Vorhanden: {
                                name: 'vorhanden',
                            },
                            ExternalServiceId: {
                                name: 'externalServiceId',
                            },
                        },
                    },
                },
            },
            Individualaufbauten: {
                name: 'individualaufbauten',
                fields: {
                    Id: {
                        name: 'id',
                    },
                    Kurztext: {
                        name: 'kurztext',
                    },
                    Langtext: {
                        name: 'langtext',
                    },
                    Verfuegbarkeit: {
                        name: 'verfuegbarkeit',
                    },
                    Produkte: {
                        name: 'produkte',
                    },
                    Feature: {
                        name: 'feature',
                    },
                    Feld: {
                        name: 'feld',
                    },
                    Tags: {
                        name: 'tags',
                    },
                    Standard: {
                        name: 'standard',
                    },
                    ErstelltAm: {
                        name: 'erstelltAm',
                    },
                    ErstelltVon: {
                        name: 'erstelltVon',
                    },
                },
            },
        },
    },
    Wartung: {
        name: 'wartung',
        fields: {
            LetzterServiceNichtBekannt: {
                name: 'letzterServiceNichtBekannt',
            },
            LetzterServiceBei: {
                name: 'letzterServiceBei',
            },
            LetzterServiceFaelligEinheit: {
                name: 'letzterServiceFaelligEinheit',
            },
            LetzterServiceAm: {
                name: 'letzterServiceAm',
            },
            LetzterServiceAmDayHidden: {
                name: 'letzterServiceAmDayHidden',
            },
            NaechsterServiceFaelligNichtBekannt: {
                name: 'naechsterServiceFaelligNichtBekannt',
            },
            NaechsterServiceFaelligIn: {
                name: 'naechsterServiceFaelligIn',
            },
            NaechsterServiceFaelligEinheit: {
                name: 'naechsterServiceFaelligEinheit',
            },
            NaechsterServiceFaelligInZeit: {
                name: 'naechsterServiceFaelligInZeit',
            },
            NaechsterServiceFaelligEinheitZeit: {
                name: 'naechsterServiceFaelligEinheitZeit',
            },
            NaechsterServiceFaelligAm: {
                name: 'naechsterServiceFaelligAm',
            },
            NaechsterServiceFaelligAmDayHidden: {
                name: 'naechsterServiceFaelligAmDayHidden',
            },
            NaechsterServiceFaelligKosten: {
                name: 'naechsterServiceFaelligKosten',
            },
            NaechsterServiceFaelligExternalId: {
                name: 'naechsterServiceFaelligExternalId',
            },
            NaechsteHauptAbgasUntersuchung: {
                name: 'naechsteHauptAbgasUntersuchung',
            },
            NaechsterServiceBeschreibung: {
                name: 'naechsterServiceBeschreibung',
                fields: {
                    Id: {
                        name: 'id',
                    },
                    Kurztext: {
                        name: 'kurztext',
                    },
                    Langtext: {
                        name: 'langtext',
                    },
                    Verfuegbarkeit: {
                        name: 'verfuegbarkeit',
                    },
                    Produkte: {
                        name: 'produkte',
                    },
                    Feature: {
                        name: 'feature',
                    },
                    Feld: {
                        name: 'feld',
                    },
                    Tags: {
                        name: 'tags',
                    },
                    Standard: {
                        name: 'standard',
                    },
                    ErstelltAm: {
                        name: 'erstelltAm',
                    },
                    ErstelltVon: {
                        name: 'erstelltVon',
                    },
                },
            },
            HauptAbgasUntersuchungFaelligKosten: {
                name: 'hauptAbgasUntersuchungFaelligKosten',
            },
            NaechsteHauptAbgasUntersuchungNichtBekannt: {
                name: 'naechsteHauptAbgasUntersuchungNichtBekannt',
            },
            NaechsteHauptAbgasUntersuchungExternalId: {
                name: 'naechsteHauptAbgasUntersuchungExternalId',
            },
            ZahnriemenwechselBei: {
                name: 'zahnriemenwechselBei',
            },
            ZahnriemenwechselFaelligEinheit: {
                name: 'zahnriemenwechselFaelligEinheit',
            },
            ZahnriemenwechselAm: {
                name: 'zahnriemenwechselAm',
            },
            ZahnriemenwechselAmDayHidden: {
                name: 'zahnriemenwechselAmDayHidden',
            },
            ZahnriemenFaelligKosten: {
                name: 'zahnriemenFaelligKosten',
            },
            ZahnriemenFaelligExternalId: {
                name: 'zahnriemenFaelligExternalId',
            },
            Positionen: {
                name: 'positionen',
                fields: {
                    ExternalId: {
                        name: 'externalId',
                    },
                    Bezeichnung: {
                        name: 'bezeichnung',
                    },
                    Kosten: {
                        name: 'kosten',
                    },
                },
            },
        },
    },
    Unterlagen: {
        name: 'unterlagen',
        fields: {
            SchluesselFahrzeug: {
                name: 'schluesselFahrzeug',
            },
            SchluesselAHK: {
                name: 'schluesselAHK',
            },
            LadekabelEAuto: {
                name: 'ladekabelEAuto',
            },
            FernbedienungStandheizung: {
                name: 'fernbedienungStandheizung',
            },
            Positionen: {
                name: 'positionen',
                fields: {
                    Bezeichnung: {
                        name: 'bezeichnung',
                    },
                    Bilder: {
                        name: 'bilder',
                    },
                },
            },
        },
    },
    Werte: {
        name: 'werte',
        fields: {
            Bezugsdatum: {
                name: 'bezugsdatum',
            },
            RoundValue: {
                name: 'roundValue',
            },
            Haendlereinkaufswert: {
                name: 'haendlereinkaufswert',
            },
            HaendlereinkaufswertDrucken: {
                name: 'haendlereinkaufswertDrucken',
            },
            HaendlereinkaufswertNetto: {
                name: 'haendlereinkaufswertNetto',
            },
            HaendlereinkaufswertNettoDrucken: {
                name: 'haendlereinkaufswertNettoDrucken',
            },
            Haendlerverkaufswert: {
                name: 'haendlerverkaufswert',
            },
            HaendlerverkaufswertDrucken: {
                name: 'haendlerverkaufswertDrucken',
            },
            HaendlerverkaufswertBesteuerung: {
                name: 'haendlerverkaufswertBesteuerung',
            },
            HaendlerverkaufswertNetto: {
                name: 'haendlerverkaufswertNetto',
            },
            HaendlerverkaufswertNettoDrucken: {
                name: 'haendlerverkaufswertNettoDrucken',
            },
            DatHvkNettoDifferenz: {
                name: 'datHvkNettoDifferenz',
            },
            DatHvkNettoRegel: {
                name: 'datHvkNettoRegel',
            },
            Aufwendungen: {
                name: 'aufwendungen',
            },
            AufwendungenDrucken: {
                name: 'aufwendungenDrucken',
            },
            AufwendungenDetailsDrucken: {
                name: 'aufwendungenDetailsDrucken',
            },
            HaendlereinkaufswertAbzuege: {
                name: 'haendlereinkaufswertAbzuege',
            },
            HaendlereinkaufswertAbzuegeDrucken: {
                name: 'haendlereinkaufswertAbzuegeDrucken',
            },
            HaendlereinkaufswertAbzuegeNetto: {
                name: 'haendlereinkaufswertAbzuegeNetto',
            },
            HaendlereinkaufswertAbzuegeNettoDrucken: {
                name: 'haendlereinkaufswertAbzuegeNettoDrucken',
            },
            HaendlerverkaufswertAbzuege: {
                name: 'haendlerverkaufswertAbzuege',
            },
            HaendlerverkaufswertAbzuegeDrucken: {
                name: 'haendlerverkaufswertAbzuegeDrucken',
            },
            HaendlerverkaufswertAbzuegeNetto: {
                name: 'haendlerverkaufswertAbzuegeNetto',
            },
            HaendlerverkaufswertAbzuegeNettoDrucken: {
                name: 'haendlerverkaufswertAbzuegeNettoDrucken',
            },
            ManuelleWerteDrucken: {
                name: 'manuelleWerteDrucken',
            },
            ManuelleWerte: {
                name: 'manuelleWerte',
                fields: {
                    Bezeichnung: {
                        name: 'bezeichnung',
                    },
                    Wert: {
                        name: 'wert',
                    },
                },
            },
            WertAmMarkt: {
                name: 'wertAmMarkt',
            },
            WertAmMarktBemerkung: {
                name: 'wertAmMarktBemerkung',
            },
            WertAmMarktBemerkungen: {
                name: 'wertAmMarktBemerkungen',
                fields: {
                    Id: {
                        name: 'id',
                    },
                    Kurztext: {
                        name: 'kurztext',
                    },
                    Langtext: {
                        name: 'langtext',
                    },
                    Verfuegbarkeit: {
                        name: 'verfuegbarkeit',
                    },
                    Produkte: {
                        name: 'produkte',
                    },
                    Feature: {
                        name: 'feature',
                    },
                    Feld: {
                        name: 'feld',
                    },
                    Tags: {
                        name: 'tags',
                    },
                    Standard: {
                        name: 'standard',
                    },
                    ErstelltAm: {
                        name: 'erstelltAm',
                    },
                    ErstelltVon: {
                        name: 'erstelltVon',
                    },
                },
            },
            RestwertAusUnfallschaden: {
                name: 'restwertAusUnfallschaden',
            },
            RestwertAusUnfallschadenNetto: {
                name: 'restwertAusUnfallschadenNetto',
            },
            Neuwert: {
                name: 'neuwert',
            },
            NeuwertDrucken: {
                name: 'neuwertDrucken',
            },
            RelativerWert: {
                name: 'relativerWert',
            },
            RelativerWertDrucken: {
                name: 'relativerWertDrucken',
            },
            WerterhoehendesZubehoer: {
                name: 'werterhoehendesZubehoer',
            },
            WerterhoehenderWartungszustand: {
                name: 'werterhoehenderWartungszustand',
            },
            WerterhoehenderReparaturzustand: {
                name: 'werterhoehenderReparaturzustand',
            },
            ZweiterRadsatzAnteilig: {
                name: 'zweiterRadsatzAnteilig',
            },
            ExternalServicesReferenceId: {
                name: 'externalServicesReferenceId',
            },
        },
    },
    VtiWerte: {
        name: 'vtiWerte',
        fields: {
            Bezugsdatum: {
                name: 'bezugsdatum',
            },
            Haendlereinkaufswert: {
                name: 'haendlereinkaufswert',
            },
            HaendlereinkaufswertDrucken: {
                name: 'haendlereinkaufswertDrucken',
            },
            HaendlereinkaufswertNetto: {
                name: 'haendlereinkaufswertNetto',
            },
            HaendlereinkaufswertNettoDrucken: {
                name: 'haendlereinkaufswertNettoDrucken',
            },
            Haendlerverkaufswert: {
                name: 'haendlerverkaufswert',
            },
            HaendlerverkaufswertDrucken: {
                name: 'haendlerverkaufswertDrucken',
            },
            HaendlerverkaufswertBesteuerung: {
                name: 'haendlerverkaufswertBesteuerung',
            },
            HaendlerverkaufswertNetto: {
                name: 'haendlerverkaufswertNetto',
            },
            HaendlerverkaufswertNettoDrucken: {
                name: 'haendlerverkaufswertNettoDrucken',
            },
            WertAmMarkt: {
                name: 'wertAmMarkt',
            },
            WertAmMarktBemerkungen: {
                name: 'wertAmMarktBemerkungen',
                fields: {
                    Id: {
                        name: 'id',
                    },
                    Kurztext: {
                        name: 'kurztext',
                    },
                    Langtext: {
                        name: 'langtext',
                    },
                    Verfuegbarkeit: {
                        name: 'verfuegbarkeit',
                    },
                    Produkte: {
                        name: 'produkte',
                    },
                    Feature: {
                        name: 'feature',
                    },
                    Feld: {
                        name: 'feld',
                    },
                    Tags: {
                        name: 'tags',
                    },
                    Standard: {
                        name: 'standard',
                    },
                    ErstelltAm: {
                        name: 'erstelltAm',
                    },
                    ErstelltVon: {
                        name: 'erstelltVon',
                    },
                },
            },
            Neuwert: {
                name: 'neuwert',
            },
            NeuwertDrucken: {
                name: 'neuwertDrucken',
            },
            RelativerWert: {
                name: 'relativerWert',
            },
            RelativerWertDrucken: {
                name: 'relativerWertDrucken',
            },
            ExternalServicesReferenceId: {
                name: 'externalServicesReferenceId',
            },
        },
    },
    Fehlteile: {
        name: 'fehlteile',
        fields: {
            Positionen: {
                name: 'positionen',
                fields: {
                    Bezeichnung: {
                        name: 'bezeichnung',
                    },
                    Preis: {
                        name: 'preis',
                    },
                    ExternalId: {
                        name: 'externalId',
                    },
                },
            },
        },
    },
    VtiAbschluss: {
        name: 'vtiAbschluss',
        fields: {
            Adresse: {
                name: 'adresse',
            },
        },
    },
    CgAbschluss: {
        name: 'cgAbschluss',
        fields: {
            Adresse: {
                name: 'adresse',
            },
            Fazit: {
                name: 'fazit',
                fields: {
                    Id: {
                        name: 'id',
                    },
                    Kurztext: {
                        name: 'kurztext',
                    },
                    Langtext: {
                        name: 'langtext',
                    },
                    Verfuegbarkeit: {
                        name: 'verfuegbarkeit',
                    },
                    Produkte: {
                        name: 'produkte',
                    },
                    Feature: {
                        name: 'feature',
                    },
                    Feld: {
                        name: 'feld',
                    },
                    Tags: {
                        name: 'tags',
                    },
                    Standard: {
                        name: 'standard',
                    },
                    ErstelltAm: {
                        name: 'erstelltAm',
                    },
                    ErstelltVon: {
                        name: 'erstelltVon',
                    },
                },
            },
            AbschlussBemerkung: {
                name: 'abschlussBemerkung',
                fields: {
                    Id: {
                        name: 'id',
                    },
                    Kurztext: {
                        name: 'kurztext',
                    },
                    Langtext: {
                        name: 'langtext',
                    },
                    Verfuegbarkeit: {
                        name: 'verfuegbarkeit',
                    },
                    Produkte: {
                        name: 'produkte',
                    },
                    Feature: {
                        name: 'feature',
                    },
                    Feld: {
                        name: 'feld',
                    },
                    Tags: {
                        name: 'tags',
                    },
                    Standard: {
                        name: 'standard',
                    },
                    ErstelltAm: {
                        name: 'erstelltAm',
                    },
                    ErstelltVon: {
                        name: 'erstelltVon',
                    },
                },
            },
        },
    },
    AcAbschluss: {
        name: 'acAbschluss',
        fields: {
            Adresse: {
                name: 'adresse',
            },
            AcVersendet: {
                name: 'acVersendet',
            },
        },
    },
    Abschluss: {
        name: 'abschluss',
        fields: {
            Adresse: {
                name: 'adresse',
            },
            Fazit: {
                name: 'fazit',
                fields: {
                    Id: {
                        name: 'id',
                    },
                    Kurztext: {
                        name: 'kurztext',
                    },
                    Langtext: {
                        name: 'langtext',
                    },
                    Verfuegbarkeit: {
                        name: 'verfuegbarkeit',
                    },
                    Produkte: {
                        name: 'produkte',
                    },
                    Feature: {
                        name: 'feature',
                    },
                    Feld: {
                        name: 'feld',
                    },
                    Tags: {
                        name: 'tags',
                    },
                    Standard: {
                        name: 'standard',
                    },
                    ErstelltAm: {
                        name: 'erstelltAm',
                    },
                    ErstelltVon: {
                        name: 'erstelltVon',
                    },
                },
            },
            AbschlussBemerkung: {
                name: 'abschlussBemerkung',
                fields: {
                    Id: {
                        name: 'id',
                    },
                    Kurztext: {
                        name: 'kurztext',
                    },
                    Langtext: {
                        name: 'langtext',
                    },
                    Verfuegbarkeit: {
                        name: 'verfuegbarkeit',
                    },
                    Produkte: {
                        name: 'produkte',
                    },
                    Feature: {
                        name: 'feature',
                    },
                    Feld: {
                        name: 'feld',
                    },
                    Tags: {
                        name: 'tags',
                    },
                    Standard: {
                        name: 'standard',
                    },
                    ErstelltAm: {
                        name: 'erstelltAm',
                    },
                    ErstelltVon: {
                        name: 'erstelltVon',
                    },
                },
            },
        },
    },
    Anhaenge: {
        name: 'anhaenge',
        fields: {
            Anhaenge: {
                name: 'anhaenge',
                fields: {
                    Bezeichnung: {
                        name: 'bezeichnung',
                    },
                    Quelle: {
                        name: 'quelle',
                    },
                },
            },
        },
    },
    Lackmessung: {
        name: 'lackmessung',
        fields: {
            Messungen: {
                name: 'messungen',
                fields: {
                    Position: {
                        name: 'position',
                    },
                    Bezeichnung: {
                        name: 'bezeichnung',
                    },
                    Dicke: {
                        name: 'dicke',
                    },
                    Ergebnis: {
                        name: 'ergebnis',
                    },
                    Beschreibung: {
                        name: 'beschreibung',
                    },
                    Bilder: {
                        name: 'bilder',
                    },
                    Bemerkungen: {
                        name: 'bemerkungen',
                        fields: {
                            Id: {
                                name: 'id',
                            },
                            Kurztext: {
                                name: 'kurztext',
                            },
                            Langtext: {
                                name: 'langtext',
                            },
                            Verfuegbarkeit: {
                                name: 'verfuegbarkeit',
                            },
                            Produkte: {
                                name: 'produkte',
                            },
                            Feature: {
                                name: 'feature',
                            },
                            Feld: {
                                name: 'feld',
                            },
                            Tags: {
                                name: 'tags',
                            },
                            Standard: {
                                name: 'standard',
                            },
                            ErstelltAm: {
                                name: 'erstelltAm',
                            },
                            ErstelltVon: {
                                name: 'erstelltVon',
                            },
                        },
                    },
                },
            },
            GesamtKarosserieVon: {
                name: 'gesamtKarosserieVon',
            },
            GesamtKarosserieBis: {
                name: 'gesamtKarosserieBis',
            },
            UntersuchungsErgebnis: {
                name: 'untersuchungsErgebnis',
            },
            Beschreibungen: {
                name: 'beschreibungen',
                fields: {
                    Id: {
                        name: 'id',
                    },
                    Kurztext: {
                        name: 'kurztext',
                    },
                    Langtext: {
                        name: 'langtext',
                    },
                    Verfuegbarkeit: {
                        name: 'verfuegbarkeit',
                    },
                    Produkte: {
                        name: 'produkte',
                    },
                    Feature: {
                        name: 'feature',
                    },
                    Feld: {
                        name: 'feld',
                    },
                    Tags: {
                        name: 'tags',
                    },
                    Standard: {
                        name: 'standard',
                    },
                    ErstelltAm: {
                        name: 'erstelltAm',
                    },
                    ErstelltVon: {
                        name: 'erstelltVon',
                    },
                },
            },
        },
    },
    Restwertprognose: {
        name: 'restwertprognose',
        fields: {
            Bezugsdatum: {
                name: 'bezugsdatum',
            },
            Vertragsnummer: {
                name: 'vertragsnummer',
            },
            Vertragsbeginn: {
                name: 'vertragsbeginn',
            },
            Vertragsende: {
                name: 'vertragsende',
            },
            VereinbarteKilometer: {
                name: 'vereinbarteKilometer',
            },
            TatsaechlicheKilometer: {
                name: 'tatsaechlicheKilometer',
            },
            DifferenzKilometer: {
                name: 'differenzKilometer',
            },
            DatDossierId: {
                name: 'datDossierId',
            },
            RestwertNetto: {
                name: 'restwertNetto',
            },
            RestwertBrutto: {
                name: 'restwertBrutto',
            },
            Dokument: {
                name: 'dokument',
            },
        },
    },
    Kalkulation: {
        name: 'kalkulation',
        fields: {
            Bezugsdatum: {
                name: 'bezugsdatum',
            },
            DossierId: {
                name: 'dossierId',
            },
            ReparaturkostenNetto: {
                name: 'reparaturkostenNetto',
            },
            ReparaturkostenBrutto: {
                name: 'reparaturkostenBrutto',
            },
            Dokument: {
                name: 'dokument',
            },
        },
    },
};

// Bewertung

const d = PRODUKT_CONFIG_FEATURES;

function createBewertung(minderwert: boolean): ProduktConfig {
    const bewertung: ProduktConfig = {
        features: [
            ProduktFeatureConfig.createAuftrag(d),
            ProduktFeatureConfig.createFahrzeug(d),
            ProduktFeatureConfig.createWerte(d, true),
            ProduktFeatureConfig.createAdressen(d),
            ProduktFeatureConfig.createUebersicht(d),
            ProduktFeatureConfig.createRaeder(d),
            ProduktFeatureConfig.createLackmessung(d),
            ProduktFeatureConfig.createVorschaden(d),
            ProduktFeatureConfig.createSchaden(d, minderwert),
            ProduktFeatureConfig.createKalkulation(d),
            ProduktFeatureConfig.createAusstattung(d),
            ProduktFeatureConfig.createUnterlagen(d),
            ProduktFeatureConfig.createFehlteile(d),
            ProduktFeatureConfig.createWartung(d),
            ProduktFeatureConfig.createAbschluss(d),
        ],
    };
    return bewertung;
}

function createZustandsbericht(): ProduktConfig {
    const zustandsbericht: ProduktConfig = {
        features: [
            ProduktFeatureConfig.createAuftrag(d),
            ProduktFeatureConfig.createFahrzeug(d),
            ProduktFeatureConfig.createWerte(d, false),
            ProduktFeatureConfig.createAdressen(d),
            ProduktFeatureConfig.createUebersicht(d),
            ProduktFeatureConfig.createRaeder(d),
            ProduktFeatureConfig.createLackmessung(d),
            ProduktFeatureConfig.createVorschaden(d),
            ProduktFeatureConfig.createFeststellungen(d),
            ProduktFeatureConfig.createKalkulation(d),
            ProduktFeatureConfig.createAusstattung(d),
            ProduktFeatureConfig.createUnterlagen(d),
            ProduktFeatureConfig.createFehlteile(d),
            ProduktFeatureConfig.createWartung(d),
            ProduktFeatureConfig.createAbschluss(d),
        ],
    };
    return zustandsbericht;
}

function createNfzRuecknahmebewertung(): ProduktConfig {
    const nfzRuecknahmebewertung: ProduktConfig = {
        features: [
            ProduktFeatureConfig.createAuftrag(d),
            ProduktFeatureConfig.createFahrzeug(d),
            ProduktFeatureConfig.createWerte(d, true),
            ProduktFeatureConfig.createAdressen(d),
            ProduktFeatureConfig.createUebersicht(d),
            ProduktFeatureConfig.createNfzRaeder(d),
            ProduktFeatureConfig.createLackmessung(d),
            ProduktFeatureConfig.createVorschaden(d),
            ProduktFeatureConfig.createSchaden(d, true),
            ProduktFeatureConfig.createKalkulation(d),
            ProduktFeatureConfig.createAusstattung(d),
            ProduktFeatureConfig.createUnterlagen(d),
            ProduktFeatureConfig.createFehlteile(d),
            ProduktFeatureConfig.createWartung(d),
            ProduktFeatureConfig.createAbschluss(d),
        ],
    };
    return nfzRuecknahmebewertung;
}

function createVtiTooling(): ProduktConfig {
    const vtiTooling: ProduktConfig = {
        features: [
            ProduktFeatureConfig.createVtiAuftrag(d),
            ProduktFeatureConfig.createFahrzeug(d),
            ProduktFeatureConfig.createVtiWerte(d),
            ProduktFeatureConfig.createKalkulation(d),
            ProduktFeatureConfig.createRestwertprognose(d),
            ProduktFeatureConfig.createAdressen(d),
            ProduktFeatureConfig.createVtiAbschluss(d),
        ],
    };
    return vtiTooling;
}

function createAcBewertung(): ProduktConfig {
    const acBewertung: ProduktConfig = {
        features: [
            ProduktFeatureConfig.createAuftrag(d),
            ProduktFeatureConfig.createAcFahrzeug(d),
            ProduktFeatureConfig.createWerte(d, true),
            ProduktFeatureConfig.createAdressen(d),
            ProduktFeatureConfig.createUebersicht(d),
            ProduktFeatureConfig.createRaeder(d),
            ProduktFeatureConfig.createLackmessung(d),
            ProduktFeatureConfig.createVorschaden(d),
            ProduktFeatureConfig.createSchaden(d, true),
            ProduktFeatureConfig.createKalkulation(d),
            ProduktFeatureConfig.createAusstattung(d),
            ProduktFeatureConfig.createUnterlagen(d),
            ProduktFeatureConfig.createFehlteile(d),
            ProduktFeatureConfig.createWartung(d),
            ProduktFeatureConfig.createAcAbschluss(d),
        ],
    };
    return acBewertung;
}

function createCarGarantie(): ProduktConfig {
    const carGarantie: ProduktConfig = {
        features: [
            ProduktFeatureConfig.createAuftrag(d),
            ProduktFeatureConfig.createFahrzeug(d),
            ProduktFeatureConfig.createAdressen(d),
            ProduktFeatureConfig.createUebersicht(d),
            ProduktFeatureConfig.createCgFeststellung(d),
            ProduktFeatureConfig.createAusstattung(d),
            ProduktFeatureConfig.createWartung(d),
            ProduktFeatureConfig.createCgAbschluss(d),
        ],
    };
    return carGarantie;
}

export const PRODUKT_CONFIG_ART_SUBART_MAP = {
    [ProduktArt.Bewertung]: {
        0: createBewertung(false),
    },
    [ProduktArt.Ruecknahmebewertung]: {
        0: createBewertung(true),
    },
    [ProduktArt.Zustandsbericht]: {
        0: createZustandsbericht(),
    },
    [ProduktArt.NfzRuecknahmebewertung]: {
        0: createNfzRuecknahmebewertung(),
    },
    [ProduktArt.VtiTooling]: {
        0: createVtiTooling(),
    },
    [ProduktArt.AlphaController]: {
        0: createAcBewertung(),
    },
    [ProduktArt.CarGarantie]: {
        0: createCarGarantie(),
    },
};
