import {
    ChangeDetectionStrategy,
    Component,
    effect,
    inject,
    Injector,
    input,
    output,
    runInInjectionContext,
    signal,
    ViewEncapsulation,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { CompletionApi, CompletionResponse } from '@data/api-gateway/schema/completion-api.model';
import { TranslateModule } from '@ngx-translate/core';
import { CompletionApiConfig } from '@shared/models/form-field.model';
import { HighlightMatchPipe } from '@shared/standalone/form-array/form-completion/highlight-match.pipe';
import { of, switchMap, tap } from 'rxjs';

@Component({
    selector: 'app-form-completion',
    standalone: true,
    imports: [
        MatFormFieldModule,
        ReactiveFormsModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatAutocompleteModule,
        HighlightMatchPipe,
        TranslateModule,
    ],
    templateUrl: './form-completion.component.html',
    styleUrl: './form-completion.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormCompletionComponent {
    completionApiConfig = input.required<CompletionApiConfig>();
    selectedEntry = output<object>();

    formControl = new FormControl('');
    completions = toSignal(
        this.formControl.valueChanges.pipe(
            switchMap((value) => {
                if (!value) return of([]);
                if (value.length < this.completionApiConfig().minInputLength) {
                    return of([]);
                }
                this.isLoading.set(true);
                return this.completionInjection?.get(value) || [];
            }),
            tap(() => {
                this.isLoading.set(false);
            }),
        ),
    );

    completionInjection?: CompletionApi<CompletionResponse>;
    isLoading = signal(false);

    constructor(readonly injector: Injector) {
        effect(() => {
            runInInjectionContext(injector, () => {
                this.completionInjection = inject(this.completionApiConfig().service);
            });
        });
    }

    deleteCompletionItem(event: MouseEvent, item: CompletionResponse): void {
        event.stopPropagation();
        event.preventDefault();

        this.completionInjection?.delete(item.es_id).subscribe(() => {
            const currentInputValue = this.formControl.value;
            this.formControl.setValue('');
            this.formControl.setValue(currentInputValue);
        });
    }
}
