import { Component, input, output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ProduktArt } from '@data/domain/schema/enum';
import { Textbaustein } from '@data/domain/schema/type';
import { TextbausteineService } from '@data/domain/service/textbausteine.service';

@Component({
    selector: 'app-textbausteine-dialog',
    templateUrl: './textbausteine-dialog.component.html',
    styleUrls: ['./textbausteine-dialog.component.scss'],
})
export class TextbausteineDialogComponent {
    feature = input<string>();
    feld = input<string>();
    feldDisplayName = input<string>();
    form = input<UntypedFormGroup>();
    produktArt = input<ProduktArt>(ProduktArt.None);

    textbausteinEventEmitter = output<Textbaustein>();

    constructor(private readonly textbausteineService: TextbausteineService) {}
}
