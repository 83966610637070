<app-card [class.mobile]="viewport() === Viewport.Mobile">
    <h4>{{ 'textbausteine.verwaltung.title' | translate }}</h4>

    @for (config of textbausteineConfigList; track config) {
        <app-accordion [multi]="true">
            <app-expansion-panel>
                <app-expansion-header>
                    <app-panel-title class="title">
                        <ng-container>
                            <app-layered-icon [icon]="config.icon" />
                            <div class="name">
                                <span>{{ config.featureDisplayName }}</span>
                                <span>-</span>
                                <strong>{{ config.feldDisplayName }}</strong>
                            </div>
                        </ng-container>
                    </app-panel-title>
                </app-expansion-header>
                <app-textbausteine-list
                    [feature]="config.feature"
                    [feld]="config.feld"
                    [feldDisplayName]="config.feldDisplayName"
                />
            </app-expansion-panel>
        </app-accordion>
    }
</app-card>
