import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ProduktArt, WartungEinheit, WartungEinheitZeit } from '@data/domain/schema/enum';
import { Notiz, Textbaustein, Wartung, WartungInput, WartungPosition } from '@data/domain/schema/type';
import { ProduktWartungService } from '@data/domain/service/feature';
import { UpdateWerteService } from '@data/domain/service/feature/update-werte-service';
import { TextbausteineService } from '@data/domain/service/textbausteine.service';
import { ProduktDetailFeatureInputComponent } from '@modules/produkt/component/produkt-detail-feature/produkt-detail-feature.component';
import {
    FeatureField,
    FeatureFieldArray,
    FeatureFields,
    PRODUKT_CONFIG_FEATURES,
} from '@modules/produkt/config/produkt-config';
import { PRODUKT_WARTUNG_POSITIONEN } from '@modules/produkt/config/produkt-wartung-positionen.config';
import { ProduktDetailWartungFormViewFactory } from '@modules/produkt/factory/wartung/produkt-detail-wartung-form-view.factory';
import { ProduktDetailWartungPositionFormViewFactory } from '@modules/produkt/factory/wartung/produkt-detail-wartung-position-form-view.factory';
import { ProduktDetailWartungPositionenFormViewFactory } from '@modules/produkt/factory/wartung/produkt-detail-wartung-positionen-form-view.factory';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ProduktConfigResolveService } from '@modules/produkt/service/produkt-config-resolve.service';
import { ProduktDetailFeatureNotizenService } from '@modules/produkt/service/produkt-detail-feature-notizen.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { TextbausteineComponent } from '@shared/component/form-controls/textbausteine/textbausteine.component';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { ViewFormControlFormatters } from '@shared/helper/form-controls/view-form-control-formatters';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { EnumValues, ObjectValues } from '@shared/helper/values';
import { CurrencyFormatterService } from '@shared/service/form-controls/currency-formatter.service';
import { ThousandsSeperatorFormatterService } from '@shared/service/form-controls/thousands-seperator-formatter.service';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { Viewport, ViewportService } from '@shared/service/viewport.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';

interface ProduktDetailWartungDialogData {
    form: ViewFormGroup;
    fields: FeatureFields;
}

@Component({
    selector: 'app-produkt-detail-wartung',
    templateUrl: './produkt-detail-wartung.component.html',
    styleUrls: ['./produkt-detail-wartung.component.scss'],
    providers: [ProduktDetailFeatureNotizenService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailWartungComponent
    extends ProduktDetailFeatureInputComponent<Wartung, WartungInput>
    implements OnInit, AfterViewInit, OnDestroy {
    private positionenName = PRODUKT_CONFIG_FEATURES.Wartung.fields.Positionen.name;

    trackByField = TrackBy.trackByField;
    now = new Date();
    wartungEinheit = new EnumValues(WartungEinheit);
    wartungEinheitZeit = new EnumValues(WartungEinheitZeit);

    notizen$: Observable<Notiz[]>;
    viewport$: Observable<Viewport>;
    showBottomSheet$: Observable<boolean>;
    showSidePanel$: Observable<boolean>;
    viewport = Viewport;

    positionen: ViewFormArray;
    positionenFields: FeatureFields;
    positionenValues = new ObjectValues(PRODUKT_WARTUNG_POSITIONEN);

    private naechsterServiceChangedSubscription: Subscription;
    private naechsteHuAuChangedSubscription: Subscription;
    private zahnriemenChangedSubscription: Subscription;

    istCarGarantie = false;

    rows$: Observable<ViewFormGroup[]>;
    sums$: Observable<string>;
    textbausteine$ = new BehaviorSubject<Textbaustein[]>([]);
    fieldNaechsterServiceBeschreibung = PRODUKT_CONFIG_FEATURES.Wartung.fields.NaechsterServiceBeschreibung.name;
    feature = PRODUKT_CONFIG_FEATURES.Wartung.name;

    letzterServiceNichtBekannt$ = new BehaviorSubject<boolean>(false);
    naechsterServiceFaelligNichtBekannt$ = new BehaviorSubject<boolean>(false);
    naechsteHauptAbgasUntersuchungNichtBekannt$ = new BehaviorSubject<boolean>(false);

    @ViewChild('dialog', { static: true })
    dialogTemplate: TemplateRef<any>;

    @ViewChild('naechsterServiceBeschreibung')
    naechsterServiceBeschreibungElement: TextbausteineComponent;

    constructor(
        produktConfigResolveService: ProduktConfigResolveService,
        produktDetailResolveService: ProduktDetailResolveService,
        produktWartungService: ProduktWartungService,
        private readonly formViewFactory: ProduktDetailWartungFormViewFactory,
        private readonly positionFormViewFactory: ProduktDetailWartungPositionFormViewFactory,
        private readonly positionenFormViewFactory: ProduktDetailWartungPositionenFormViewFactory,
        private readonly templateDialogService: TemplateDialogService,
        private readonly currencyFormatter: CurrencyFormatterService,
        private readonly viewportService: ViewportService,
        private readonly notizenService: ProduktDetailFeatureNotizenService,
        private readonly textbausteineService: TextbausteineService,
        private readonly updateWerteService: UpdateWerteService,
        private readonly formatter: ThousandsSeperatorFormatterService,
    ) {
        super(produktConfigResolveService, produktDetailResolveService, produktWartungService);
        Assert.notNullOrUndefined(formViewFactory, 'formViewFactory');
        Assert.notNullOrUndefined(positionFormViewFactory, 'positionFormViewFactory');
        Assert.notNullOrUndefined(positionenFormViewFactory, 'positionenFormViewFactory');
        Assert.notNullOrUndefined(templateDialogService, 'templateDialogService');
        Assert.notNullOrUndefined(currencyFormatter, 'currencyFormatter');
        Assert.notNullOrUndefined(viewportService, 'viewportService');
        Assert.notNullOrUndefined(notizenService, 'notizenService');

        this.istCarGarantie = this.produkt?.art === ProduktArt.CarGarantie;
    }

    ngOnInit(): void {
        const name = PRODUKT_CONFIG_FEATURES.Wartung.name;
        this.notizen$ = this.notizenService.init(this.produkt, name);
        this.viewport$ = this.viewportService.observe();
        this.showBottomSheet$ = this.viewport$.pipe(
            map((viewport) => viewport !== Viewport.Desktop && !this.istCarGarantie),
        );
        this.showSidePanel$ = this.viewport$.pipe(
            map((viewport) => viewport === Viewport.Desktop && !this.istCarGarantie),
        );
        this.init(name);

        this.letzterServiceNichtBekannt$.next(this.form.get('letzterServiceNichtBekannt').value);
        this.naechsterServiceFaelligNichtBekannt$.next(this.form.get('naechsterServiceFaelligNichtBekannt').value);
        this.naechsteHauptAbgasUntersuchungNichtBekannt$.next(
            this.form.get('naechsteHauptAbgasUntersuchungNichtBekannt').value,
        );

        this.naechsteHuAuChangedSubscription = this.form
            .get('hauptAbgasUntersuchungFaelligKosten')
            .valueChanges.subscribe((value) => this.handleKostenPositionChange(value));
        this.naechsterServiceChangedSubscription = this.form
            .get('naechsterServiceFaelligKosten')
            .valueChanges.subscribe((value) => this.handleKostenPositionChange(value));
        this.zahnriemenChangedSubscription = this.form
            .get('zahnriemenFaelligKosten')
            .valueChanges.subscribe((value) => this.handleKostenPositionChange(value));
    }

    ngAfterViewInit(): void {
        this.prefillForm();
    }

    ngOnDestroy(): void {
        this.naechsteHuAuChangedSubscription.unsubscribe();
        this.naechsterServiceChangedSubscription.unsubscribe();
        this.zahnriemenChangedSubscription.unsubscribe();
        super.ngOnDestroy();
    }

    onPositionSelect(bezeichnungIndex: number): void {
        Assert.notNullOrUndefined(bezeichnungIndex, 'bezeichnungIndex');
        const bezeichnung = this.positionenValues.values[bezeichnungIndex];
        this.createPosition(bezeichnung);
    }

    onPositionSubmit(bezeichnung: string): void {
        Assert.notNullOrEmpty(bezeichnung, 'bezeichnung');
        this.createPosition(ViewFormControlFormatters.firstLetterToUppercase.format(bezeichnung));
    }

    onRowOpen(row: ViewFormGroup): void {
        Assert.notNullOrUndefined(row, 'row');
        const index = this.positionen.controls.indexOf(row);
        this.editPosition(index, row.getRawValue());
    }

    onRowRemove(row: ViewFormGroup): void {
        Assert.notNullOrUndefined(row, 'row');
        const index = this.positionen.controls.indexOf(row);
        this.positionen.removeAt(index);
        this.updateWerteService.resetAufwendungen(this.produkt);
    }

    onNotizenChange(notizen: Notiz[]): void {
        Assert.notNullOrUndefined(notizen, 'notizen');
        this.notizenService.save(notizen).pipe(take(1)).subscribe();
    }

    protected createForm(): ViewFormGroup {
        const form = this.formViewFactory.create(this.produkt.wartung, this.fields, this.produkt.art);
        this.positionen = form.get(this.positionenName) as ViewFormArray;
        this.positionenFields = (
            this.fields.find((x: FeatureFieldArray) => x.arrayName === this.positionenName) as FeatureFieldArray
        ).fields;
        this.rows$ = form.valueChanges.pipe(
            startWith([]),
            map(() => this.positionenFormViewFactory.create(form.getRawValue(), this.fields, this.positionenFields)),
            map((controls) => controls.concat([...this.positionen.controls] as ViewFormGroup[])),
        );
        this.sums$ = this.rows$.pipe(
            map((rows: AbstractControl[]) => {
                const sums = [];
                this.positionenFields.forEach((field: FeatureField) => {
                    if (field.name === PRODUKT_CONFIG_FEATURES.Wartung.fields.Positionen.fields.Kosten.name) {
                        const sum = rows.reduce((cv, cy: ViewFormGroup) => cv + cy.getRawValue()[field.name], 0);
                        sums.push(`${this.currencyFormatter.format(sum)}&nbsp;€`);
                    }
                });
                return sums.join('&nbsp;|&nbsp;');
            }),
        );
        return form;
    }

    private editPosition(index: number, position: WartungPosition): void {
        const fields = this.positionenFields;
        const form = this.positionFormViewFactory.create(position, fields);
        const buttons = [
            this.templateDialogService.getCancelButtonSetting(),
            this.templateDialogService.getSaveButtonSetting(),
        ];
        const data: ProduktDetailWartungDialogData = { form, fields };

        this.templateDialogService
            .openTemplate(position.bezeichnung, buttons, this.dialogTemplate, data)
            .pipe(take(1))
            .subscribe((result) => {
                if (result?.name === this.templateDialogService.getSaveButtonSetting().title) {
                    (this.positionen.controls[index] as ViewFormGroup) = result.data.form;
                    this.positionen.updateValueAndValidity();
                }
            });
    }

    private createPosition(bezeichnung: string): void {
        const fields = this.positionenFields;
        const form = this.positionFormViewFactory.create({ bezeichnung }, fields);
        const buttons = [
            this.templateDialogService.getCancelButtonSetting(),
            this.templateDialogService.getSaveButtonSetting(),
        ];
        const data: ProduktDetailWartungDialogData = { form, fields };

        this.templateDialogService
            .openTemplate(bezeichnung, buttons, this.dialogTemplate, data)
            .pipe(take(1))
            .subscribe((result) => {
                if (result?.name === this.templateDialogService.getSaveButtonSetting().title) {
                    this.positionen.push(result.data.form);
                }
            });
    }

    private prefillForm(): void {
        if (
            this.form?.get(this.fieldNaechsterServiceBeschreibung) &&
            this.produkt?.wartung?.naechsterServiceBeschreibung === null
        ) {
            this.textbausteineService.prefillWithStandardTextbausteine(
                this.feature,
                this.fieldNaechsterServiceBeschreibung,
                this.produkt.art,
                this.form,
            );
        }
    }

    private handleKostenPositionChange(value: string) {
        if (!value || this.formatter.parse(value) === 0) {
            this.updateWerteService.resetAufwendungen(this.produkt);
        }
    }
}
