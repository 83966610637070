<div class="flex flex-col gap-2">
    @if (!field().settings.hideLabel) {
        <span class="label">{{ field().label || '' | translate }}</span>
    }

    @if (fieldFormArray().controls.length === 0) {
        <p>{{ field().settings.emptyMessage | translate }}</p>
    }

    <div class="flex flex-col gap-4">
        @for (control of fieldFormArray().controls; track control; let index = $index; let last = $last) {
            <div class="last:pb-4">
                @if (getFormGroupByControl(control); as formGroup) {
                    <div class="flex items-end justify-between gap-2">
                        <div [ngClass]="field().settings.style || 'flex flex-grow flex-col gap-4'">
                            @for (field of field().fields; track field) {
                                <div [ngClass]="field.style">
                                    <app-form-field
                                        [field]="field"
                                        [fieldFormControl]="getFormControlByFieldName(formGroup, field.name)"
                                        [parentFormGroup]="formGroup"
                                    />
                                </div>
                            }
                        </div>

                        <button [disabled]="isDeleteButtonDisabled(index)" (click)="removeItem(index)" mat-icon-button>
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                    @if (!last && field().fields.length > 1) {
                        <div class="pt-6">
                            <mat-divider />
                        </div>
                    }
                }
            </div>
        }
    </div>

    <button
        class="self-start"
        [disabled]="isAddButtonDisabled()"
        (click)="addItem()"
        mat-stroked-button
        color="primary"
    >
        <mat-icon>add</mat-icon>
        {{ field().settings.newItemLabel | translate }}
    </button>
</div>
