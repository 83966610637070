import { formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnDestroy, OnInit, Signal, signal, TemplateRef, ViewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { AbschlussDownloadService } from '@data/api-gateway';
import { BilderDownloadService } from '@data/api-gateway/service/bilder-download.service';
import { CsvDownloadService } from '@data/api-gateway/service/csv-download.service';
import {
    ProduktArt,
    ProduktArtNachbewertung,
    ProduktArtUebersicht,
    ProduktIcon,
    ProduktStatus,
} from '@data/domain/schema/enum';
import { Produkt } from '@data/domain/schema/type';
import { ProduktService } from '@data/domain/service/produkt.service';
import { OldtimerRoutes } from '@modules/oldtimer/oldtimer.routes';
import { ProduktDetailNachbewertungDialogComponent } from '@modules/produkt/component/produkt-detail-nachbewertung-dialog/produkt-detail-nachbewertung-dialog.component';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ProduktUebersichtFilterService } from '@modules/produkt/service/produkt-uebersicht-filter.service';
import { ProduktUebersichtResolveService } from '@modules/produkt/service/produkt-uebersicht-resolve.service';
import { ButtonType } from '@shared/component/button-indicator/button/button.component';
import { TableComponent } from '@shared/component/data-table';
import { Assert } from '@shared/helper/assert';
import { ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { EnumValues } from '@shared/helper/values';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { Viewport, ViewportService } from '@shared/service/viewport.service';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, first, switchMap, take } from 'rxjs/operators';

interface ProduktNachbewertungDialogData {
    produkt: Produkt;
}

export interface FilterValues {
    art: number;
    searchText: string;
    von: string;
    bis: string;
    status: number;
}

@Component({
    selector: 'app-produkt-uebersicht-gtue-em',
    templateUrl: './produkt-uebersicht-gtue-em.component.html',
    styleUrls: ['./produkt-uebersicht-gtue-em.component.scss'],
})
export class ProduktUebersichtGtueEmComponent implements OnInit, OnDestroy {
    trackById = TrackBy.trackById;

    produkte: Produkt[];
    name = 'produktUebersicht';
    produktStatus = new EnumValues(ProduktStatus);
    produktStatusEnum = ProduktStatus;
    loading = signal(false);
    loadingCsv = signal(false);

    searchTerm = '';
    form: ViewFormGroup;
    produktArt = ProduktArt;
    produktIcon = ProduktIcon;
    produktArtNachbewertungValues = new EnumValues(ProduktArtNachbewertung);
    readonly ButtonType = ButtonType;

    produktArtEnumValues = new EnumValues(ProduktArtUebersicht);

    filter$ = new BehaviorSubject<string>('');
    filterValues: FilterValues = {
        art: undefined,
        searchText: '',
        von: '',
        bis: '',
        status: 0,
    };

    minDate: Date = new Date(2019, 0, 1);
    maxDate: Date = new Date();

    viewport: Signal<Viewport>;
    viewportMobile = Viewport.Mobile;

    private csvFilenamePrefix = 'EM_Produktdaten_';
    private csvFilenameDateFormat = 'yyyy-MM-dd_HH-mm';

    @ViewChild('table')
    table: TableComponent;

    @ViewChild('nachbewertungArtSelection')
    selectedNachbewertungArt: ProduktDetailNachbewertungDialogComponent;

    @ViewChild('dialogConfirmDuplicate', { static: true })
    dialogConfirmDuplicateTemplate: TemplateRef<HTMLElement>;

    private subscriptions: Subscription[] = [];

    constructor(
        @Inject(LOCALE_ID) public locale: string,
        private readonly produktUebersichtResolveService: ProduktUebersichtResolveService,
        private readonly router: Router,
        private readonly viewportService: ViewportService,
        private readonly bilderDownloadService: BilderDownloadService,
        private readonly csvDownloadService: CsvDownloadService,
        private readonly templateDialogService: TemplateDialogService,
        private readonly produktService: ProduktService,
        private readonly filterService: ProduktUebersichtFilterService,
        private readonly abschlussDownloadService: AbschlussDownloadService,
        private readonly snackBarService: SnackBarService,
    ) {
        Assert.notNullOrUndefined(produktUebersichtResolveService, 'produktUebersichtResolveService');
        Assert.notNullOrUndefined(router, 'router');
        Assert.notNullOrUndefined(bilderDownloadService, 'bilderDownloadService');
        Assert.notNullOrUndefined(csvDownloadService, 'csvDownloadService');
        Assert.notNullOrUndefined(templateDialogService, 'templateDialogService');
        Assert.notNullOrUndefined(produktService, 'produktService');
        Assert.notNullOrUndefined(filterService, 'filterService');
        Assert.notNullOrUndefined(snackBarService, 'snackBarService');
        this.viewport = toSignal<Viewport>(this.viewportService.observe());
    }

    ngOnInit(): void {
        this.produkte = this.produktUebersichtResolveService.get();
        this.setProdukteErstelltAmMitAuftragDatum();
        this.setProdukteAuftragNummer();

        this.form = new ViewFormGroup({
            art: new ViewFormControl(0),
            searchText: new ViewFormControl(''),
            von: new ViewFormControl(this.getOldestVisibleProduktDate(this.produkte)),
            bis: new ViewFormControl(this.maxDate),
            dateToggle: new ViewFormControl(false),
            status: new ViewFormControl(false),
        });
        this.createFilterSubscriptions();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    onAction(produkt: Produkt): void {
        Assert.notNullOrUndefined(produkt, 'produkt');

        if (produkt.art === ProduktArt.OldTimer || !produkt.art) {
            this.router.navigate([OldtimerRoutes.Oldtimer, produkt.id]);
            return;
        }

        this.router.navigate(['produkt', 'detail', produkt.id]);
    }

    onDownloadClick(event: MouseEvent, identnummer: string, disabled: boolean): void {
        event.stopPropagation();
        if (disabled) {
            return;
        }
        this.abschlussDownloadService
            .get(identnummer)
            .pipe(take(1))
            .subscribe((response) => {
                if (response.url) {
                    this.onDownloadResponse(response.url);
                }
            });
    }

    filterPredicate(produkt: Produkt, filter: string): boolean {
        const filterObject = JSON.parse(filter) as FilterValues;

        if (ProduktUebersichtFilterService.checkStatusNotOpen(filterObject, produkt)) {
            return false;
        }

        if (ProduktUebersichtFilterService.checkProduktArtNotMatching(filterObject, produkt)) {
            return false;
        }

        if (produkt.erstelltAm && filterObject.von && filterObject.bis) {
            const dateResult = ProduktUebersichtFilterService.filterByDate(filterObject, produkt);
            if (dateResult !== undefined) {
                if (!dateResult) {
                    return false;
                }
            }
        }

        if (ProduktUebersichtFilterService.checkSearchText(filterObject, produkt)) {
            return true;
        }
        return false;
    }

    onBilderDownload(event: MouseEvent, produktId: string, disabled: boolean): void {
        Assert.notNullOrUndefined(produktId, 'produktId');
        event.stopPropagation();

        if (disabled) {
            return;
        }

        this.loading.set(true);
        this.bilderDownloadService
            .get(produktId)
            .pipe(
                take(1),
                catchError((error) => {
                    if (error.status === 404) {
                        this.snackBarService.info('produkt.uebersicht.downloadKeineBilder');
                        return of(null);
                    }
                    this.snackBarService.error('produkt.uebersicht.downloadBilderError');
                    return of(null);
                }),
            )
            .subscribe((url) => {
                this.loading.set(false);
                if (url) {
                    window.open(url);
                }
            });
    }

    onClickProduktSearch(): void {
        this.searchProdukt();
    }

    alphaNumericOnly(e: KeyboardEvent): boolean {
        return String(e.key).match(/[^a-zA-Z0-9]/g) === null;
    }

    onClickDuplicate($event: MouseEvent, element: Produkt): void {
        const title = 'nachbewertung.title';
        const buttons = [
            this.templateDialogService.getCancelButtonSetting(),
            this.templateDialogService.getSaveButtonSetting(),
        ];
        const data: ProduktNachbewertungDialogData = { produkt: element };
        $event.stopPropagation();

        let selectedProduktArt = ProduktArt.None;

        this.templateDialogService
            .openTemplate(title, buttons, this.dialogConfirmDuplicateTemplate, data)
            .pipe(
                take(1),
                switchMap((result) => {
                    if (result.name === this.templateDialogService.getSaveButtonSetting().title) {
                        this.loading.set(true);
                        selectedProduktArt = this.selectedNachbewertungArt.getSelectedValue();
                        return this.produktService.create(selectedProduktArt);
                    }
                    return of(null);
                }),
                switchMap((produkt) => {
                    if (produkt) {
                        return this.produktService.getDuplikat(element.id, produkt.id, selectedProduktArt);
                    }
                    return of(null);
                }),
                catchError((error) => {
                    console.error(error);
                    this.snackBarService.error('nachbewertung.error');
                    return of(null);
                }),
            )
            .subscribe((produktDuplikat) => {
                this.loading.set(false);
                if (produktDuplikat) {
                    this.router.navigateByUrl(`/produkt/detail/${produktDuplikat.id}`);
                }
            });
    }

    onResetFilter(): void {
        this.form.patchValue({
            art: undefined,
            searchText: '',
            von: this.getOldestVisibleProduktDate(this.produkte),
            bis: this.maxDate,
            status: 0,
            dateToggle: false,
        });
    }

    onCsvDownload(event: MouseEvent): void {
        event.stopPropagation();

        this.loadingCsv.set(true);
        this.csvDownloadService
            .get(this.form.get('von').value, this.form.get('bis').value)
            .pipe(
                take(1),
                catchError((error) => {
                    console.error(error);
                    this.snackBarService.error('produkt.uebersicht.downloadCSVError');
                    return of(null);
                }),
            )
            .subscribe((data) => {
                this.loadingCsv.set(false);

                if (!data) {
                    return;
                }

                if (data.status === 204) {
                    this.snackBarService.info('produkt.uebersicht.downloadCSVNoData');
                    return;
                }

                if (data.body) {
                    const csvData = this.base64DecodeEncode(data.body);
                    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `${this.csvFilenamePrefix}${formatDate(new Date(), this.csvFilenameDateFormat, this.locale)}.csv`;
                    link.click();
                }
            });
    }

    protected nachbewertungEnabled(produktArt: ProduktArt): boolean {
        return !(produktArt === ProduktArt.VtiTooling || produktArt === ProduktArt.CarGarantie);
    }

    private setProdukteErstelltAmMitAuftragDatum(): void {
        this.produkte.forEach((p) => {
            if (p.erstelltAm === null && p.auftrag?.erstellungsTag) {
                p.erstelltAm = p.auftrag.erstellungsTag;
            }
        });
    }

    private setProdukteAuftragNummer(): void {
        this.produkte.forEach((p) => {
            if (!p.auftrag?.nummer && p.auftrag?.vorgangsnummer) {
                p.auftrag.nummer = p.auftrag.vorgangsnummer;
            }
        });
    }

    private base64DecodeEncode(input: string): Uint8Array {
        // Decode the base64
        const binaryString = atob(input);
        // Convert the bytes to a string
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        const decoder = new TextDecoder('utf-8');

        let decodedString = decoder.decode(bytes);
        // Add BOM to the start of the string, to fix excel encoding issues
        decodedString = `\ufeff${decodedString}`;
        const encoder = new TextEncoder();
        return encoder.encode(decodedString);
    }

    private searchProdukt(): void {
        const searchTextControl = this.form.get('searchText');
        const startDateControl = this.form.get('von');
        const endDateControl = this.form.get('bis');
        const dateToggleControl = this.form.get('dateToggle');
        const searchText = searchTextControl?.value || '';

        this.loading.set(true);

        let startDate = startDateControl?.value;
        let endDate = endDateControl?.value;

        if (dateToggleControl.value === true) {
            startDate = null;
            endDate = null;
        }
        this.produktUebersichtResolveService
            .resolve(searchText, startDate, endDate)
            .pipe(
                first(),
                catchError((error) => {
                    this.snackBarService.info('produkt.uebersicht.Suche', error);
                    return of(null);
                }),
            )
            .subscribe((result) => {
                this.loading.set(false);
                if (result) {
                    if (result.length < 1) {
                        this.snackBarService.info('produkt.uebersicht.IdentSucheNothingFound');
                        return;
                    }
                    this.produkte = result;
                    this.table.refresh(this.produkte);
                }
            });
    }

    private onDownloadResponse(url: string): void {
        if (url) {
            window.open(url, '_blank');
        }
    }

    private createFilterSubscriptions(): void {
        this.createFilterSubscription('art');
        this.createFilterSubscription('status');
        this.createFilterSubscription('searchText', 300);
        this.createFilterSubscription('von', 300);
        this.createFilterSubscription('bis', 300);

        const dateToggleControl = this.form.get('dateToggle');
        const startDateControl = this.form.get('von');
        const endDateControl = this.form.get('bis');

        if (dateToggleControl && startDateControl && endDateControl) {
            const dateToggleSubscription = dateToggleControl.valueChanges.subscribe((value) => {
                if (value) {
                    startDateControl.disable();
                    endDateControl.disable();
                    this.filterValues.von = null;
                    this.filterValues.bis = null;
                } else {
                    startDateControl.enable();
                    endDateControl.enable();
                    this.filterValues.von = startDateControl.value;
                    this.filterValues.bis = endDateControl.value;
                }
                this.filter$.next(JSON.stringify(this.filterValues));
            });
            this.subscriptions.push(dateToggleSubscription);
        }
    }

    private createFilterSubscription(controlName: string, debounce = 0): void {
        const control = this.form.get(controlName);

        if (!control) {
            return;
        }

        const sub = control.valueChanges.pipe(distinctUntilChanged(), debounceTime(debounce)).subscribe(() => {
            this.filterValues[controlName] = control.value;
            this.filter$.next(JSON.stringify(this.filterValues));
        });

        this.subscriptions.push(sub);
    }

    private getOldestVisibleProduktDate(produkte: Produkt[]): Date {
        if (!produkte || produkte.length === 0) {
            return new Date();
        }
        produkte.sort((a, b) => new Date(b.erstelltAm).getTime() - new Date(a.erstelltAm).getTime());
        return new Date(produkte[produkte.length - 1].erstelltAm);
    }
}
