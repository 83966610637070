<div class="grid lg:grid-cols-2">
    <mat-card class="m-2 mb-12" appearance="outlined" style="height: auto">
        @if (!loading()) {
            @if (bueroSettings()) {
                <app-form-array class="p-6" [arrayField]="config" [fieldFormArray]="form()" />
                <div class="flex justify-end p-6">
                    <button [disabled]="saveDisabled()" (click)="onSave($event)" mat-flat-button color="primary">
                        <mat-icon>save</mat-icon>
                        {{ 'einstellungen.kalkulation.save' | translate }}
                    </button>
                </div>
            } @else {
                <span class="flex flex-col items-center justify-center gap-4 p-6">
                    <p>{{ 'einstellungen.kalkulation.loadError' | translate }}</p>
                    <button (click)="reloadSettings()" mat-flat-button color="secondary">
                        {{ 'einstellungen.kalkulation.retry' | translate }}
                    </button>
                </span>
            }
        } @else {
            <span class="flex flex-col items-center justify-center gap-4 p-6">
                <p>{{ 'einstellungen.kalkulation.loading' | translate }}</p>
                <mat-progress-spinner mode="indeterminate" diameter="50" />
            </span>
        }
    </mat-card>
</div>
