@if (arrayField().isCard) {
    <mat-card class="h-auto" appearance="outlined">
        <mat-card-content>
            <ng-container *ngTemplateOutlet="formArrayContent" />
        </mat-card-content>
    </mat-card>
} @else {
    <ng-container *ngTemplateOutlet="formArrayContent" />
}

<ng-template #formArrayContent>
    @if (arrayField(); as arrayField) {
        <div class="form-array">
            @if (arrayField.completionApi) {
                <div class="pb-6">
                    <app-form-completion
                        [completionApiConfig]="arrayField.completionApi"
                        (selectedEntry)="addItem($event)"
                    />
                </div>
            }

            <div class="flex items-center justify-between pb-6">
                <h3>{{ arrayField.label || '' | translate }} ({{ fieldFormArray().length }})</h3>

                <button (click)="addItem()" mat-mini-fab color="primary">
                    <mat-icon>add</mat-icon>
                </button>
            </div>

            @if (fieldFormArray().controls.length === 0) {
                <p>{{ arrayField.settings.emptyMessage | translate }}</p>
            }

            <div [class.reverse]="arrayField.settings.isDescending">
                <mat-accordion>
                    @for (control of fieldFormArray().controls; track control; let first = $first; let index = $index) {
                        @if (getFormGroupByControl(control); as formGroup) {
                            <mat-expansion-panel [expanded]="first">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div class="overflow-hidden truncate whitespace-nowrap">
                                            <app-item-preview-template
                                                [previewTemplates]="arrayField.settings.previewTemplate || []"
                                                [formGroup]="formGroup"
                                                [newItemLabel]="arrayField.settings.newItemLabel"
                                            />
                                        </div>
                                    </mat-panel-title>

                                    <mat-panel-description class="justify-end">
                                        <mat-icon>keyboard_arrow_down</mat-icon>
                                    </mat-panel-description>
                                </mat-expansion-panel-header>

                                <div class="grid grid-cols-1 gap-6 px-0 py-6" [ngClass]="arrayField.style">
                                    @for (field of arrayField.fields; track field) {
                                        <div class="col-span-1" [ngClass]="field.style">
                                            @if (isArrayField(field)) {
                                                @if (getFormArrayByFieldName(formGroup, field.name); as formArray) {
                                                    <app-form-dense-array
                                                        [field]="field"
                                                        [fieldFormArray]="formArray"
                                                    />
                                                }
                                            } @else {
                                                <app-form-field
                                                    [field]="field"
                                                    [fieldFormControl]="
                                                        getFormControlByFieldName(formGroup, field.name)
                                                    "
                                                    [parentFormGroup]="formGroup"
                                                />
                                            }
                                        </div>
                                    }
                                </div>
                                <div class="flex justify-end">
                                    <button (click)="removeItem(index)" mat-flat-button color="primary">
                                        <mat-icon>delete</mat-icon>
                                        {{ 'forms.array.delete' | translate }}
                                    </button>
                                </div>
                            </mat-expansion-panel>
                        }
                    }
                </mat-accordion>
            </div>
        </div>
    }
</ng-template>
