<div>
    <div>
        <label class="text-field-label" for="searchInput">{{ completionApiConfig().label | translate }}</label>
        <mat-form-field>
            <input
                id="searchInput"
                [matAutocomplete]="auto"
                [formControl]="formControl"
                [placeholder]="completionApiConfig().placeholder | translate"
                matInput
            />
            @if (isLoading()) {
                <ng-container matIconSuffix>
                    <mat-icon>sync</mat-icon>
                </ng-container>
            }

            <mat-autocomplete #auto="matAutocomplete">
                @for (item of completions(); track item) {
                    <mat-option (click)="selectedEntry.emit(item); formControl.reset('')">
                        @for (previewTemplate of completionApiConfig().previewTemplate; track previewTemplate.key) {
                            @if (item[previewTemplate.key]; as value) {
                                <span [innerHTML]="value | highlightMatch: formControl.value"></span>
                                <span class="pr-1 last-of-type:hidden">{{ previewTemplate.separator || '|' }}</span>
                            }
                        }
                        <button class="ml-auto" (click)="deleteCompletionItem($event, item)" mat-icon-button>
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-option>
                }
            </mat-autocomplete>
        </mat-form-field>
    </div>
</div>
