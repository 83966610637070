import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
    ButtonGroupTabsField,
    ConfigField,
    DateField,
    DateRangeField,
    FormField,
    InputField,
    LabelField,
    ListField,
    RadioGroupField,
    SelectField,
    StatusReportField,
    TextareaField,
    TextModuleField,
    VinField,
} from '@shared/models/form-field.model';
import { FormFieldDateRangeComponent } from '@shared/standalone/form-fields/form-field-date-range/form-field-date-range.component';
import { FormFieldDateTimeComponent } from '@shared/standalone/form-fields/form-field-date-time/form-field-date-time.component';
import { FormFieldImageUploadComponent } from '@shared/standalone/form-fields/form-field-image-upload/form-field-image-upload.component';
import { FormFieldInputComponent } from '@shared/standalone/form-fields/form-field-input/form-field-input.component';
import { FormFieldListComponent } from '@shared/standalone/form-fields/form-field-list/form-field-list.component';
import { FormFieldSelectComponent } from '@shared/standalone/form-fields/form-field-select/form-field-select.component';
import { FormFieldStatusReportComponent } from '@shared/standalone/form-fields/form-field-status-report/form-field-status-report.component';
import { FormFieldTextModuleComponent } from '@shared/standalone/form-fields/form-field-text-module/form-field-text-module.component';
import { FormFieldTextareaComponent } from '@shared/standalone/form-fields/form-field-textarea/form-field-textarea.component';
import { FormFieldToggleComponent } from '@shared/standalone/form-fields/form-field-toggle/form-field-toggle.component';
import { FormFieldRadioGroupComponent } from '../form-fields/form-field-radio-group/form-field-radio-group.component';
import { FormFieldVinComponent } from '../form-fields/form-field-vin/form-field-vin.component';

@Component({
    selector: 'app-form-field',
    standalone: true,
    imports: [
        TranslateModule,
        ReactiveFormsModule,
        FormFieldInputComponent,
        FormFieldDateTimeComponent,
        FormFieldTextModuleComponent,
        FormFieldListComponent,
        FormFieldToggleComponent,
        FormFieldSelectComponent,
        FormFieldRadioGroupComponent,
        FormFieldVinComponent,
        FormFieldTextareaComponent,
        FormFieldDateRangeComponent,
        FormFieldImageUploadComponent,
        FormFieldStatusReportComponent,
        NgClass,
    ],
    templateUrl: './form-field.component.html',
    styleUrl: './form-field.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldComponent {
    readonly field = input.required<ConfigField>();
    readonly fieldFormControl = input<FormControl>();
    readonly parentFormGroup = input.required<FormGroup>();

    protected readonly FormField = FormField;

    isLabelField(field: ConfigField): field is LabelField {
        return field.type === FormField.Label;
    }

    isInputField(field: ConfigField): field is InputField {
        return field.type === FormField.Input;
    }

    isTextareaField(field: ConfigField): field is TextareaField {
        return field.type === FormField.Textarea;
    }

    isSelectField(field: ConfigField): field is SelectField {
        return field.type === FormField.Select;
    }

    isListField(field: ConfigField): field is ListField {
        return field.type === FormField.List;
    }

    isTextModuleField(field: ConfigField): field is TextModuleField {
        return field.type === FormField.TextModule;
    }

    isDateField(field: ConfigField): field is DateField {
        return field.type === FormField.Date;
    }

    isDateRangeField(field: ConfigField): field is DateRangeField {
        return field.type === FormField.DateRange;
    }

    isButtonGroupTabsField(field: ConfigField): field is ButtonGroupTabsField {
        return field.type === FormField.ButtonGroupTabs;
    }

    isRadioGroupField(field: ConfigField): field is RadioGroupField {
        return field.type === FormField.RadioGroup;
    }

    isVinField(field: ConfigField): field is VinField {
        return field.type === FormField.Vin;
    }

    isStatusReportField(field: ConfigField): field is StatusReportField {
        return field.type === FormField.StatusReport;
    }
}
