import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { OldtimerProdukt } from '@data/domain/schema/types';
import { OldtimerGraphqlService } from '@modules/oldtimer/graphql/oldtimer-graphql.service';
import { Observable, catchError, of } from 'rxjs';
import { map } from 'rxjs/operators';

export const oldtimerResolver: (route: ActivatedRouteSnapshot) => Observable<OldtimerProdukt | Promise<boolean>> = (
    route,
) => {
    const router = inject(Router);
    const oldtimerGraphqlService = inject(OldtimerGraphqlService);

    return oldtimerGraphqlService.getOldtimerProdukt(route.params['id']).pipe(
        catchError((_error) => {
            return of(undefined);
        }),
        map((oldtimer) => {
            if (oldtimer?.id) return oldtimer;
            return router.navigateByUrl('/dashboard');
        }),
    );
};
