import { Injectable } from '@angular/core';
import { LackmessungMessung } from '@data/domain/schema/type';
import { FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ProduktDetailTextbausteinFormViewFactory } from '@modules/produkt/factory/produkt-detail-textbaustein-form-view.factory';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormControlFormatters } from '@shared/helper/form-controls/view-form-control-formatters';
import { DecimalSeperatorFormatterArrayService } from '@shared/service/form-controls/decimal-separator-formatter-array.service';
import { FormViewModelBaseFactory } from '../form-view-base.factory';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailLackmessungMessungFormViewFactory extends FormViewModelBaseFactory<LackmessungMessung> {
    constructor(
        private readonly decimalSeperatorFormatterArray: DecimalSeperatorFormatterArrayService,
        private readonly produktDetailTextbausteinFormViewFactory: ProduktDetailTextbausteinFormViewFactory,
    ) {
        super();
    }

    protected createField(model: LackmessungMessung, name: string): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.Lackmessung.fields.Messungen.fields;
        switch (name) {
            case fields.Position.name:
                return new ViewFormControl(model.position);
            case fields.Bezeichnung.name:
                return new ViewFormControl(model.bezeichnung);
            case fields.Dicke.name:
                return new ViewFormControl(model.dicke);
            case fields.Ergebnis.name:
                return new ViewFormControl(model.ergebnis || [], {
                    arrayFormatter: this.decimalSeperatorFormatterArray,
                });
            case fields.Beschreibung.name:
                return new ViewFormControl(model.beschreibung, {
                    formatter: ViewFormControlFormatters.firstLetterToUppercase,
                });
            case fields.Bilder.name:
                return new ViewFormControl(model.bilder || []);
            default:
                throw new Error(`Could not create field for name: '${name}'.`);
        }
    }

    protected createArray(model: LackmessungMessung, arrayName: string, arrayFields: FeatureFields): ViewFormArray {
        const fields = PRODUKT_CONFIG_FEATURES.Lackmessung.fields.Messungen.fields;
        if (arrayName === fields.Bemerkungen.name) {
            const bemerkungen = model.bemerkungen || [];
            return new ViewFormArray(
                bemerkungen.map((bemerkung) =>
                    this.produktDetailTextbausteinFormViewFactory.create(bemerkung, arrayFields),
                ),
            );
        } else {
            throw new Error(`Could not create array for name: '${arrayName}'.`);
        }
    }
}
