<h1 mat-dialog-title>{{ 'kalkulation.dialog.title' | translate }}</h1>
<mat-dialog-content>
    <mat-label>{{ 'kalkulation.dialog.text' | translate }}</mat-label>
    <h3 class="pt-6">{{ 'kalkulation.dialog.faktoren' | translate }}</h3>
    @if (!loading()) {
        <app-form-field-select
            [field]="configField"
            [fieldFormControl]="form().get('kalkulationsFaktoren')"
            [parentFormGroup]="form()"
        />
        @if (form().get('kalkulationsFaktoren')?.value !== null) {
            <app-form-field-select
                [field]="systemArtSelectField"
                [fieldFormControl]="form().get('systemArtSelect')"
                [parentFormGroup]="form()"
            />
        }

        @if (selectedSettings()) {
            <ng-container *ngTemplateOutlet="settingDetails" />
        } @else {
            <p>{{ 'kalkulation.dialog.keineFaktorenHinweis' | translate }}</p>
        }
    } @else {
        <span class="flex flex-col items-center justify-center gap-4 p-6">
            <p>{{ 'kalkulation.dialog.loadSettings' | translate }}</p>
            <mat-progress-spinner mode="indeterminate" diameter="50" />
        </span>
    }
</mat-dialog-content>

<mat-dialog-actions class="p-0">
    <button (click)="onCancel()" mat-stroked-button color="primary">
        {{ 'feature.cancel' | translate }}
    </button>
    <button (click)="onSubmit()" mat-flat-button color="primary">
        {{ 'feature.confirm' | translate }}
    </button>
</mat-dialog-actions>

<ng-template #settingDetails>
    <div class="flex gap-6">
        <p>
            {{
                ('einstellungen.kalkulation.faktoren.mechanik' | translate) +
                    ': ' +
                    getKalkulationDisplayValue(selectedSettings().arbeitslohnfaktoren?.mechanik, '€')
            }}
        </p>
        <p>
            {{
                ('einstellungen.kalkulation.faktoren.karosserie' | translate) +
                    ': ' +
                    getKalkulationDisplayValue(selectedSettings().arbeitslohnfaktoren?.karosserie, '€')
            }}
        </p>
        <p>
            {{
                ('einstellungen.kalkulation.faktoren.elektrik' | translate) +
                    ': ' +
                    getKalkulationDisplayValue(selectedSettings().arbeitslohnfaktoren?.elektrik, '€')
            }}
        </p>
        <p>
            {{
                ('einstellungen.kalkulation.faktoren.dellenDruecken' | translate) +
                    ': ' +
                    getKalkulationDisplayValue(selectedSettings().arbeitslohnfaktoren?.dellenDruecken, '€')
            }}
        </p>
    </div>

    @if (form().get('systemArtSelect')?.value === SystemArtEnum.AZT) {
        <div class="flex gap-6">
            <p>
                {{
                    ('einstellungen.kalkulation.faktoren.lacklohn' | translate) +
                        ': ' +
                        getKalkulationDisplayValue(selectedSettings().lackfaktoren?.azt?.lacklohn, '€')
                }}
            </p>
            <p>
                {{
                    ('einstellungen.kalkulation.faktoren.lackmaterial' | translate) +
                        ': ' +
                        getKalkulationDisplayValue(selectedSettings().lackfaktoren?.azt?.lackmaterial, '%')
                }}
            </p>
        </div>
    }
    @if (form().get('systemArtSelect')?.value === SystemArtEnum.Hersteller) {
        <div class="flex gap-6">
            <p>
                {{
                    ('einstellungen.kalkulation.faktoren.lacklohn' | translate) +
                        ': ' +
                        getKalkulationDisplayValue(selectedSettings().lackfaktoren?.hersteller?.lacklohn, '€')
                }}
            </p>
            <p>
                {{
                    ('einstellungen.kalkulation.faktoren.lackmaterial' | translate) +
                        ': ' +
                        getKalkulationDisplayValue(selectedSettings().lackfaktoren?.hersteller?.lackmaterial, '%')
                }}
            </p>
        </div>
    }
</ng-template>
