<div class="form-field-select">
    @if (field(); as field) {
        <label class="text-field-label" [class.required]="field.required" for="formFieldSelect">
            {{ field.label || '' | translate }}
        </label>
        <mat-form-field>
            <mat-select id="formFieldSelect" #matSelect [formControl]="fieldFormControl()">
                <mat-option [value]="null" />
                @for (option of field.options; track option) {
                    @if (isOptionVisible(option)) {
                        <mat-option [value]="option.value">{{ option.label | translate }}</mat-option>
                    }
                }
            </mat-select>
            <mat-icon
                class="absolute right-0 top-1/2 -translate-y-1/2 transform"
                [ngClass]="matSelect.panelOpen ? 'icon-chevron-up' : 'icon-chevron-down'"
            />

            @for (error of getValidationErrors(); track error.key) {
                <mat-error>
                    {{ 'forms.errors.' + error.key | translate: error.value }}
                </mat-error>
            }
        </mat-form-field>
    }
</div>
