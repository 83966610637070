import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

export const DEFAULT_FORMAT = 'DD.MM.YYYY';
export const DEFAULT_LOCALE = 'de-DE';

@Pipe({
    name: 'previewTemplateDate',
    standalone: true,
})
export class PreviewTemplateDatePipe implements PipeTransform {
    constructor(private readonly translateService: TranslateService) {}

    transform(value: Date | string | moment.Moment): string {
        if (!value) return '';

        const momentDate = moment(value);

        if (momentDate.isSame(moment(), 'day')) {
            return this.translateService.instant('forms.date.today');
        }

        return momentDate.locale(DEFAULT_LOCALE).format(DEFAULT_FORMAT);
    }
}
