import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ProduktDetailAbschlussWorkflowSimpleComponent } from '@modules/produkt/component/produkt-detail-abschluss-workflow-simple/produkt-detail-abschluss-workflow-simple.component';
import { ProduktDetailAcAbschlussWorkflowComponent } from '@modules/produkt/component/produkt-detail-ac-abschluss-workflow/produkt-detail-ac-abschluss-workflow.component';
import { ProduktDetailCgFeststellungBeurteilungComponent } from '@modules/produkt/component/produkt-detail-cg-feststellung-beurteilung/produkt-detail-cg-feststellung-beurteilung.component';
import { ProduktDetailNfzRaederRadComponent } from '@modules/produkt/form/produkt-detail-nfz-raeder-rad/produkt-detail-nfz-raeder-rad.component';
import { ProduktDetailUebersichtSonstigesComponent } from '@modules/produkt/form/produkt-detail-uebersicht-sonstiges/produkt-detail-uebersicht-sonstiges.component';
import { ProduktDetailUnterlagenBilderComponent } from '@modules/produkt/form/produkt-detail-unterlagen-bilder/produkt-detail-unterlagen-bilder.component';
import { ProduktDetailWerteManuelleWerteComponent } from '@modules/produkt/form/produkt-detail-werte-manuelle-werte/produkt-detail-werte-manuelle-werte.component';
import { ProduktDetailAcAbschlussComponent } from '@modules/produkt/page/produkt-detail-ac-abschluss/produkt-detail-ac-abschluss.component';
import { ProduktDetailCgAbschlussComponent } from '@modules/produkt/page/produkt-detail-cg-abschluss/produkt-detail-cg-abschluss.component';
import { ProduktDetailCgFeststellungComponent } from '@modules/produkt/page/produkt-detail-cg-feststellung/produkt-detail-cg-feststellung.component';
import { ProduktDetailKalkulationComponent } from '@modules/produkt/page/produkt-detail-kalkulation/produkt-detail-kalkulation.component';
import { ProduktDetailNfzRaederComponent } from '@modules/produkt/page/produkt-detail-nfz-raeder/produkt-detail-nfz-raeder.component';
import { ProduktDetailRestwertprognoseComponent } from '@modules/produkt/page/produkt-detail-restwertprognose/produkt-detail-restwertprognose.component';
import { ProduktDetailVtiAbschlussComponent } from '@modules/produkt/page/produkt-detail-vti-abschluss/produkt-detail-vti-abschluss.component';
import { ProduktDetailVtiAuftragComponent } from '@modules/produkt/page/produkt-detail-vti-auftrag/produkt-detail-vti-auftrag.component';
import { ProduktDetailVtiWerteComponent } from '@modules/produkt/page/produkt-detail-vti-werte/produkt-detail-vti-werte.component';
import { ProduktUebersichtAcComponent } from '@modules/produkt/page/produkt-uebersicht-ac/produkt-uebersicht-ac.component';
import { ProduktRoutingModule } from '@modules/produkt/produkt-routing.module';
import { MaterialModule } from '@shared/module/material.module';
import { SharedModule } from '@shared/shared.module';
import { ProduktDetailAbschlussAnhaengeComponent } from './component/produkt-detail-abschluss-anhaenge/produkt-detail-abschluss-anhaenge.component';
import { ProduktDetailAbschlussEntwurfComponent } from './component/produkt-detail-abschluss-entwurf/produkt-detail-abschluss-entwurf.component';
import { ProduktDetailAbschlussWorkflowComponent } from './component/produkt-detail-abschluss-workflow/produkt-detail-abschluss-workflow.component';
import { ProduktDetailCgFeststellungBaugruppenComponent } from './component/produkt-detail-cg-feststellung-baugruppen/produkt-detail-cg-feststellung-baugruppen.component';
import { ProduktDetailContentComponent } from './component/produkt-detail-content/produkt-detail-content.component';
import { ProduktDetailErrorDialogComponent } from './component/produkt-detail-error-dialog/produkt-detail-error-dialog.component';
import { ProduktDetailFahrzeugDialogComponent } from './component/produkt-detail-fahrzeug-dialog/produkt-detail-fahrzeug-dialog.component';
import { ProduktDetailFahrzeugscheinDialogComponent } from './component/produkt-detail-fahrzeugschein-dialog/produkt-detail-fahrzeugschein-dialog.component';
import { ProduktDetailFeatureDialogComponent } from './component/produkt-detail-feature-dialog/produkt-detail-feature-dialog.component';
import { ProduktDetailFeststellungenUmfangComponent } from './component/produkt-detail-feststellungen-umfang/produkt-detail-feststellungen-umfang.component';
import { ProduktDetailLackmessungGesamtComponent } from './component/produkt-detail-lackmessung-gesamt/produkt-detail-lackmessung-gesamt.component';
import { ProduktDetailLackmessungSceneComponent } from './component/produkt-detail-lackmessung-scene/produkt-detail-lackmessung-scene.component';
import { ProduktDetailNachbewertungDialogComponent } from './component/produkt-detail-nachbewertung-dialog/produkt-detail-nachbewertung-dialog.component';
import { ProduktDetailNavComponent } from './component/produkt-detail-nav/produkt-detail-nav.component';
import { ProduktDetailNotizenButtonComponent } from './component/produkt-detail-notizen-button/produkt-detail-notizen-button.component';
import { ProduktDetailNotizenDialogComponent } from './component/produkt-detail-notizen-dialog/produkt-detail-notizen-dialog.component';
import { ProduktDetailSchadenSceneComponent } from './component/produkt-detail-schaden-scene/produkt-detail-schaden-scene.component';
import { ProduktDetailToolbarComponent } from './component/produkt-detail-toolbar/produkt-detail-toolbar.component';
import { ProduktDetailVorschadenSceneComponent } from './component/produkt-detail-vorschaden-scene/produkt-detail-vorschaden-scene.component';
import { ProduktDetailVtiAbschlussWorkflowComponent } from './component/produkt-detail-vti-abschluss-workflow/produkt-detail-vti-abschluss-workflow.component';
import { ProduktDetailAdressenAdresseComponent } from './form/produkt-detail-adressen-adresse/produkt-detail-adressen-adresse.component';
import { ProduktDetailFahrzeugFormComponent } from './form/produkt-detail-fahrzeug-form/produkt-detail-fahrzeug-form.component';
import { ProduktDetailFehlteilePositionComponent } from './form/produkt-detail-fehlteile-position/produkt-detail-fehlteile-position.component';
import { ProduktDetailLackmessungMessungComponent } from './form/produkt-detail-lackmessung-messung/produkt-detail-lackmessung-messung.component';
import { ProduktDetailNotizenFormComponent } from './form/produkt-detail-notizen-form/produkt-detail-notizen-form.component';
import { ProduktDetailParentFormComponent } from './form/produkt-detail-parent-form.component.ts/produkt-detail-parent-form.component';
import { ProduktDetailRaederRadComponent } from './form/produkt-detail-raeder-rad/produkt-detail-raeder-rad.component';
import { ProduktDetailRaederRadsatzComponent } from './form/produkt-detail-raeder-radsatz/produkt-detail-raeder-radsatz.component';
import { ProduktDetailSchadenPositionComponent } from './form/produkt-detail-schaden-position/produkt-detail-schaden-position.component';
import { ProduktDetailVorschadenPositionComponent } from './form/produkt-detail-vorschaden-position/produkt-detail-vorschaden-position.component';
import { ProduktDetailWartungPositionComponent } from './form/produkt-detail-wartung-position/produkt-detail-wartung-position.component';
import { ProduktDetailAbschlussComponent } from './page/produkt-detail-abschluss/produkt-detail-abschluss.component';
import { ProduktDetailAdressenComponent } from './page/produkt-detail-adressen/produkt-detail-adressen.component';
import { ProduktDetailAuftragComponent } from './page/produkt-detail-auftrag/produkt-detail-auftrag.component';
import { ProduktDetailAusstattungComponent } from './page/produkt-detail-ausstattung/produkt-detail-ausstattung.component';
import { ProduktDetailFahrzeugComponent } from './page/produkt-detail-fahrzeug/produkt-detail-fahrzeug.component';
import { ProduktDetailFehlteileComponent } from './page/produkt-detail-fehlteile/produkt-detail-fehlteile.component';
import { ProduktDetailFeststellungenComponent } from './page/produkt-detail-feststellungen/produkt-detail-feststellungen.component';
import { ProduktDetailLackmessungComponent } from './page/produkt-detail-lackmessung/produkt-detail-lackmessung.component';
import { ProduktDetailRaederComponent } from './page/produkt-detail-raeder/produkt-detail-raeder.component';
import { ProduktDetailSchadenComponent } from './page/produkt-detail-schaden/produkt-detail-schaden.component';
import { ProduktDetailUebersichtComponent } from './page/produkt-detail-uebersicht/produkt-detail-uebersicht.component';
import { ProduktDetailUnterlagenComponent } from './page/produkt-detail-unterlagen/produkt-detail-unterlagen.component';
import { ProduktDetailVorschadenComponent } from './page/produkt-detail-vorschaden/produkt-detail-vorschaden.component';
import { ProduktDetailWartungComponent } from './page/produkt-detail-wartung/produkt-detail-wartung.component';
import { ProduktDetailWerteComponent } from './page/produkt-detail-werte/produkt-detail-werte.component';
import { ProduktDetailComponent } from './page/produkt-detail/produkt-detail.component';
import { ProduktErstellenComponent } from './page/produkt-erstellen/produkt-erstellen.component';
import { ProduktUebersichtGtueEmComponent } from './page/produkt-uebersicht-gtue-em/produkt-uebersicht-gtue-em.component';
import { ProduktUebersichtGtueModulComponent } from './page/produkt-uebersicht-gtue-modul/produkt-uebersicht-gtue-modul.component';
import { ProduktUebersichtComponent } from './page/produkt-uebersicht/produkt-uebersicht.component';
import { AbbreviateTextPipe } from './pipe/abbreviate-text.pipe';
import { ProduktDetailAdressenOrderPipe } from './pipe/produkt-detail-adressen-order.pipe';
import { VtiAuftragstypPipe } from './pipe/vti-auftragstyp.pipe';
import { ProduktDetailKalkulationDialogComponent } from './component/produkt-detail-kalkulation-dialog/produkt-detail-kalkulation-dialog.component';

@NgModule({
    declarations: [
        ProduktDetailComponent,
        ProduktUebersichtComponent,
        ProduktUebersichtAcComponent,
        ProduktDetailToolbarComponent,
        ProduktDetailNavComponent,
        ProduktDetailAuftragComponent,
        ProduktDetailVtiAuftragComponent,
        ProduktDetailFahrzeugComponent,
        ProduktDetailWartungComponent,
        ProduktDetailWerteComponent,
        ProduktDetailContentComponent,
        ProduktDetailUebersichtComponent,
        ProduktDetailUnterlagenComponent,
        ProduktDetailUnterlagenBilderComponent,
        ProduktDetailRaederComponent,
        ProduktDetailRaederRadsatzComponent,
        ProduktDetailRaederRadComponent,
        ProduktDetailNfzRaederComponent,
        ProduktDetailNfzRaederRadComponent,
        ProduktDetailVorschadenComponent,
        ProduktDetailVorschadenSceneComponent,
        ProduktDetailVorschadenPositionComponent,
        ProduktDetailFehlteileComponent,
        ProduktDetailFehlteilePositionComponent,
        ProduktDetailAbschlussComponent,
        ProduktDetailAdressenComponent,
        ProduktDetailAdressenAdresseComponent,
        ProduktDetailAdressenOrderPipe,
        ProduktDetailFahrzeugFormComponent,
        ProduktDetailFeatureDialogComponent,
        ProduktDetailFahrzeugDialogComponent,
        ProduktDetailAusstattungComponent,
        ProduktDetailWartungPositionComponent,
        ProduktDetailNotizenFormComponent,
        ProduktDetailNotizenDialogComponent,
        ProduktDetailNotizenButtonComponent,
        ProduktDetailSchadenComponent,
        ProduktDetailSchadenSceneComponent,
        ProduktDetailSchadenPositionComponent,
        ProduktDetailFahrzeugscheinDialogComponent,
        ProduktDetailAbschlussWorkflowComponent,
        ProduktDetailAbschlussEntwurfComponent,
        ProduktDetailLackmessungComponent,
        ProduktDetailLackmessungMessungComponent,
        ProduktDetailLackmessungSceneComponent,
        ProduktDetailAbschlussAnhaengeComponent,
        ProduktDetailUebersichtSonstigesComponent,
        ProduktDetailCgFeststellungComponent,
        ProduktDetailCgFeststellungBaugruppenComponent,
        ProduktDetailCgFeststellungBeurteilungComponent,
        ProduktDetailFeststellungenComponent,
        ProduktUebersichtGtueModulComponent,
        ProduktUebersichtGtueEmComponent,
        ProduktDetailFeststellungenUmfangComponent,
        ProduktDetailWerteManuelleWerteComponent,
        ProduktUebersichtGtueModulComponent,
        ProduktUebersichtGtueEmComponent,
        ProduktDetailLackmessungGesamtComponent,
        ProduktDetailKalkulationComponent,
        ProduktDetailVtiWerteComponent,
        ProduktDetailRestwertprognoseComponent,
        ProduktDetailNachbewertungDialogComponent,
        ProduktDetailVtiAbschlussComponent,
        ProduktDetailVtiAbschlussWorkflowComponent,
        ProduktDetailParentFormComponent,
        VtiAuftragstypPipe,
        ProduktDetailCgAbschlussComponent,
        ProduktDetailAcAbschlussComponent,
        ProduktDetailAcAbschlussWorkflowComponent,
        ProduktDetailAbschlussWorkflowSimpleComponent,
        ProduktDetailErrorDialogComponent,
        AbbreviateTextPipe,
    ],
    imports: [
    ProduktRoutingModule,
    SharedModule,
    DragDropModule,
    MaterialModule,
    MatSlideToggleModule,
    ProduktErstellenComponent,
    ProduktDetailKalkulationDialogComponent
],
})
export class ProduktModule {}
