import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AdresseAnrede } from '@data/domain/schema/enum';
import { PRODUKT_ADRESSEN_ARTEN } from '@modules/produkt/config/produkt-adressen-arten.config';
import { FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { EnumValues } from '@shared/helper/values';
import { debounceTime, distinctUntilChanged, Subscription } from 'rxjs';

@Component({
    selector: 'app-produkt-detail-adressen-adresse',
    templateUrl: './produkt-detail-adressen-adresse.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailAdressenAdresseComponent implements OnInit, OnDestroy {
    trackByField = TrackBy.trackByField;

    @Input()
    form: UntypedFormGroup;

    @Input()
    name: string;

    @Input()
    fields: FeatureFields;

    saveAdressen = output<UntypedFormGroup>();

    anrede = new EnumValues(AdresseAnrede, 0);
    arten = PRODUKT_ADRESSEN_ARTEN;

    private subscriptions: Subscription[] = [];

    isCustomAnrede(): boolean {
        const anredeFieldName = PRODUKT_CONFIG_FEATURES.Adressen.fields.Adressen.fields.Anrede.name;
        return this.form?.get(anredeFieldName)?.value === AdresseAnrede.Freitext;
    }

    ngOnInit(): void {
        this.createSubscriptions();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    private createSubscriptions(): void {
        const adressArtField = 'arten';
        this.createFieldSubscription(adressArtField, 0);
    }

    private createFieldSubscription(fieldName: string, debounce = 300): void {
        const control = this.form?.get(fieldName);
        if (!control) {
            console.warn(`Unable to create subscription for form field with name: ${fieldName}.`);
            return;
        }

        const sub = control.valueChanges.pipe(distinctUntilChanged(), debounceTime(debounce)).subscribe(() => {
            if (control.dirty) {
                this.saveAdressen.emit({ ...this.form.value });
            }
        });

        this.subscriptions.push(sub);
    }
}
