import { DatePipe } from '@angular/common';
import { Component, input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormField, PreviewTemplate } from '@shared/models/form-field.model';
import { PreviewTemplateDatePipe } from '@shared/standalone/form-array/item-preview-template/preview-date.pipe';

@Component({
    selector: 'app-item-preview-template',
    standalone: true,
    imports: [
        DatePipe,
        TranslateModule,
        PreviewTemplateDatePipe,
    ],
    templateUrl: './item-preview-template.component.html',
    styleUrl: './item-preview-template.component.scss',
})
export class ItemPreviewTemplateComponent {
    readonly previewTemplates = input.required<PreviewTemplate[]>();
    readonly formGroup = input.required<FormGroup>();
    readonly newItemLabel = input<string>();

    protected readonly FormField = FormField;
}
