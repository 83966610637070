import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
    ArrayField,
    ButtonGroupTabsField,
    ConfigField,
    ConfigTab,
    FormConfig,
    FormField,
    FormFieldArrays,
} from '@shared/models/form-field.model';
import { FormPaintMeasurementService } from '@shared/standalone/form-paint-measurement/form-paint-measurement.service';
import { FormWheelsService } from '@shared/standalone/form-wheels/form-wheels.service';

// TODO Work in Progress
@Injectable({
    providedIn: 'root',
})
export class ProduktFormService {
    constructor() {}

    static getFormGroupByConfiguration<T>(configuration: FormConfig, data?: T): FormGroup {
        const formConfig: FormConfig = configuration;
        const formGroup = new FormGroup({}, { updateOn: 'blur' });

        const filteredData = ProduktFormService.removeTypename(data);

        formConfig.tabs.forEach((tab) => {
            const tabGroup = new FormGroup({});
            formGroup.addControl(tab.name, tabGroup);

            const tabData = filteredData?.[tab.name];

            tab.sections.forEach((section) => {
                const sectionData = section.name ? tabData?.[section.name] : tabData;

                section.fields.forEach((field) => {
                    if (!field.name) return;

                    if (field.type === FormField.ButtonGroupTabs) {
                        this.setupButtonGroupTabsFields(field as ButtonGroupTabsField, tabGroup, tabData);
                        return;
                    }

                    const fieldData = sectionData?.[field.name];

                    if (field.type === FormField.Wheels) {
                        const wheelsFormGroup = FormWheelsService.getWheelsFormGroup(fieldData);
                        tabGroup.addControl(field.name, wheelsFormGroup);
                        return;
                    }

                    if (field.type === FormField.PaintMeasurement) {
                        const control = FormPaintMeasurementService.getPaintMeasurementFormGroup(fieldData);
                        tabGroup.addControl(field.name, control);
                        return;
                    }

                    const control = ProduktFormService.getControlByConfigField(field, fieldData);
                    tabGroup.addControl(field.name, control);
                    if (!control.value && field.defaultValue) {
                        control.setValue(field.defaultValue());
                        control.markAsDirty();
                    }
                });
            });
        });

        return formGroup;
    }

    private static setupButtonGroupTabsFields(
        field: ButtonGroupTabsField,
        tabGroup: FormGroup,
        tabData: unknown,
    ): void {
        field.tabs.forEach((subtab) => {
            subtab.sections.forEach((subsection) => {
                subsection.fields.forEach((subfield) => {
                    if (!subfield.name) return;

                    const subfieldData = tabData?.[subfield.name];
                    const control = ProduktFormService.getControlByConfigField(subfield, subfieldData);

                    tabGroup.addControl(subfield.name, control);
                    if (!control.value && subfield.defaultValue) {
                        control.setValue(subfield.defaultValue());
                        control.markAsDirty();
                    }
                });
            });
        });
    }

    static getControlByConfigField(field: ConfigField, fieldData?: unknown): FormControl | FormArray {
        const validators = field.validators || [];
        if (field.required) {
            validators.push(Validators.required);
        }

        if (!FormFieldArrays.includes(field.type)) {
            return new FormControl(fieldData || undefined, { updateOn: field.updateOn || 'blur', validators });
        } else {
            const inspectionField = field as ArrayField;
            const fieldArray = fieldData as {}[];
            const formArray = new FormArray<FormGroup>([], validators);

            fieldArray?.forEach((subData) => {
                const formGroup = new FormGroup({});
                inspectionField.fields.forEach((arrayField) => {
                    if (!arrayField.name) {
                        return;
                    }
                    const control = this.getControlByConfigField(arrayField, subData?.[arrayField.name]);
                    formGroup.addControl(arrayField.name, control);
                });
                formArray.push(formGroup);
            });

            return formArray;
        }
    }

    static getMutationInputByFormGroup<T extends {}>(configuration: FormConfig, formGroup: FormGroup): T | undefined {
        // Keeping input and its fields undefined to only patch on real changes
        let input: T | undefined;

        configuration.tabs.forEach((tab) => {
            if (tab.skipInput) {
                return;
            }
            const tabGroup = formGroup.get(tab.name) as FormGroup;

            tab.sections.forEach((section) => {
                section.fields.forEach((field) => {
                    if (!field.name) {
                        return;
                    }

                    // TODO mutation scheint nicht zu patchen, sondern zu überschreiben,
                    // TODO also {} => alle Werte auf null, daher erst mal auskommentiert
                    // const control = tabGroup.get(field.name);
                    // if (!control?.dirty) {
                    //     return;
                    // }

                    if (!input) {
                        input = {} as T;
                    }
                    if (!input[tab.name]) {
                        input[tab.name] = {};
                    }
                    if (section.name) {
                        if (!input[tab.name]?.[section.name]) {
                            input[tab.name][section.name] = {};
                        }

                        input[tab.name][section.name][field.name] = tabGroup.get(field.name)?.value;
                        return;
                    }

                    if (field.type === FormField.ButtonGroupTabs) {
                        this.setupButtonGroupTabsMutationInput(field as ButtonGroupTabsField, tabGroup, tab, input);
                        return;
                    }

                    input[tab.name][field.name] = tabGroup.get(field.name)?.value;
                });
            });
        });

        return ProduktFormService.removeTypename(input);
    }

    /**
     * Recursively removes GraphQL '__typename' fields from an object
     * @param obj The object to clean
     * @returns A new object without '__typename' fields
     */
    private static removeTypename<T>(obj: T): T {
        if (Array.isArray(obj)) {
            return obj.map((item) => this.removeTypename(item)) as T;
        }

        if (obj !== null && typeof obj === 'object') {
            const newObj = {} as T;

            Object.entries(obj).forEach(([key, value]) => {
                if (key !== '__typename') {
                    newObj[key] = this.removeTypename(value);
                }
            });

            return newObj;
        }

        return obj;
    }

    private static setupButtonGroupTabsMutationInput<T>(
        field: ButtonGroupTabsField,
        tabGroup: FormGroup,
        tab: ConfigTab,
        input: T,
    ): void {
        field.tabs.forEach((subtab) => {
            subtab.sections.forEach((subsection) => {
                subsection.fields.forEach((subfield) => {
                    if (!subfield.name) {
                        return;
                    }
                    if (!input[tab.name][subfield.name]) {
                        input[tab.name][subfield.name] = {};
                    }
                    input[tab.name][subfield.name] = tabGroup.get(subfield.name)?.value;
                });
            });
        });
    }
}
