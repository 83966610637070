<app-expansion-panel-single [expanded]="viewport() !== viewportMobile">
    <app-expansion-header>
        <div class="filter-container" [class.mobile]="viewport() === viewportMobile">
            <h4 class="filter-header-text">{{ 'produktUebersicht.filter' | translate }}</h4>
            <app-slide-toggle class="filter" [form]="form" [name]="'status'" displayName="produktUebersicht.filter.onlyEditable" />
        </div>
    </app-expansion-header>

    <div class="px-4" [class.mobile]="viewport() === viewportMobile">
        <div class="grid gap-x-4 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-6">
            <app-select
                class="col-span-1"
                [form]="form"
                [name]="'art'"
                [displayName]="'produktUebersicht.art'"
                [values]="produktArtEnumValues"
                [valuePrefix]="name + '.'"
                [hasEmptyField]="true"
            />
            <app-input
                class="sm:col-span-1 md:col-span-2 lg:col-span-2"
                [form]="form"
                [name]="'searchText'"
                [displayName]="'produktUebersicht.searchText'"
                type="text"
            />
            <app-slide-toggle
                class="col-span-1"
                [form]="form"
                [name]="'dateToggle'"
                displayName="produktUebersicht.dateToggle"
            />
            <app-date
                class="col-span-1"
                [form]="form"
                [name]="'von'"
                [displayName]="'produktUebersicht.von'"
                [min]="minDate"
                [max]="maxDate"
            />
            <app-date
                class="col-span-1"
                [form]="form"
                [name]="'bis'"
                [displayName]="'produktUebersicht.bis'"
                [min]="minDate"
                [max]="maxDate"
            />
        </div>
        <div class="flex gap-x-4">
            <app-button class="search-button" [type]="ButtonType.PRIMARY" (action)="onClickProduktSearch()">
                <div [class.invisible]="loading()">{{ 'produktUebersicht.suche' | translate }}</div>
                @if (loading()) {
                    <app-progress-spinner class="spinner" [diameter]="25" />
                }
            </app-button>
            <app-button class="button" [type]="ButtonType.SECONDARY" (action)="onCsvDownload($event)">
                <div [class.invisible]="loadingCsv()">{{ 'produktuebersicht.csv' | translate }}</div>
                @if (loadingCsv()) {
                    <app-progress-spinner class="spinner" [diameter]="25" />
                }
            </app-button>
            <app-button class="reset-button" [type]="ButtonType.TERTIARY" (action)="onResetFilter()">
                {{ 'produktuebersicht.reset' | translate }}
            </app-button>
        </div>
    </div>
</app-expansion-panel-single>

<app-table
    #table
    [data]="produkte"
    [filter$]="filter$"
    [filterPredicate]="filterPredicate"
    [pageSizeOptions]="[25, 50, 100]"
    [customFilterVisible]="false"
    (action)="onAction($event)"
    active="erstelltAm"
    direction="desc"
>
    <app-table-column [displayName]="name + '.art'" [sort]="true" name="art">
        <ng-template let-element="element">
            @switch (element.art) {
                @case (produktArt.Bewertung) {
                    <app-mat-icon [svgIcon]="'bewertung'" color="primary" />
                }
                @case (produktArt.Ruecknahmebewertung) {
                    <app-mat-icon [svgIcon]="'ruecknahmebewertung'" color="primary" />
                }
                @case (produktArt.Zustandsbericht) {
                    <app-mat-icon [svgIcon]="'zustandsbericht'" color="primary" />
                }
                @case (produktArt.VtiTooling) {
                    <h4>VTI</h4>
                }
                @case (produktArt.CarGarantie) {
                    <app-mat-icon [svgIcon]="'car-garantie'" color="primary" />
                }
                @case (produktArt.AlphaController) {
                    <app-mat-icon [svgIcon]="'alphacontroller'" color="primary" />
                }
                @case (produktArt.Oldtimer) {
                    <app-mat-icon [svgIcon]="produktIcon.Oldtimer" color="primary" />
                }
            }
        </ng-template>
    </app-table-column>
    <app-table-column [displayName]="name + '.nummer'" [sort]="true" name="auftrag.nummer">
        <ng-template let-element="element">{{ element.auftrag?.nummer }}</ng-template>
    </app-table-column>
    <app-table-column [displayName]="name + '.identnummer'" [sort]="true" name="fahrzeug.identnummer">
        <ng-template let-element="element">
            {{ element.fahrzeug?.identnummer }}
            <br />
            {{ element.fahrzeug?.kennzeichen }}
        </ng-template>
    </app-table-column>
    <app-table-column [displayName]="name + '.erstelltAm'" [sort]="true" name="erstelltAm" start="desc">
        <ng-template let-element="element">{{ element.erstelltAm | date }}</ng-template>
    </app-table-column>
    <app-table-column [displayName]="name + '.status'" [sort]="true" name="status">
        <ng-template let-element="element">
            {{ produktStatus.values[element.status] }}
            <br />
            {{ element.abgeschlossenAm ? '(' + (element.abgeschlossenAm | date) + ')' : '' }}
        </ng-template>
    </app-table-column>
    <app-table-column [displayName]="name + '.download'" [sort]="false" name="download">
        <ng-template let-element="element">
            <div class="button-container">
                <app-button
                    [matBadge]="element.anzahlBilder"
                    [matBadgeHidden]="element.anzahlBilder <= 0"
                    [class.disabled]="element.anzahlBilder <= 0"
                    (action)="onBilderDownload($event, element.id, element.anzahlBilder <= 0)"
                >
                    <ng-container>
                        <app-mat-icon icon="photo_gallery" color="primary" />
                    </ng-container>
                </app-button>
                <app-button
                    [class.disabled]="element.status !== produktStatusEnum.Beendet"
                    (action)="onDownloadClick($event, element.id, element.status !== produktStatusEnum.Beendet)"
                >
                    <ng-container>
                        <app-mat-icon icon="picture_as_pdf" color="primary" />
                    </ng-container>
                </app-button>
            </div>
        </ng-template>
    </app-table-column>
    <app-table-column [displayName]="name + '.duplicate'" [sort]="false" name="duplicate">
        <ng-template let-element="element">
            @if (nachbewertungEnabled(element.art)) {
                <app-button (action)="onClickDuplicate($event, element)">
                    <ng-container>
                        <app-mat-icon icon="control_point_duplicate" color="primary" />
                    </ng-container>
                </app-button>
            }
        </ng-template>
    </app-table-column>
</app-table>

<!-- Confirm dialog -->
<ng-template #dialogConfirmDuplicate let-data="data">
    <mat-label class="dialog-text">{{ 'nachbewertung.text' | translate }}</mat-label>
    <app-produkt-detail-nachbewertung-dialog #nachbewertungArtSelection [defaultSelection]="data.produkt.art" />
</ng-template>
