<h4 *ngIf="!this.hasFahrzeugExternalServiceReference">{{ name + '.identabfrage' | translate }}</h4>
<div class="main-content">
    <app-card class="daten">
        <app-button
            class="open-new-tab"
            [loading]="loading$ | async"
            [type]="ButtonType.SECONDARY"
            [color]="'primary'"
            [disabled]="!hasFahrzeugExternalServiceReference"
            (action)="onOpenKalkulationInTab()"
        >
            {{ name + '.tab' | translate }}
            <app-mat-icon icon="open_in_new" />
        </app-button>

        <ng-container *ngTemplateOutlet="(this.loadingGetKalkulationData$ | async) ? spinner : kalkulationTemplate" />
    </app-card>
    <div id="iframeContainer"></div>
    <app-progress-spinner class="loading-spinner" [class.loading-finished]="loading$ | async" [diameter]="150" />
</div>

<ng-template #kalkulationTemplate>
    <ng-container *ngFor="let field of kalkulationFields; trackBy: trackByField">
        <ng-container [ngSwitch]="field.name">
            <ng-container *ngSwitchCase="'dossierId'">
                <app-input
                    [disabled]="true"
                    [form]="form"
                    [name]="field.name"
                    [displayName]="name + '.' + field.name"
                    type="text"
                />
            </ng-container>
            <ng-container *ngSwitchCase="'bezugsdatum'">
                <app-date
                    [form]="form"
                    [name]="field.name"
                    [displayName]="name + '.' + field.name"
                    [readonly]="true"
                    [hasDatepicker]="false"
                    [disabled]="true"
                />
            </ng-container>
            <ng-container *ngSwitchCase="'reparaturkostenNetto'">
                <ng-container *ngTemplateOutlet="textCopy; context: { field: field }" />
            </ng-container>
            <ng-container *ngSwitchCase="'reparaturkostenBrutto'">
                <ng-container *ngTemplateOutlet="textCopy; context: { field: field }" />
            </ng-container>
        </ng-container>
    </ng-container>

    <div class="download-kalkulation-container">
        <p>{{ name + '.' + 'dokument' | translate }}</p>
        <app-button
            class="download-button"
            [disabled]="!this.form?.get('dokument')?.value"
            (action)="downloadKalkulation()"
        >
            <app-mat-icon icon="picture_as_pdf" color="primary" />
        </app-button>
    </div>
</ng-template>

<ng-template #textCopy let-field="field">
    <div class="text-copy-container">
        <app-input
            #userinput
            [disabled]="true"
            [form]="form"
            [name]="field.name"
            [displayName]="name + '.' + field.name"
            type="text"
            inputmode="numeric"
        >
            <ng-container [class.readonly]="true" appSuffix>&nbsp;€</ng-container>
        </app-input>
        <app-button class="copy-button" (action)="copy(userinput.input.nativeElement.id)">
            <app-mat-icon icon="file_copy" />
        </app-button>
    </div>
</ng-template>

<ng-template #loading>
    <app-progress-spinner class="loading-spinner" [diameter]="30" />
</ng-template>

<ng-template #spinner>
    <app-progress-spinner />
</ng-template>
