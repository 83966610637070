import { Injectable } from '@angular/core';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { AusstattungGruppe } from '@data/api-gateway';
import { GraphQLResponse, saveAusstattung, SaveAusstattungData } from '../../graphql/mutations';
import { Ausstattung, AusstattungInput, Produkt } from '../../schema/type';
import { ProduktService } from '../produkt.service';
import { ProduktFeatureService } from './produkt-feature.service';

@Injectable({
    providedIn: 'root',
})
export class ProduktAusstattungService extends ProduktFeatureService<Ausstattung, AusstattungInput> {
    constructor(awsAppSyncClientProvider: AwsAppSyncClientProvider, produktService: ProduktService) {
        super(awsAppSyncClientProvider, produktService);
    }

    getErfassteAusstattung(produkt: Produkt): AusstattungGruppe {
        const erfassteAusstattung = {
            name: 'Erfasste Ausstattung',
            sonderausstattung: null,
            teile: [],
        };

        if (produkt.ausstattung && produkt.ausstattung.gruppen) {
            const foundErfassteAusstattung = produkt.ausstattung.gruppen.find(
                (value) => value.name === 'Erfasste Ausstattung',
            );

            if (foundErfassteAusstattung) {
                foundErfassteAusstattung.teile.forEach((teil) => {
                    erfassteAusstattung.teile.push({
                        name: teil.name,
                        vorhanden: teil.vorhanden,
                        externalServiceId: teil.externalServiceId,
                    });
                });
            }
        }
        return erfassteAusstattung;
    }

    protected mapSaveInput(produktId: string, feature: Ausstattung): AusstattungInput {
        const input: AusstattungInput = {
            id: produktId,
            individualaufbauten: feature.individualaufbauten,
            gruppen: (feature.gruppen || []).map((gruppe, gruppeIndex) => ({
                ...gruppe,
                id: `${produktId}:${gruppeIndex}`,
                teile: (gruppe.teile || []).map((teil, teilIndex) => ({
                    ...teil,
                    id: `${produktId}:${gruppeIndex}:${teilIndex}`,
                })),
            })),
        };
        return input;
    }

    protected getSaveMutation(): any {
        return saveAusstattung;
    }

    protected getSaveVariables(input: AusstattungInput): any {
        return {
            ausstattung: input,
        };
    }

    protected getSaveOptimisticResponse(input: AusstattungInput): any {
        return {
            saveAusstattung: {
                ...input,
                __typename: 'Ausstattung',
                individualaufbauten: (input.individualaufbauten || []).map((individualaufbau) => ({
                    ...individualaufbau,
                    __typename: 'Textbaustein',
                })),
                gruppen: (input.gruppen || []).map((gruppe) => ({
                    ...gruppe,
                    __typename: 'AusstattungGruppe',
                    teile: (gruppe.teile || []).map((teil) => ({
                        ...teil,
                        __typename: 'AusstattungTeil',
                    })),
                })),
            },
        };
    }
    protected getSaveResponse(response: GraphQLResponse<SaveAusstattungData>): Ausstattung {
        return response.data.saveAusstattung;
    }

    protected update(produkt: Produkt, feature: Ausstattung): void {
        produkt.ausstattung.id = feature.id;
        produkt.ausstattung.gruppen = feature.gruppen;
    }
}
