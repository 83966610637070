import { ProduktArt } from '@data/domain/schema/enum';
import { ConfigField, FormField, InputType } from '@shared/models/form-field.model';

export const paintMeasurementSingleMeasure: ConfigField[] = [
    {
        name: 'dicke',
        label: 'lackmessung.dicke',
        required: true,
        type: FormField.Input,
        inputType: InputType.Number,
        suffix: 'µm',
    },
    {
        name: 'weitereDicken',
        label: 'lackmessung.ergebnis',
        type: FormField.DenseArray,
        settings: {
            emptyMessage: 'oldtimer.paintMeasurement.additionalThicknesses.emptyMessage',
            newItemLabel: 'oldtimer.paintMeasurement.additionalThicknesses.newItemLabel',
        },
        fields: [
            {
                name: 'value',
                type: FormField.Input,
                inputType: InputType.Number,
                label: 'lackmessung.dicke',
                placeholder: 'Weitere Dicke',
            },
        ],
    },
    {
        name: 'beschreibung',
        label: 'lackmessung.beschreibung',
        type: FormField.Input,
    },
    {
        name: 'bilder',
        label: 'lackmessung.bilder',
        type: FormField.ImageUpload,
    },
    {
        name: 'bemerkungen',
        label: 'lackmessung.bemerkungen',
        type: FormField.TextModule,
        settings: {
            feature: 'lackmessung',
            feld: 'messungen',
            produktArt: ProduktArt.Oldtimer,
        },
    },
];

export const paintMeasurementOverall: ConfigField[] = [
    {
        name: 'gesamtKarosserieVon',
        label: 'lackmessung.gesamtKarosserieVon',
        type: FormField.Input,
        suffix: 'µm',
    },
    {
        name: 'gesamtKarosserieBis',
        label: 'lackmessung.gesamtKarosserieBis',
        type: FormField.Input,
        suffix: 'µm',
    },
    {
        name: 'beschreibungen',
        label: 'lackmessung.beschreibungen',
        type: FormField.TextModule,
        style: 'col-span-2',
        settings: {
            feature: 'lackmessung',
            feld: 'beschreibungen',
            produktArt: ProduktArt.Oldtimer,
        },
    },
];
