import { NgTemplateOutlet } from '@angular/common';
import { Component, DestroyRef, output, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
    EinstellungenService,
    KalkulatorischeFaktoren,
    SystemArtEnum,
} from '@data/api-gateway/service/einstellungen.service';
import { TranslateModule } from '@ngx-translate/core';
import { FormField } from '@shared/models/form-field.model';
import { FormFieldSelectComponent } from '@shared/standalone/form-fields/form-field-select/form-field-select.component';
import { finalize, map, take } from 'rxjs';

export interface KalkulationDialogResult {
    openKalkulation: boolean;
    settings: KalkulatorischeFaktoren | null;
}

enum KalkulationFields {
    KalkulationsFaktoren = 'kalkulationsFaktoren',
    SystemArtSelect = 'systemArtSelect',
}

@Component({
    selector: 'app-produkt-detail-kalkulation-dialog',
    standalone: true,
    imports: [
        MatFormFieldModule,
        MatDialogModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        FormFieldSelectComponent,
        TranslateModule,
        NgTemplateOutlet,
    ],
    templateUrl: './produkt-detail-kalkulation-dialog.component.html',
    styleUrl: './produkt-detail-kalkulation-dialog.component.scss',
})
export class ProduktDetailKalkulationDialogComponent {
    openKalkulationDat = output<KalkulationDialogResult>();

    calculationSettings = signal<KalkulatorischeFaktoren[] | null>(null);
    loading = signal<boolean>(false);

    configField = {
        name: KalkulationFields.KalkulationsFaktoren,
        label: 'kalkulation.dialog.kalkulationsFaktoren',
        type: FormField.Select,
        options: [],
    };

    systemArtSelectField = {
        name: KalkulationFields.SystemArtSelect,
        label: 'kalkulation.dialog.systemArt',
        type: FormField.Select,
        options: [
            { value: SystemArtEnum.AZT, label: 'kalkulation.dialog.azt' },
            { value: SystemArtEnum.Hersteller, label: 'kalkulation.dialog.hersteller' },
        ],
    };

    form = signal<FormGroup>(
        new FormGroup({
            kalkulationsFaktoren: new FormControl(null),
            systemArtSelect: new FormControl(null),
        }),
    );

    selectedSettings = signal<KalkulatorischeFaktoren | null>(null);

    SystemArtEnum = SystemArtEnum;

    constructor(
        private readonly einstellungenService: EinstellungenService,
        private readonly destroyRef: DestroyRef,
    ) {
        this.loading.set(true);
        this.einstellungenService
            .getBuero()
            .pipe(
                take(1),
                map((buero) => {
                    this.calculationSettings.set(buero.kalkulatorischeFaktoren);
                    this.configField.options = buero.kalkulatorischeFaktoren.map((faktor) => ({
                        value: faktor.id,
                        label: faktor.name,
                    }));
                }),
                finalize(() => this.loading.set(false)),
            )
            .subscribe();

        this.form()
            .get(KalkulationFields.KalkulationsFaktoren)
            .valueChanges.pipe(
                takeUntilDestroyed(this.destroyRef),
                map((id) => {
                    const faktoren = this.calculationSettings()?.find((setting) => setting.id === id);

                    this.form().get(KalkulationFields.SystemArtSelect).setValue(id ? faktoren?.lackfaktoren?.systemArt : null);

                    this.selectedSettings.set(faktoren);
                }),
            )
            .subscribe();

        this.form()
            .get(KalkulationFields.SystemArtSelect)
            .valueChanges.pipe(
                takeUntilDestroyed(this.destroyRef),
                map((systemArt) => {
                    const faktoren = this.selectedSettings();
                    if (faktoren) {
                        faktoren.lackfaktoren.systemArt = systemArt;
                        this.selectedSettings.set(faktoren);
                    }
                }),
            )
            .subscribe();
    }

    getKalkulationDisplayValue(value?: number, suffix?: string): string {
        if (!value) {
            return 'k.A.';
        }
        return `${value} ${suffix}`;
    }

    onCancel(): void {
        const result: KalkulationDialogResult = {
            openKalkulation: false,
            settings: null,
        };
        this.openKalkulationDat.emit(result);
    }

    onSubmit(): void {
        const result: KalkulationDialogResult = {
            openKalkulation: true,
            settings: this.selectedSettings(),
        };
        this.openKalkulationDat.emit(result);
    }
}
