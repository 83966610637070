import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { guid } from '@app/function/guid';
import {
    BueroEinstellungen,
    EinstellungenService,
    KalkulatorischeFaktoren,
    SystemArtEnum,
} from '@data/api-gateway/service/einstellungen.service';
import { TranslateModule } from '@ngx-translate/core';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { FormArrayComponent } from '@shared/standalone/form-array/form-array.component';
import { catchError, finalize, map, take } from 'rxjs';
import { einstellungenKalkulationConfiguration } from './einstellungen-kalkulation.configuration';

interface CalculationFormValue {
    name: string;
    mechanik: number;
    karosserie: number;
    elektrik: number;
    dellenDruecken: number;
    systemArt: SystemArtEnum;
    aztLacklohn: number;
    aztLackmaterial: number;
    herstellerLacklohn: number;
    herstellerLackmaterial: number;
}

@Component({
    selector: 'app-einstellungen-kalkulation',
    standalone: true,
    imports: [
        MatCardModule,
        FormArrayComponent,
        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,
        TranslateModule,
    ],
    templateUrl: './einstellungen-kalkulation.component.html',
    styleUrl: './einstellungen-kalkulation.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinstellungenKalkulationComponent {
    config = einstellungenKalkulationConfiguration;
    form = signal<FormArray>(new FormArray([]));
    loading = signal<boolean>(false);
    bueroSettings = signal<BueroEinstellungen>(null);

    constructor(
        private readonly einstellungenService: EinstellungenService,
        private readonly fb: FormBuilder,
        private readonly snackBarService: SnackBarService,
    ) {
        this.loadBueroSettings();
    }

    saveDisabled(): boolean {
        return !this.form().valid || !this.bueroSettings;
    }

    reloadSettings(): void {
        this.loadBueroSettings();
    }

    onSave(_event): void {
        const formValue = this.form().getRawValue();
        const calculationSettings = this.mapToCalculationSettingsDto(formValue);

        const newSettings = this.bueroSettings();
        newSettings.kalkulatorischeFaktoren = calculationSettings;

        this.bueroSettings.set(newSettings);

        this.saveBueroSettings();
    }

    private loadBueroSettings(): void {
        this.loading.set(true);

        this.einstellungenService
            .getBuero()
            .pipe(
                take(1),
                map((bueroSettings) => {
                    if (!bueroSettings) {
                        this.bueroSettings.set(null);
                        this.loading.set(false);
                        return null;
                    }

                    const formValues = this.mapFromCalculationSettingsDto(bueroSettings.kalkulatorischeFaktoren);
                    if (!formValues) {
                        return null;
                    }

                    const form = this.convertFormValuesToFormArray(formValues);

                    this.bueroSettings.set(bueroSettings);
                    this.form.set(form);
                    this.loading.set(false);
                }),
                catchError((error) => {
                    console.error('Error while loading buero settings', error);
                    return null;
                }),
                finalize(() => this.loading.set(false)),
            )
            .subscribe();
    }

    private saveBueroSettings(): void {
        if (!this.bueroSettings) return;

        this.einstellungenService
            .putBuero(this.bueroSettings())
            .pipe(
                take(1),
                map((result) => {
                    if (result) {
                        this.snackBarService.success('einstellungen.kalkulation.calculationSettings.save.success');
                        return;
                    }
                    this.snackBarService.error('einstellungen.kalkulation.calculationSettings.save.error');
                }),
            )
            .subscribe();
    }

    private mapFromCalculationSettingsDto(settings: KalkulatorischeFaktoren[]): CalculationFormValue[] {
        if (!settings || settings.length === 0) {
            return [];
        }

        const result: CalculationFormValue[] = [];
        for (const setting of settings) {
            const value: CalculationFormValue = {
                name: setting.name,
                mechanik: setting.arbeitslohnfaktoren.mechanik,
                karosserie: setting.arbeitslohnfaktoren.karosserie,
                elektrik: setting.arbeitslohnfaktoren.elektrik,
                dellenDruecken: setting.arbeitslohnfaktoren.dellenDruecken,
                systemArt: setting.lackfaktoren.systemArt,
                aztLacklohn: setting.lackfaktoren.azt.lacklohn,
                aztLackmaterial: setting.lackfaktoren.azt.lackmaterial,
                herstellerLacklohn: setting.lackfaktoren.hersteller.lacklohn,
                herstellerLackmaterial: setting.lackfaktoren.hersteller.lackmaterial,
            };
            result.push(value);
        }

        return result;
    }

    private mapToCalculationSettingsDto(formValue: CalculationFormValue[]): KalkulatorischeFaktoren[] {
        const result: KalkulatorischeFaktoren[] = [];
        for (const value of formValue) {
            const settings: KalkulatorischeFaktoren = {
                id: guid(),
                name: value.name,
                arbeitslohnfaktoren: {
                    mechanik: value.mechanik,
                    karosserie: value.karosserie,
                    elektrik: value.elektrik,
                    dellenDruecken: value.dellenDruecken,
                },
                lackfaktoren: {
                    systemArt: value.systemArt,
                    azt: {
                        lacklohn: value.aztLacklohn,
                        lackmaterial: value.aztLackmaterial,
                    },
                    hersteller: {
                        lacklohn: value.herstellerLacklohn,
                        lackmaterial: value.herstellerLackmaterial,
                    },
                },
            };
            result.push(settings);
        }

        return result;
    }

    private convertFormValuesToFormArray(formValues: CalculationFormValue[]): FormArray {
        const formArray: FormArray<FormGroup> = new FormArray([]);
        formValues.forEach((value) => {
            const formGroup = this.fb.group(value);
            formArray.push(formGroup);
        });
        return formArray;
    }
}
