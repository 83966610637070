import { Validators } from '@angular/forms';
import { ProduktArt, RadBauart, RadGeschwindigkeitsindex } from '@data/domain/schema/enum';
import { ConfigField, FormField, InputType } from '@shared/models/form-field.model';

// TODO currently general config, could be product specific in the future via getFormGroupByConfiguration
export const wheelsConfig: ConfigField[] = [
    {
        name: 'ort',
        label: 'oldtimer.wheels.fields.location',
        required: true,
        type: FormField.Select,
        style: 'col-span-12 sm:col-span-6',
        options: [
            {
                label: 'oldtimer.wheels.select.location.mounted',
                value: 'MONTIERT',
            },
            {
                label: 'oldtimer.wheels.select.location.demounted',
                value: 'DEMONTIERT',
            },
            {
                label: 'oldtimer.wheels.select.location.inVehicle',
                value: 'IM_FAHRZEUG',
            },
            {
                label: 'oldtimer.wheels.select.location.stored',
                value: 'AUSGELAGERT',
            },
            {
                label: 'oldtimer.wheels.select.location.stocked',
                value: 'EINGELAGERT',
            },
            {
                label: 'oldtimer.wheels.select.location.toBeSubmitted',
                value: 'WIRD_NACHGEREICHT',
            },
            {
                label: 'oldtimer.wheels.select.location.other',
                value: 'SONSTIGE',
            },
        ],
    },
    {
        name: 'felgentyp',
        label: 'oldtimer.wheels.fields.rimType',
        required: true,
        type: FormField.Select,
        style: 'col-span-12 sm:col-span-6',
        options: [
            {
                label: 'oldtimer.wheels.select.rimType.wood',
                value: 'HOLZ',
            },
            {
                label: 'oldtimer.wheels.select.rimType.steel',
                value: 'STAHL',
            },
            {
                label: 'oldtimer.wheels.select.rimType.lightAlloy',
                value: 'LEICHTMETALL',
            },
            {
                label: 'oldtimer.wheels.select.rimType.magnesium',
                value: 'MAGNESIUM',
            },
            {
                label: 'oldtimer.wheels.select.rimType.carbon',
                value: 'CARBON',
            },
            {
                label: 'oldtimer.wheels.select.rimType.hybrid',
                value: 'HYBRID',
            },
            {
                label: 'oldtimer.wheels.select.rimType.other',
                value: 'SONSTIGE',
            },
        ],
    },
    {
        name: 'reifenhersteller',
        label: 'oldtimer.wheels.fields.tireManufacturer',
        type: FormField.Input,
        style: 'col-span-12 sm:col-span-6',
        placeholder: 'Michelin',
    },
    {
        name: 'felgenhersteller',
        label: 'oldtimer.wheels.fields.rimManufacturer',
        type: FormField.Input,
        style: 'col-span-12 sm:col-span-6',
        placeholder: 'Porsche',
    },
    {
        name: 'reifentyp',
        label: 'oldtimer.wheels.fields.tireType',
        required: true,
        type: FormField.Select,
        style: 'col-span-8 sm:col-span-6',
        options: [
            {
                label: 'oldtimer.wheels.select.tireType.allWeather',
                value: 'ALLWETTER',
            },
            {
                label: 'oldtimer.wheels.select.tireType.summer',
                value: 'SOMMER',
            },
            {
                label: 'oldtimer.wheels.select.tireType.winter',
                value: 'WINTER',
            },
            {
                label: 'oldtimer.wheels.select.tireType.other',
                value: 'SONSTIGE',
            },
        ],
    },
    {
        name: 'runflat',
        label: 'oldtimer.wheels.fields.runflatTire',
        type: FormField.Toggle,
        style: 'col-span-4 sm:col-span-6',
    },
    {
        name: 'reifenbreite',
        label: 'oldtimer.wheels.fields.width',
        type: FormField.Input,
        inputType: InputType.Number,
        style: 'col-span-4 sm:col-span-2',
        inputStyle: 'text-right',
        suffix: '/',
        placeholder: '225',
    },
    {
        name: 'querschnitt',
        label: 'oldtimer.wheels.fields.aspectRatio',
        type: FormField.Input,
        inputType: InputType.Number,
        style: 'col-span-4 sm:col-span-2',
        inputStyle: 'text-right',
        placeholder: '50',
    },
    {
        name: 'art',
        label: 'oldtimer.wheels.fields.construction',
        type: FormField.Select,
        style: 'col-span-4 sm:col-span-2',
        options: Object.keys(RadBauart)
            .filter((key) => isNaN(Number(key)) && key !== 'None')
            .map((key) => ({
                label: key,
                value: key,
            })),
    },
    {
        name: 'durchmesser',
        label: 'oldtimer.wheels.fields.diameter',
        type: FormField.Input,
        inputType: InputType.Number,
        style: 'col-span-4 sm:col-span-2',
        inputStyle: 'text-right',
        placeholder: '16',
    },
    {
        name: 'tragfaehigkeit',
        label: 'oldtimer.wheels.fields.loadIndex',
        type: FormField.Input,
        inputType: InputType.Number,
        style: 'col-span-4 sm:col-span-2',
        inputStyle: 'text-right',
        placeholder: '800',
    },
    {
        name: 'vmax',
        label: 'oldtimer.wheels.fields.vmax',
        type: FormField.Select,
        style: 'col-span-4 sm:col-span-2',
        options: Object.keys(RadGeschwindigkeitsindex)
            .filter((key) => isNaN(Number(key)) && key !== 'None')
            .map((key) => ({
                label: key,
                value: key,
            })),
    },
    {
        name: 'profiltiefe',
        label: 'oldtimer.wheels.fields.treadDepth',
        type: FormField.Input,
        style: 'col-span-4',
        inputType: InputType.Number,
        suffix: 'mm',
        inputStyle: 'text-right',
    },
    {
        name: 'messmittel',
        label: 'oldtimer.wheels.fields.measuringEquipment',
        type: FormField.Select,
        style: 'col-span-8',
        options: [
            {
                label: 'oldtimer.wheels.select.measuringEquipment.caliper',
                value: 'MESSSCHIEBER',
            },
            {
                label: 'oldtimer.wheels.select.measuringEquipment.treadDepthGauge',
                value: 'REIFENPROFILTIEFENMESSER',
            },
            {
                label: 'oldtimer.wheels.select.measuringEquipment.depthGauge',
                value: 'TIEFENMESSER',
            },
            {
                label: 'oldtimer.wheels.select.measuringEquipment.other',
                value: 'SONSTIGE',
            },
        ],
    },
    {
        name: 'zustand',
        label: 'oldtimer.wheels.fields.tireCondition',
        type: FormField.Input,
    },
    {
        name: 'dot',
        label: 'oldtimer.wheels.fields.dot',
        type: FormField.Input,
        validators: [Validators.minLength(3), Validators.maxLength(4)],
        maxLength: 4,
        regex: '^[0-9]*$',
    },
    {
        name: 'bemerkungen',
        required: false,
        label: 'oldtimer.wheels.fields.comments',
        type: FormField.TextModule,
        settings: {
            feature: 'raeder',
            feld: 'bemerkungen',
            produktArt: ProduktArt.Oldtimer,
        },
        style: 'col-span-12',
    },
];

export const wheelsConfigOthers: ConfigField[] = [
    {
        name: 'notlaufeinrichtung',
        label: 'oldtimer.wheels.fields.emergencyRunning',
        type: FormField.Select,
        options: [
            {
                label: 'oldtimer.wheels.select.emergencyRunning.emergencyWheel',
                value: 'NOTRAD',
            },
            {
                label: 'oldtimer.wheels.select.emergencyRunning.spareWheel',
                value: 'ERSATZRAD',
            },
            {
                label: 'oldtimer.wheels.select.emergencyRunning.tirefit',
                value: 'TIREFIT',
            },
            {
                label: 'oldtimer.wheels.select.emergencyRunning.runflatTire',
                value: 'RUNFLAT_REIFEN',
            },
            {
                label: 'oldtimer.wheels.select.emergencyRunning.foldableWheel',
                value: 'FALTRAD',
            },
            {
                label: 'oldtimer.wheels.select.emergencyRunning.notAvailable',
                value: 'NICHT_VORHANDEN',
            },
        ],
    },
    {
        name: 'profiltiefe',
        label: 'oldtimer.wheels.fields.treadDepth',
        type: FormField.Input,
        inputType: InputType.Number,
        suffix: 'mm',
        inputStyle: 'text-right',
    },
    {
        name: 'bemerkungen',
        required: false,
        label: 'oldtimer.wheels.fields.comments',
        type: FormField.TextModule,
        settings: {
            feature: 'raeder',
            feld: 'bemerkungen_sonstiges',
            produktArt: ProduktArt.Oldtimer,
        },
    },
];
