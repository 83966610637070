import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'highlightMatch',
    standalone: true,
})
export class HighlightMatchPipe implements PipeTransform {
    transform(value: string, match: string | null): string {
        if (!match) return value;
        const regex = new RegExp(`(${match})`, 'gi');
        return value.replace(regex, '<strong>$1</strong>');
    }
}
