import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/guard/auth.guard';
import { EinstellungenKalkulationComponent } from '@modules/einstellungen/page/einstellungen-kalkulation/einstellungen-kalkulation.component';
import { EinstellungenComponent } from '@modules/einstellungen/page/einstellungen/einstellungen.component';
import { EinstellungenViewModelResolve } from '@modules/einstellungen/resolve/einstellungen-view-model.resolve';
import { TextbausteineVerwaltenComponent } from '@modules/textbausteine-verwalten/page/textbausteine-verwalten/textbausteine-verwalten.component';
import { VerwaltungComponent } from '@modules/verwaltung/page/verwaltung/verwaltung.component';

export const routes: Routes = [
    {
        path: '',
        component: VerwaltungComponent,
        data: {
            backUrl: '/',
        },
    },
    {
        path: 'einstellungen',
        component: EinstellungenComponent,
        canActivate: [AuthGuard],
        resolve: {
            model: EinstellungenViewModelResolve,
        },
        data: {
            backUrl: '/verwaltung',
        },
    },
    {
        path: 'textbausteine',
        component: TextbausteineVerwaltenComponent,
        canActivate: [AuthGuard],
        data: {
            backUrl: '/verwaltung',
        },
    },
    {
        path: 'kalkulationFaktoren',
        component: EinstellungenKalkulationComponent,
        canActivate: [AuthGuard],
        data: {
            backUrl: '/verwaltung',
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class VerwaltungRoutingModule {}
