import { Platform } from '@angular/cdk/platform';
import {
    ChangeDetectionStrategy,
    Component,
    effect,
    ElementRef,
    EventEmitter,
    HostListener,
    input,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { FormControlFocusService } from '@shared/service/form-controls/form-control-focus.service';
import * as moment from 'moment';
import { Moment } from 'moment';
import { FormControlBase } from '../form-control-base.component';
import { CustomDateAdapter } from './date.format.adapter';
import { DEFAULT_FORMAT, DateFormatService, MONTH_YEAR_FORMAT } from './date.format.service';

@Component({
    selector: 'app-date',
    templateUrl: './date.component.html',
    styleUrls: ['./date.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        CustomDateAdapter,
        DateFormatService,
        { provide: DateAdapter, useClass: CustomDateAdapter, deps: [DateFormatService, Platform] },
    ],
})
export class DateComponent extends FormControlBase implements OnInit {
    @ViewChild(MatInput, { static: true, read: ElementRef })
    input: ElementRef<HTMLElement>;

    @ViewChild(MatDatepicker, { static: true })
    datepicker: MatDatepicker<Date>;

    @Input()
    hasDatepicker = true;

    @Input()
    readonly: boolean;

    @Input()
    startView: 'month' | 'year' | 'multi-year' = 'multi-year';

    @Input()
    min: Date;

    @Input()
    max: Date;

    @Input()
    value: Date;

    disableDay = input<boolean>(false);

    @Input()
    disabled = false;

    @Output()
    focusInputField = new EventEmitter<any>();

    @Output()
    dateChange = new EventEmitter<any>();

    required = false;

    @HostListener('keydown', ['$event'])
    onKeydown(event: KeyboardEvent): void {
        if (!(event.key === 'Enter' || event.keyCode === 13)) {
            return;
        }
        if (!event.shiftKey) {
            this.next();
        } else {
            this.prev();
        }
    }

    constructor(
        formControlFocusService: FormControlFocusService,
        private readonly dateFormatService: DateFormatService,
    ) {
        super(formControlFocusService);

        effect(() => {
            this.dateFormatService.format = this.disableDay() ? MONTH_YEAR_FORMAT : DEFAULT_FORMAT;
            this.control.setValue(this.control.value);
        })
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.setRequired();
    }

    blur(): void {
        this.input.nativeElement.blur();
    }

    focus(): void {
        this.input.nativeElement.focus();
        if (this.hasDatepicker) {
            this.datepicker.open();
        }
    }

    chosenYearHandler(normalizedYear: Moment): void {
        const ctrlValue = moment(this.control.value ? this.control.value : undefined);
        if (this.disableDay()) {
            ctrlValue.date(1);
        }
        ctrlValue.year(normalizedYear.year());
        this.control.setValue(ctrlValue.toISOString());
    }

    chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>): void {
        const ctrlValue = moment(this.control.value ? this.control.value : undefined);
        ctrlValue.month(normalizedMonth.month());
        this.control.setValue(ctrlValue.toISOString());

        if (this.disableDay()) {
            datepicker.close();
        }
    }

    private setRequired(): void {
        if (!this.form || !this.control) {
            return;
        }

        const validator = this.control?.validator?.({} as AbstractControl);
        if (validator?.required) {
            this.required = true;
        }
    }
}
