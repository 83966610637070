import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { TrackBy } from '@shared/helper/track-by';
import { Values } from '@shared/helper/values';
import { FormControlFocusService } from '@shared/service/form-controls/form-control-focus.service';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { BehaviorSubject } from 'rxjs';
import { FormControlBase } from '../form-control-base.component';

export enum MaxColumnEntries {
    MOBILE = 5,
    TABLET = 15,
}

export enum ColumnCount {
    ONE = 1,
    TWO = 2,
    THREE = 3,
}

@Component({
    selector: 'app-extendable-radio-list',
    templateUrl: './extendable-radio-list.component.html',
    styleUrls: ['./extendable-radio-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExtendableRadioListComponent extends FormControlBase implements OnInit {
    private ref: MatDialogRef<any>;

    trackByKey = TrackBy.trackByKey;

    filteredValues$ = new BehaviorSubject<string[]>([]);
    visibleValues: string[];

    columnCountEnum = ColumnCount;

    @Input()
    values: Values;

    @Input()
    keys: string[] | number;

    @Input()
    columnCount = ColumnCount.ONE;

    @Input()
    enableExtendedList = true;

    @ViewChild('list', { static: true })
    listTemplate: TemplateRef<any>;

    constructor(
        formControlFocusService: FormControlFocusService,
        private readonly templateDialogService: TemplateDialogService,
    ) {
        super(formControlFocusService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.init();
        this.filter();
    }

    onShowListClick(): void {
        const buttons = [this.templateDialogService.getCancelButtonSetting()];
        this.filter();
        this.ref = this.templateDialogService.open(
            'list.more',
            buttons,
            this.listTemplate,
            { value: this.control.value },
            true,
        );
    }

    onValueChange(value: string): void {
        this.setValue(value);
    }

    onAddClick(input: MatInput): void {
        if (input.value.length <= 0) {
            return;
        }

        this.setValue(input.value);
    }

    onFilterKeyUp(input: MatInput): void {
        const filterValue = (input.value || '').toLocaleLowerCase();
        this.filter(filterValue);
    }

    blur(): void {
        // not implemented
    }

    focus(): void {
        // not implemented
    }

    private filter(filterValue: string = ''): void {
        const values = this.values.keys.map((key) => this.values.values[key]);
        const selected = this.control.value;
        if (selected && !this.values.keys.find((key) => this.values.values[key] === selected)) {
            values.unshift(selected);
        }
        this.filteredValues$.next(values.filter((value) => value.toLowerCase().indexOf(filterValue) !== -1));
    }

    private setValue(value: string): void {
        if (this.ref) {
            this.ref.afterClosed().subscribe(() => {
                this.control.setRawValue(value);
            });
            this.ref.close();
            this.ref = null;
        }
    }

    private init(): void {
        let keys = this.keys as string[];
        if (!(keys instanceof Array)) {
            const count = +this.keys;
            if (this.enableExtendedList) {
                keys = this.values.keys.slice(0, count);
            } else {
                keys = this.values.keys;
            }
        }
        this.visibleValues = keys.map((key) => this.values.values[key]);
    }

    protected readonly MaxColumnEntries = MaxColumnEntries;
}
