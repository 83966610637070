import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { ArrayField, ConfigField, FormField, FormFieldArrays } from '@shared/models/form-field.model';
import { ProduktFormService } from '@shared/service/produkt-form.service';
import { FormCompletionComponent } from '@shared/standalone/form-array/form-completion/form-completion.component';
import { ItemPreviewTemplateComponent } from '@shared/standalone/form-array/item-preview-template/item-preview-template.component';
import { FormDenseArrayComponent } from '@shared/standalone/form-dense-array/form-dense-array.component';
import { FormFieldComponent } from '@shared/standalone/form-field/form-field.component';

@Component({
    selector: 'app-form-array',
    standalone: true,
    imports: [
        MatIconModule,
        MatButtonModule,
        MatExpansionModule,
        FormFieldComponent,
        TranslateModule,
        FormDenseArrayComponent,
        FormCompletionComponent,
        NgClass,
        ItemPreviewTemplateComponent,
        MatCardModule,
        NgTemplateOutlet,
    ],
    templateUrl: './form-array.component.html',
    styleUrl: './form-array.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class FormArrayComponent {
    arrayField = input.required<ArrayField>();
    fieldFormArray = input.required<FormArray>();

    protected readonly FormField = FormField;

    addItem(patchValue?: object): void {
        const formGroup = new FormGroup({});
        this.arrayField().fields.forEach((field) => {
            if (!field.name) return;
            const control = ProduktFormService.getControlByConfigField(field);
            if (!control.value && field.defaultValue) {
                control.setValue(field.defaultValue());
            }
            formGroup.addControl(field.name, control);
        });

        if (patchValue) {
            formGroup.patchValue(patchValue);
        }

        this.fieldFormArray().markAsDirty();
        this.fieldFormArray().insert(0, formGroup);
    }

    removeItem(index: number): void {
        this.fieldFormArray().markAsDirty();
        this.fieldFormArray().removeAt(index);
    }

    getFormControlByFieldName(formGroup: FormGroup, fieldName?: string): FormControl | undefined {
        if (!fieldName) return;
        return formGroup.get(fieldName) as FormControl | undefined;
    }

    getFormArrayByFieldName(formGroup?: FormGroup, fieldName?: string): FormArray | undefined {
        if (!fieldName || !formGroup) return;
        return formGroup.get(fieldName) as FormArray | undefined;
    }

    getFormGroupByControl(control: AbstractControl): FormGroup | undefined {
        if (control instanceof FormGroup) {
            return control;
        }
        return undefined;
    }

    isArrayField(field: ConfigField): field is ArrayField {
        return FormFieldArrays.includes(field.type);
    }
}
