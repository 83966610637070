<mat-form-field class="textbausteine-field-wrapper">
    <mat-label>{{ displayName | translate }}</mat-label>
    <mat-icon
        class="open-dialog-button material-icons-outlined"
        [class.disabled]="!multiple() && control.value.length >= 1"
        (click)="!multiple() && control.value.length >= 1 ? {} : onAddTextbausteinClicked()"
        matSuffix
    >
        font_download
    </mat-icon>
    <mat-chip-grid #list>
        @for (value of control.value; track value) {
            <mat-chip-row
                (removed)="onChipRemoveClicked($index)"
                (click)="onChipClicked(viewControlArray.controls[$index])"
                matTooltip="{{ value.langtext }}"
            >
                {{ value.kurztext }}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip-row>
        }

        <input
            #input
            [placeholder]="displayName | translate"
            [readonly]="true"
            [id]="name"
            [formControl]="viewControl"
            [matChipInputFor]="list"
            [matChipInputSeparatorKeyCodes]="seperators"
            matInput
        />
    </mat-chip-grid>
</mat-form-field>

<!-- Delete dialog -->
<ng-template #delete>
    <app-delete-dialog />
</ng-template>

<!-- Edit dialog -->
<ng-template #dialogEdit let-data="data">
    <app-textbausteine-dialog-edit
        [form]="data.form"
        [feature]="data.feature"
        [feld]="data.feld"
        [data]="data"
        [feldDisplayName]="displayName"
    />
</ng-template>

<!-- dialog add textbaustein -->
<ng-template #dialog let-data="data">
    <app-textbausteine-list
        [feature]="feature()"
        [feld]="data.feld"
        [feldDisplayName]="displayName"
        [produktArt]="produktArt()"
        (textbausteinEventEmitter)="onTextbausteinAdd($event)"
    />
</ng-template>
