import { NgClass } from '@angular/common';
import { Component, effect, input } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { ArrayField } from '@shared/models/form-field.model';
import { ProduktFormService } from '@shared/service/produkt-form.service';
import { FormFieldComponent } from '@shared/standalone/form-field/form-field.component';

@Component({
    selector: 'app-form-dense-array',
    standalone: true,
    imports: [
        MatButtonModule,
        MatIconModule,
        TranslateModule,
        FormFieldComponent,
        MatDividerModule,
        NgClass,
    ],
    templateUrl: './form-dense-array.component.html',
    styleUrl: './form-dense-array.component.scss',
})
export class FormDenseArrayComponent {
    field = input.required<ArrayField>();
    fieldFormArray = input.required<FormArray>();

    constructor() {
        effect(() => {
            this.setupInitialControls(this.field(), this.fieldFormArray());
        });
    }

    addItem(): void {
        const formGroup = new FormGroup({});
        this.field().fields.forEach((field) => {
            if (!field.name) return;
            const control = ProduktFormService.getControlByConfigField(field);
            if (!control.value && field.defaultValue) {
                control.setValue(field.defaultValue());
            }
            formGroup.addControl(field.name, control);
        });
        this.fieldFormArray().markAsDirty();
        this.fieldFormArray().push(formGroup);
    }

    removeItem(index: number): void {
        this.fieldFormArray().markAsDirty();
        this.fieldFormArray().removeAt(index);
    }

    getFormGroupByControl(control: AbstractControl): FormGroup | undefined {
        if (control instanceof FormGroup) {
            return control;
        }
        return undefined;
    }

    getFormControlByFieldName(formGroup: FormGroup, fieldName?: string): FormControl | undefined {
        if (!fieldName) return;
        return formGroup.get(fieldName) as FormControl | undefined;
    }

    isAddButtonDisabled(): boolean {
        const maxItems = this.field().settings.maxItems;

        if (maxItems) {
            return this.fieldFormArray().length >= maxItems;
        }
        return false;
    }

    isDeleteButtonDisabled(index: number): boolean {
        const minItems = this.field().settings.minItems;

        if (minItems) {
            return index < minItems;
        }
        return false;
    }

    private setupInitialControls(field: ArrayField, fieldFormArray: FormArray): void {
        const minItems = field.settings.minItems;
        if (minItems && fieldFormArray.length < minItems) {
            for (let i = 0; i < minItems; i++) {
                this.addItem();
            }
        }
    }
}
