import gql from 'graphql-tag';

export const TEXTBAUSTEIN_FIELDS = gql`
    fragment TextbausteinFields on Textbaustein {
        id
        kurztext
        langtext
        verfuegbarkeit
        produkte
        feature
        feld
        tags
        standard
        erstelltAm
        erstelltVon
    }
`;
