import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
    Maybe,
    OldtimerAchse,
    OldtimerRad,
    OldtimerRadsatz,
    OldtimerRaederDetails,
    OldtimerRaederSonstiges,
} from '@data/domain/schema/types';
import { ProduktFormService } from '@shared/service/produkt-form.service';
import { wheelsConfig, wheelsConfigOthers } from '@shared/standalone/form-wheels/form-wheels.configuration';

export enum FormWheelsFields {
    wheelSets = 'radSaetze',
    others = 'sonstiges',
}

export type WheelSetWheels = FormArray<FormGroup>;
export type WheelSetSides = FormArray<FormGroup<{ raeder: WheelSetWheels }>>;
export type WheelSetAxles = FormArray<FormGroup<{ seiten: WheelSetSides }>>;
export type WheelSet = FormGroup<{ achsen: WheelSetAxles }>;

@Injectable({
    providedIn: 'root',
})
export class FormWheelsService {
    static getWheelsFormGroup(patchValue?: OldtimerRaederDetails): FormGroup {
        const wheelSetsData = patchValue?.radSaetze || [undefined];
        const wheelSets = wheelSetsData.map((wheelSet) => this.getWheelSetFormGroup(wheelSet));

        return new FormGroup({
            [FormWheelsFields.wheelSets]: new FormArray(wheelSets || []),
            [FormWheelsFields.others]: this.getOthersFormGroup(patchValue?.sonstiges),
        });
    }

    // TODO combine this and othersFormGroup into one method
    static getSingleWheelFormGroup(patchValue?: Maybe<OldtimerRad>): FormGroup {
        const formGroup = new FormGroup({});
        wheelsConfig.forEach((field) => {
            if (!field.name) {
                return;
            }

            const validators = field.validators || [];
            if (field.required) {
                validators.push(Validators.required);
            }
            const control = new FormControl(undefined, validators);
            formGroup.addControl(field.name, control);
        });

        if (patchValue) {
            formGroup.patchValue(patchValue);
        }

        return formGroup;
    }

    static getWheelSetFormGroup(wheelSetPatch?: Maybe<OldtimerRadsatz>): WheelSet {
        const axlesArray: WheelSetAxles = new FormArray<FormGroup>([]);

        const vehicleConfig = this.getVehicleConfig();
        const defaultVehicleConfig: OldtimerAchse[] = vehicleConfig.map((length) => ({
            seiten: Array.from({ length }, () => ({ raeder: [{}] })),
        }));

        (wheelSetPatch?.achsen || defaultVehicleConfig).forEach((wheelSides) => {
            const sideArray: WheelSetSides = new FormArray<FormGroup>([]);
            wheelSides?.seiten?.forEach((wheelSide) => {
                const wheelsArray: WheelSetWheels = new FormArray<FormGroup>([]);

                wheelSide?.raeder?.forEach((wheel) => {
                    const formGroup = this.getSingleWheelFormGroup(wheel);
                    wheelsArray.push(formGroup);
                });
                const wheelsFormGroup = new FormGroup({ raeder: wheelsArray });
                sideArray.push(wheelsFormGroup);
            });
            const sideFormGroup = new FormGroup({ seiten: sideArray });
            axlesArray.push(sideFormGroup);
        });

        return new FormGroup({ achsen: axlesArray });
    }

    private static getOthersFormGroup(othersPatch?: Maybe<OldtimerRaederSonstiges>): FormGroup {
        const othersFormGroup = new FormGroup({});
        wheelsConfigOthers.forEach((field) => {
            if (!field.name) return;
            othersFormGroup.addControl(field.name, ProduktFormService.getControlByConfigField(field));
        });

        if (othersPatch) {
            othersFormGroup.patchValue(othersPatch);
        }

        return othersFormGroup;
    }

    private static getVehicleConfig(): number[] {
        // TODO get vehicle config based on vehicle type -> [(1 single | 2 double)]
        return [1, 2];
    }
}
