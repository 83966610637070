<span class="hidden only:block">
    @if (newItemLabel(); as newItemLabel) {
        {{ newItemLabel | translate }}
    }
</span>

@for (previewTemplate of previewTemplates(); track previewTemplate.key) {
    @if (formGroup().get(previewTemplate.key)?.value; as value) {
        <span>
            @switch (previewTemplate.type) {
                @case (FormField.Date) {
                    {{ value | date: previewTemplate.format }}
                }
                @case (FormField.DateRange) {
                    {{ value.from | previewTemplateDate }} -
                    {{ value.to | previewTemplateDate }}
                }
                @default {
                    {{ (previewTemplate.translationPrefix || '') + value | translate }}
                }
            }
        </span>
        <span class="pr-1 last-of-type:hidden">
            {{ previewTemplate.separator ?? '|' }}
        </span>
    }
}
