import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, effect, input, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { SelectField, SelectFieldOption } from '@shared/models/form-field.model';

@Component({
    selector: 'app-form-field-select',
    standalone: true,
    imports: [
        MatFormFieldModule,
        MatInputModule,
        TranslateModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatIconModule,
        NgClass,
    ],
    templateUrl: './form-field-select.component.html',
    styleUrl: './form-field-select.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class FormFieldSelectComponent {
    field = input.required<SelectField>();
    fieldFormControl = input.required<FormControl>();
    parentFormGroup = input<FormGroup>();

    private readonly dependencyFormArray = new FormArray<FormControl>([]);

    constructor(private readonly destroyRef: DestroyRef) {
        effect(() => {
            const options = this.field().options;
            this.handleDependencies(options);
        });
    }

    getValidationErrors(): { key: string; value: unknown }[] {
        return Object.entries<unknown>(this.fieldFormControl().errors || {}).map(([key, value]) => ({ key, value }));
    }

    isOptionVisible(option: SelectFieldOption): boolean {
        if (!option.dependency) return true;

        const dependentControl = this.parentFormGroup()?.get(option.dependency.path);
        if (!dependentControl) {
            console.warn(`Control with path ${option.dependency.path} not found`);
            return false;
        }

        return option.dependency.values.includes(dependentControl.value);
    }

    private handleDependencies(options: SelectFieldOption[]): void {
        if (!options) return;

        options.forEach((option) => {
            if (option.dependency) {
                const dependentControl = this.parentFormGroup()?.get(option.dependency.path);
                if (!dependentControl) {
                    console.warn(`Control with path ${option.dependency.path} not found`);
                    return;
                }

                this.dependencyFormArray.push(dependentControl as FormControl);
            }
        });

        this.dependencyFormArray.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((_value) => {
            this.fieldFormControl().setValue(null);
        });
    }
}
