import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RadLagerort, RaederNotlaufeinrichtung } from '@data/domain/schema/enum';
import { Achse, NfzRadsatz, NfzRaeder, NfzRaederInput, Notiz, Raeder } from '@data/domain/schema/type';
import { ProduktNfzRaederService } from '@data/domain/service/feature/produkt-nfz-raeder.service';
import { TextbausteineService } from '@data/domain/service/textbausteine.service';
import { ProduktDetailFeatureInputComponent } from '@modules/produkt/component/produkt-detail-feature/produkt-detail-feature.component';
import { FeatureFieldArray, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ProduktDetailNfzRaederFormViewFactory } from '@modules/produkt/factory/nfz-raeder/produkt-detail-nfz-raeder-form-view.factory';
import { ProduktDetailNfzRaederRadsatzAchseFormViewFactory } from '@modules/produkt/factory/nfz-raeder/produkt-detail-nfz-raeder-radsatz-achse-form-view.factory';
import { ProduktDetailNfzRaederRadsatzAchseSeiteRadFormViewFactory } from '@modules/produkt/factory/nfz-raeder/produkt-detail-nfz-raeder-radsatz-achse-seite-rad-form-view.factory';
import { ProduktDetailNfzRaederRadsatzFormViewFactory } from '@modules/produkt/factory/nfz-raeder/produkt-detail-nfz-raeder-radsatz-form-view.factory';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ProduktConfigResolveService } from '@modules/produkt/service/produkt-config-resolve.service';
import { ProduktDetailFeatureNotizenService } from '@modules/produkt/service/produkt-detail-feature-notizen.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { TextbausteineComponent } from '@shared/component/form-controls/textbausteine/textbausteine.component';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { EnumValues } from '@shared/helper/values';
import { Observable, take } from 'rxjs';

@Component({
    selector: 'app-produkt-detail-raeder',
    templateUrl: './produkt-detail-nfz-raeder.component.html',
    styleUrls: ['./produkt-detail-nfz-raeder.component.scss'],
    providers: [ProduktDetailFeatureNotizenService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktDetailNfzRaederComponent
    extends ProduktDetailFeatureInputComponent<NfzRaeder, NfzRaederInput>
    implements OnInit, AfterViewInit, OnDestroy {
    feature = PRODUKT_CONFIG_FEATURES.NfzRaeder.name;
    fieldBemerkungen = PRODUKT_CONFIG_FEATURES.NfzRaeder.fields.Bemerkungen.name;

    fieldsRadSaetze;
    fieldsAchsen;
    fieldsSeiten;
    fieldsAchsenRaeder;

    trackByField = TrackBy.trackByField;
    trackByIndex = TrackBy.trackByIndex;

    notizen$: Observable<Notiz[]>;

    notlaufeinrichtung = new EnumValues(RaederNotlaufeinrichtung);
    valuesLagerort = new EnumValues(RadLagerort);

    @ViewChild('bemerkungen')
    bemerkungenElement: TextbausteineComponent;

    get radSaetze(): ViewFormArray {
        return this.form.get(PRODUKT_CONFIG_FEATURES.NfzRaeder.fields.RadSaetze.name) as ViewFormArray;
    }

    constructor(
        produktConfigResolveService: ProduktConfigResolveService,
        produktDetailResolveService: ProduktDetailResolveService,
        produktNfzRaederService: ProduktNfzRaederService,
        private readonly formViewFactory: ProduktDetailNfzRaederFormViewFactory,
        private readonly textbausteineService: TextbausteineService,
        private readonly nfzRadSatzFormViewFactory: ProduktDetailNfzRaederRadsatzFormViewFactory,
        private readonly nfzRaederRadsatzAchseFormViewFactory: ProduktDetailNfzRaederRadsatzAchseFormViewFactory,
        private readonly nfzRaederRadsatzAchseSeiteRadFormViewFactory: ProduktDetailNfzRaederRadsatzAchseSeiteRadFormViewFactory,
        private readonly notizenService: ProduktDetailFeatureNotizenService,
    ) {
        super(produktConfigResolveService, produktDetailResolveService, produktNfzRaederService);
        Assert.notNullOrUndefined(formViewFactory, 'formViewFactory');
        Assert.notNullOrUndefined(nfzRadSatzFormViewFactory, 'nfzRadSatzFormViewFactory');
        Assert.notNullOrUndefined(textbausteineService, 'textbausteineService');
        Assert.notNullOrUndefined(nfzRaederRadsatzAchseFormViewFactory, 'nfzRaederRadsatzAchseFormViewFactory');
        Assert.notNullOrUndefined(
            nfzRaederRadsatzAchseSeiteRadFormViewFactory,
            'nfzRaederRadsatzAchseSeiteRadFormViewFactory',
        );
        Assert.notNullOrUndefined(notizenService, 'notizenService');
    }

    ngOnInit(): void {
        this.notizen$ = this.notizenService.init(this.produkt, this.feature);
        this.init(this.feature);
        if (this.radSaetze && this.radSaetze.length === 0) {
            this.addSatz();
        }

        this.fieldsRadSaetze = (this.fields[0] as FeatureFieldArray).fields;
        this.fieldsAchsen = (this.fieldsRadSaetze[1] as FeatureFieldArray).fields;
        this.fieldsSeiten = (this.fieldsAchsen[1] as FeatureFieldArray).fields;
        this.fieldsAchsenRaeder = (this.fieldsSeiten[1] as FeatureFieldArray).fields;
    }

    ngAfterViewInit(): void {
        if (this.form?.get(this.fieldBemerkungen) && this.produkt?.raeder?.bemerkungen === null) {
            this.textbausteineService.prefillWithStandardTextbausteine(
                this.feature,
                this.fieldBemerkungen,
                this.produkt.art,
                this.form,
            );
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.produkt.nfzRaeder = this.form.getRawValue();
    }

    onAddSatzClick(): void {
        this.addSatz();
    }

    onDuplicateSatzClick(index: number): void {
        this.duplicateSatz(index);
    }

    onRemoveSatzClick(index: number): void {
        this.removeSatz(index);
    }

    onNotizenChange(notizen: Notiz[]): void {
        Assert.notNullOrUndefined(notizen, 'notizen');
        this.notizenService.save(notizen).pipe(take(1)).subscribe();
    }

    onDuplicateAchseClick(radsatz: ViewFormGroup, achse: ViewFormGroup) {
        (radsatz.get('achsen') as ViewFormArray).controls.push(achse);
    }

    onAddAchseClick(radsatz: ViewFormGroup) {
        const newAchse: Achse = {
            bewertung: '',
            seiten: [
                {
                    achsenRaeder: [{}],
                },
                {
                    achsenRaeder: [{}],
                },
            ],
        };
        const form = this.nfzRaederRadsatzAchseFormViewFactory.create(newAchse, this.fieldsAchsen);
        (radsatz.get('achsen') as ViewFormArray).controls.push(form);
    }

    onDeleteAchseClick(radsatz: ViewFormGroup, achseIndex: number) {
        (radsatz.get('achsen') as ViewFormArray).controls.splice(achseIndex, 1);
    }

    onDuplicateRadClick(achse: ViewFormGroup, seite: number, rad: ViewFormGroup) {
        const seiten = (achse.get('seiten') as ViewFormArray).controls[seite];
        seiten.patchValue({ ['seite']: seite });
        (seiten.get('achsenRaeder') as ViewFormArray).controls.push(rad);
    }

    onAddRadClick(achse: ViewFormGroup, seite: number) {
        const seiten = (achse.get('seiten') as ViewFormArray).controls[seite];
        seiten.patchValue({ ['seite']: seite });
        (seiten.get('achsenRaeder') as ViewFormArray).controls.push(
            this.nfzRaederRadsatzAchseSeiteRadFormViewFactory.create({}, this.fieldsAchsenRaeder),
        );
    }

    onDeleteRadClick(achse: ViewFormGroup, seite: number, radIndex: number) {
        const seiten = (achse.get('seiten') as ViewFormArray).controls[seite];
        (seiten.get('achsenRaeder') as ViewFormArray).controls.splice(radIndex, 1);
    }

    onDuplicateSeiteClick(radsatz: ViewFormGroup, radsatzIndex: number, seite: number) {
        const achsen = (radsatz.get('achsen') as ViewFormArray).controls[radsatzIndex];
        const seiteToCopy = (achsen.get('seiten') as ViewFormArray).controls[seite] as ViewFormGroup;
        if (seite === 0) {
            (achsen.get('seiten') as ViewFormArray).controls[1] = seiteToCopy;
        } else if (seite === 1) {
            (achsen.get('seiten') as ViewFormArray).controls[0] = seiteToCopy;
        }
    }

    protected getFeature(): Raeder {
        return this.form.getRawValue();
    }

    protected createForm(): ViewFormGroup {
        return this.formViewFactory.create(this.produkt.nfzRaeder, this.fields);
    }

    private addSatz(): void {
        const radSaetzeFields = (
            this.fields.find(
                (x: FeatureFieldArray) => x.arrayName === PRODUKT_CONFIG_FEATURES.NfzRaeder.fields.RadSaetze.name,
            ) as FeatureFieldArray
        ).fields;
        const nfzRadsatz: NfzRadsatz = {
            achsen: [
                {
                    bewertung: '',
                    seiten: [
                        {
                            achsenRaeder: [{}],
                        },
                        {
                            achsenRaeder: [{}],
                        },
                    ],
                },
            ],
        };
        const form = this.nfzRadSatzFormViewFactory.create(nfzRadsatz, radSaetzeFields);
        this.radSaetze.push(form);
    }

    private duplicateSatz(index: number): void {
        const radSaetzeFields = (
            this.fields.find(
                (x: FeatureFieldArray) => x.arrayName === PRODUKT_CONFIG_FEATURES.NfzRaeder.fields.RadSaetze.name,
            ) as FeatureFieldArray
        ).fields;
        const radSatz = (this.radSaetze.controls[index] as ViewFormGroup).getRawValue() as NfzRadsatz;
        const form = this.nfzRadSatzFormViewFactory.create(radSatz, radSaetzeFields);
        this.radSaetze.push(form);
    }

    private removeSatz(index: number): void {
        this.radSaetze.removeAt(index);
    }
}
