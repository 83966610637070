@if (field(); as field) {
    @if (isLabelField(field)) {
        <p [ngClass]="field.labelStyles">{{ field.label || '' | translate }}</p>
    }

    @if (fieldFormControl(); as formControl) {
        <!-- @defer is not narrowing type by type guard => workaround by using @if first-->
        @if (isInputField(field); as isInputField) {
            @defer (when isInputField) {
                <app-form-field-input [field]="field" [fieldFormControl]="formControl" />
            }
        }

        @if (isTextareaField(field); as isTextareaField) {
            @defer (when isTextareaField) {
                <app-form-field-textarea [field]="field" [fieldFormControl]="formControl" />
            }
        }

        @if (isSelectField(field); as isSelectField) {
            @defer (when isSelectField) {
                <app-form-field-select
                    [field]="field"
                    [fieldFormControl]="formControl"
                    [parentFormGroup]="parentFormGroup()"
                />
            }
        }

        @if (isDateField(field); as isDateField) {
            @defer (when isDateField) {
                <app-form-field-date-time [field]="field" [fieldFormControl]="formControl" />
            }
        }

        @if (isDateRangeField(field); as isDateRangeField) {
            @defer (when isDateRangeField) {
                <app-form-field-date-range [field]="field" [fieldFormControl]="formControl" />
            }
        }

        @if (isTextModuleField(field); as isTextModuleField) {
            @defer (when isTextModuleField) {
                <app-form-field-text-module [field]="field" [fieldFormControl]="formControl" />
            }
        }

        @if (isListField(field); as isListField) {
            @defer (when isListField) {
                <app-form-field-list [field]="field" [fieldFormControl]="formControl" />
            }
        }

        @if (isRadioGroupField(field); as isRadioGroupField) {
            @defer (when isRadioGroupField) {
                <app-form-field-radio-group [field]="field" [fieldFormControl]="formControl" />
            }
        }

        @if (isVinField(field); as isVinField) {
            @defer (when isVinField) {
                <app-form-field-vin
                    [parentFormGroup]="parentFormGroup()"
                    [field]="field"
                    [fieldFormControl]="formControl"
                />
            }
        }

        @if (isStatusReportField(field); as isStatusReportField) {
            @defer (when isStatusReportField) {
                <app-form-field-status-report
                    [field]="field"
                    [fieldFormControl]="formControl"
                    [parentFormGroup]="parentFormGroup()"
                />
            }
        }

        @defer (when field.type === FormField.Toggle) {
            <app-form-field-toggle [field]="field" [fieldFormControl]="formControl" />
        }

        @defer (when field.type === FormField.ImageUpload) {
            <app-form-field-image-upload [field]="field" [fieldFormControl]="formControl" />
        }
    }
}
