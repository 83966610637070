import { SystemArtEnum } from '@data/api-gateway/service/einstellungen.service';
import { FormField, InputType } from '@shared/models/form-field.model';

export const einstellungenKalkulationConfiguration = {
    name: 'calculationSettings',
    type: FormField.Array,
    label: 'einstellungen.kalkulation.calculationSettings.title',
    style: 'grid lg:grid-cols-2',
    settings: {
        emptyMessage: 'einstellungen.kalkulation.calculationSettings.emptyMessage',
        newItemLabel: 'einstellungen.kalkulation.calculationSettings.newItemLabel',
        previewTemplate: [
            { key: 'name' },
        ],
    },
    fields: [
        {
            name: 'name',
            required: true,
            type: FormField.Input,
            label: 'einstellungen.kalkulation.faktoren.name',
            style: 'lg:col-span-2',
        },
        {
            type: FormField.Label,
            label: 'einstellungen.kalkulation.faktoren.arbeitslohnfaktoren',
            style: 'lg:col-span-2',
            labelStyles: 'font-bold text-xl',
        },
        {
            name: 'mechanik',
            type: FormField.Input,
            inputType: InputType.Number,
            label: 'einstellungen.kalkulation.faktoren.mechanik',
            suffix: '€ / Stunde',
        },
        {
            name: 'karosserie',
            type: FormField.Input,
            inputType: InputType.Number,
            label: 'einstellungen.kalkulation.faktoren.karosserie',
            suffix: '€ / Stunde',
        },
        {
            name: 'elektrik',
            type: FormField.Input,
            inputType: InputType.Number,
            label: 'einstellungen.kalkulation.faktoren.elektrik',
            suffix: '€ / Stunde',
        },
        {
            name: 'dellenDruecken',
            type: FormField.Input,
            inputType: InputType.Number,
            label: 'einstellungen.kalkulation.faktoren.dellenDruecken',
            suffix: '€ / Stunde',
        },
        {
            type: FormField.Label,
            label: 'einstellungen.kalkulation.faktoren.lackfaktoren',
            labelStyles: 'font-bold text-xl',
            style: 'lg:col-span-2',
        },
        {
            name: 'systemArt',
            type: FormField.Select,
            label: 'einstellungen.kalkulation.faktoren.systemArt',
            style: 'lg:col-span-2',
            options: [
                { label: 'einstellungen.kalkulation.faktoren.azt', value: SystemArtEnum.AZT },
                { label: 'einstellungen.kalkulation.faktoren.hersteller', value: SystemArtEnum.Hersteller },
            ],
        },
        {
            type: FormField.Label,
            label: 'einstellungen.kalkulation.faktoren.azt',
            labelStyles: 'font-semibold py-0',
            style: 'lg:col-span-2',
        },
        {
            name: 'aztLacklohn',
            type: FormField.Input,
            inputType: InputType.Number,
            label: 'einstellungen.kalkulation.faktoren.lacklohn',
            suffix: '€ / Stunde',
        },
        {
            name: 'aztLackmaterial',
            type: FormField.Input,
            inputType: InputType.Number,
            label: 'einstellungen.kalkulation.faktoren.lackmaterial',
            suffix: '%',
        },
        {
            type: FormField.Label,
            label: 'einstellungen.kalkulation.faktoren.hersteller',
            labelStyles: 'font-semibold py-0',
            style: 'lg:col-span-2',
        },
        {
            name: 'herstellerLacklohn',
            type: FormField.Input,
            inputType: InputType.Number,
            label: 'einstellungen.kalkulation.faktoren.lacklohn',
            suffix: '€ / Stunde',
        },
        {
            name: 'herstellerLackmaterial',
            type: FormField.Input,
            inputType: InputType.Number,
            label: 'einstellungen.kalkulation.faktoren.lackmaterial',
            suffix: '%',
        },
    ],
};
