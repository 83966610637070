<div class="main-content">
    <div class="toggle">
        <app-toggle-group [value]="display$ | async" (valueChange)="onDisplayChange($event)">
            <app-toggle [value]="schadenDisplay.Umfang">
                {{ name + '.umfang' | translate }}
            </app-toggle>
            <app-toggle [value]="schadenDisplay.Exterieur" [disabled]="modelDisabled$ | async">
                {{ name + '.exterieur' | translate }}
            </app-toggle>
            <app-toggle [value]="schadenDisplay.Interieur" [disabled]="modelDisabled$ | async">
                {{ name + '.interieur' | translate }}
            </app-toggle>
            <app-toggle [value]="schadenDisplay.Technik" [disabled]="modelDisabled$ | async">
                {{ name + '.technik' | translate }}
            </app-toggle>
            <app-toggle [value]="schadenDisplay.Manuell">
                {{ name + '.manuell' | translate }}
            </app-toggle>
            <app-toggle [value]="schadenDisplay.Text">
                {{ name + '.text' | translate }}
            </app-toggle>
            <app-toggle *ngIf="showKalkulation() | async" [value]="schadenDisplay.Daten">
                {{ name + '.daten' | translate }}
            </app-toggle>
        </app-toggle-group>
    </div>

    <ng-container *ngIf="displayModel(); else noModel">
        <app-produkt-detail-schaden-scene
            [modelFileConfigs]="modelFileConfigs"
            [gruppe]="display$ | async"
            (obergruppeSelect)="onObergruppeSelect($event)"
            (modelLoad)="onModelLoad($event)"
        />
    </ng-container>

    <ng-template #noModel>
        <ng-container [ngSwitch]="display$ | async">
            <app-card *ngSwitchCase="schadenDisplay.Umfang">
                <app-produkt-detail-feststellungen-umfang
                    [umfaenge]="umfaenge"
                    (addClicked)="onAddUmfangClicked($event)"
                    (deleteClicked)="onDeleteUmfangClicked($event)"
                />
            </app-card>
            <app-card *ngSwitchCase="schadenDisplay.Manuell">
                <app-action-list
                    [values]="obergruppe"
                    [searchable]="true"
                    (action)="onObergruppeAction($event)"
                    (submit)="onObergruppeSelect($event)"
                />
            </app-card>
            <app-card *ngSwitchCase="schadenDisplay.Text">
                <app-textbausteine
                    #beschreibungen
                    [form]="form"
                    [name]="'beschreibungen'"
                    [displayName]="'Beschreibungen'"
                    [feature]="name"
                    [produktArt]="this.produkt.art"
                />
            </app-card>
            <app-card *ngSwitchCase="schadenDisplay.Daten">
                <h4 *ngIf="!this.hasFahrzeugExternalServiceReference">
                    {{ name + '.kalkulation.keineIdentabfrage' | translate }}
                </h4>
                <app-button
                    class="kalkulation-open"
                    *ngIf="(viewport$ | async) !== viewport.Mobile"
                    [type]="ButtonType.SECONDARY"
                    [color]="'primary'"
                    [loading]="loading$ | async"
                    [disabled]="!hasFahrzeugExternalServiceReference"
                    (action)="onOpenKalkulation()"
                >
                    {{ name + '.kalkulation' | translate }}
                    <app-mat-icon icon="open_in_new" />
                </app-button>
                <ng-container
                    *ngTemplateOutlet="(this.loadingGetKalkulationData$ | async) ? spinner : kalkulationTemplate"
                />
            </app-card>
            <div id="iframeContainer"></div>
        </ng-container>
    </ng-template>
</div>

<!--side panel-->
<app-side-panel class="side-panel" *ngIf="(viewport$ | async) === viewport.Desktop">
    <h4 class="pb-4">{{ (name + '.feststellungen' | translate) + '&nbsp;(' + positionen?.controls?.length + ')&nbsp;' }}</h4>
    <ng-container *ngTemplateOutlet="feststellungen" />
</app-side-panel>

<!--bottom sheet-->
<app-bottom-sheet
    *ngIf="(viewport$ | async) !== viewport.Desktop"
    [title]="(name + '.feststellungen' | translate) + '&nbsp;(' + positionen?.controls?.length + ')&nbsp;'"
    [value]="sums$ | async"
    [seamless]="true"
>
    <ng-container *ngTemplateOutlet="feststellungen" />
</app-bottom-sheet>

<!--notizen button-->
<app-produkt-detail-notizen-button [notizen]="notizen$ | async" (notizenChange)="onNotizenChange($event)" />

<!--Templates-->
<ng-template #feststellungen let-data="data">
    <!--header-->
    <div class="header">
        <div class="group-header">
            <label>{{ name + '.' + 'obergruppe' | translate }}</label>
            <label>{{ name + '.' + 'untergruppe' | translate }}</label>
        </div>
        <ng-container *ngFor="let field of positionenFields; trackBy: trackByField">
            <ng-container [ngSwitch]="field.name || field.groupName">
                <ng-container *ngSwitchCase="'beschaedigungart'">
                    <div class="group-header">
                        <label>{{ 'Beschädigungsart' }}</label>
                        <label>{{ 'Reparaturweg' }}</label>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'bilder'">
                    <div class="group-header">
                        <label>{{ 'Bilder' }}</label>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'preis'">
                    <div class="group-header">
                        <label>{{ name + '.' + 'preis' | translate }}</label>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>

    <div
        class="drop-list"
        [class.mobile]="(viewport$ | async) !== viewport.Desktop"
        [class.disabled]="produkt.status !== statusEnum.Offen"
        (cdkDropListDropped)="drop($event)"
        cdkDropList
    >
        <app-expansion-panel-custom
            #panel
            *ngFor="let item of positionen?.getRawValue(); index as index; trackBy: trackByField"
            cdkDrag
        >
            <app-expansion-panel-header-custom>
                <ng-container *ngFor="let field of positionenFields; trackBy: trackByField">
                    <ng-container [ngSwitch]="field.name || field.groupName">
                        <ng-container *ngSwitchCase="'obergruppe'">
                            <div class="group-header">
                                <label [class.unbestaetigt]="!checkFieldBestaetigt(item)">{{ item.obergruppe }}</label>
                                <label [class.unbestaetigt]="!checkFieldBestaetigt(item)">{{ item.untergruppe }}</label>
                            </div>
                        </ng-container>

                        <ng-container *ngSwitchCase="'beschaedigungart'">
                            <div class="group-header">
                                <label [class.unbestaetigt]="!checkFieldBestaetigt(item)">
                                    {{ item.beschaedigungart }}
                                </label>
                                <label [class.unbestaetigt]="!checkFieldBestaetigt(item)">
                                    {{ item.reparaturweg ? item.reparaturweg : '-' }}
                                </label>
                            </div>
                        </ng-container>

                        <ng-container *ngSwitchCase="'bilder'">
                            <div class="group-header">
                                <label [class.unbestaetigt]="!checkFieldBestaetigt(item)">
                                    {{ item.bilder.length == 0 ? 0 : item.bilder.length }}
                                </label>
                            </div>
                        </ng-container>

                        <ng-container *ngSwitchCase="'preis'">
                            <div class="group-header align-right">
                                <label [class.unbestaetigt]="!checkFieldBestaetigt(item)">
                                    {{ (item.preis ? item.preis : '0') | number: '0.2-2' }}€
                                </label>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <app-icon-button class="button drag-button" cdkDragHandle>
                    <app-mat-icon [icon]="'drag_indicator'" />
                </app-icon-button>
            </app-expansion-panel-header-custom>
            <!--body of expansion panel-->
            <div class="button-group">
                <app-icon-button class="button edit-button" (action)="onRowOpenByIndex(index, item, panel, $event)">
                    <app-mat-icon [icon]="'edit'" />
                </app-icon-button>
                <app-icon-button class="button delete-button" (action)="onRowRemoveByIndex(index, panel, $event)">
                    <app-mat-icon class="new-style" [newStyle]="true" icon="icon-trash" />
                </app-icon-button>
            </div>
        </app-expansion-panel-custom>
    </div>

    <!--footer-->
    <div class="footer">
        <label>{{ sums$ | async }}</label>
    </div>
</ng-template>

<ng-template #kalkulationTemplate let-data="data">
    <ng-container *ngFor="let field of kalkulationFields; trackBy: trackByField">
        <ng-container [ngSwitch]="field.name">
            <ng-container *ngSwitchCase="'dossierId'">
                <app-input
                    [disabled]="true"
                    [form]="this.kalkulation"
                    [name]="field.name"
                    [displayName]="name + '.' + field.name"
                    type="text"
                />
            </ng-container>
            <ng-container *ngSwitchCase="'bezugsdatum'">
                <app-date
                    [form]="this.kalkulation"
                    [name]="field.name"
                    [displayName]="name + '.' + field.name"
                    [readonly]="true"
                    [hasDatepicker]="false"
                    [disabled]="true"
                />
            </ng-container>
            <ng-container *ngSwitchCase="'reparaturkostenNetto'">
                <ng-container *ngTemplateOutlet="textCopy; context: { field: field }" />
            </ng-container>
            <ng-container *ngSwitchCase="'reparaturkostenBrutto'">
                <ng-container *ngTemplateOutlet="textCopy; context: { field: field }" />
            </ng-container>
        </ng-container>
    </ng-container>

    <div class="download-kalkulation-container">
        <p>{{ name + '.' + 'dokument' | translate }}</p>
        <app-button
            class="download-button"
            [disabled]="!this.kalkulation?.get('dokument')?.value"
            (action)="downloadKalkulation()"
        >
            <app-mat-icon icon="picture_as_pdf" color="primary" />
        </app-button>
    </div>
</ng-template>

<ng-template #untergruppe let-data="data">
    <ng-container *ngFor="let field of positionenFields; trackBy: trackByField">
        <ng-container [ngSwitch]="field.name">
            <ng-container *ngSwitchCase="'untergruppe'">
                <app-extendable-radio-list
                    [form]="data.form"
                    [name]="field.name"
                    [displayName]="name + '.' + field.name"
                    [values]="data.values"
                    [keys]="(viewport$ | async) === viewport.Mobile ? maxColumnEntries.MOBILE : maxColumnEntries.TABLET"
                    [columnCount]="
                        (viewport$ | async) !== viewport.Mobile ? columnCountEnum.THREE : columnCountEnum.ONE
                    "
                />
            </ng-container>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #textCopy let-field="field">
    <div class="text-copy-container">
        <app-input
            #userinput
            [disabled]="true"
            [form]="this.kalkulation"
            [name]="field.name"
            [displayName]="name + '.' + field.name"
            type="text"
            inputmode="numeric"
        >
            <ng-container [class.readonly]="true" appSuffix>&nbsp;€</ng-container>
        </app-input>
        <app-button class="copy-button" (action)="copy(userinput.input.nativeElement.id)">
            <app-mat-icon icon="file_copy" />
        </app-button>
    </div>
</ng-template>

<ng-template #dialog let-data="data">
    <app-produkt-detail-schaden-position
        [form]="data.form"
        [name]="name"
        [fields]="data.fields"
        [produktArt]="data.produktArt"
        (disableTemplateButton)="changeTemplateButtonDisabled($event)"
    />
</ng-template>

<!-- Confirm dialog -->
<ng-template #dialogKalkulationConfirm>
    <mat-label>{{ 'feststellungen.kalkulation.dialog.text' | translate }}</mat-label>
</ng-template>

<ng-template #spinner>
    <app-progress-spinner />
</ng-template>
