import { Component, Signal, TemplateRef, ViewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TEXTBAUSTEINE_VERWALTUNG_CONFIG } from '@modules/textbausteine-verwalten/config/textbausteine-verwalten.config';
import { Assert } from '@shared/helper/assert';
import { Viewport, ViewportService } from '@shared/service/viewport.service';

@Component({
    selector: 'app-textbausteine-verwalten',
    templateUrl: './textbausteine-verwalten.component.html',
    styleUrls: ['./textbausteine-verwalten.component.scss'],
})
export class TextbausteineVerwaltenComponent {
    @ViewChild('dialog', { static: true })
    dialogTemplate: TemplateRef<any>;

    textbausteineConfigList = TEXTBAUSTEINE_VERWALTUNG_CONFIG;
    viewport: Signal<Viewport>;
    Viewport = Viewport;

    constructor(private readonly viewportService: ViewportService) {
        Assert.notNullOrUndefined(viewportService, 'viewportService');
        this.viewport = toSignal<Viewport>(this.viewportService.observe());
    }
}
