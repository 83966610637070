<div class="content-wrapper" *ngIf="!loggingOut; else spinner">
    <app-card>
        <app-tab-group>
            <app-tab [label]="'einstellungen.buero.title' | translate">
                <app-einstellungen-buero-form [saving]="saving$ | async" (save)="onBueroSave($event)" />
            </app-tab>
            <app-tab [label]="'einstellungen.benutzer.title' | translate">
                <app-einstellungen-benutzer-form [saving]="saving$ | async" (save)="onBenutzerSave($event)" />
            </app-tab>
        </app-tab-group>
    </app-card>
    <app-card>
        <table *ngIf="claims$ | async as claims" role="presentation" class="border-separate">
            <tr>
                <td class="header">{{ 'einstellungen.benutzer.email' | translate }}</td>
                <td>{{ claims['email'] }}</td>
            </tr>
            <tr>
                <td class="header">{{ 'einstellungen.benutzer.gtueid' | translate }}</td>
                <td>{{ claims['custom:gtueid'] }}</td>
            </tr>
            <tr>
                <td class="header">{{ 'einstellungen.benutzer.benutzerId' | translate }}</td>
                <td>{{ claims['custom:benutzerId'] }}</td>
            </tr>
            <tr>
                <td class="header">{{ 'einstellungen.benutzer.buero' | translate }}</td>
                <td>{{ claims['custom:buero_id'] }}</td>
            </tr>
        </table>
        <div align="end">
            <app-button [type]="ButtonType.SECONDARY" (action)="onLogoutClick()">
                {{ 'einstellungen.benutzer.logout' | translate }}
            </app-button>
        </div>
    </app-card>
</div>

<ng-template #spinner>
    <app-progress-spinner class="loading-spinner" [diameter]="150" />
</ng-template>
