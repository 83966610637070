import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { OldtimerLackmessungDetails, OldtimerLackmessungMessung } from '@data/domain/schema/types';
import { FormFieldArrays } from '@shared/models/form-field.model';
import { paintMeasurementSingleMeasure } from './form-paint-measurement.configuration';

export enum FormPaintMeasurementFields {
    measurements = 'messungen',
    overallBodyFrom = 'gesamtKarosserieVon',
    overallBodyTo = 'gesamtKarosserieBis',
    descriptions = 'beschreibungen',
}

export enum SinglePaintMeasureFields {
    position = 'position',
    dicke = 'dicke',
    additionalThicknesses = 'weitereDicken',
}

export type PaintMeasurements = FormArray<FormGroup>;

@Injectable({
    providedIn: 'root',
})
export class FormPaintMeasurementService {
    static getPaintMeasurementFormGroup(patchValue?: OldtimerLackmessungDetails): FormGroup {
        const measurementsData = patchValue?.messungen || [];
        const measurements = measurementsData.map((measurement) => this.getMeasurementFormGroup(measurement));

        return new FormGroup({
            [FormPaintMeasurementFields.measurements]: new FormArray(measurements || []),
            [FormPaintMeasurementFields.overallBodyFrom]: new FormControl(patchValue?.gesamtKarosserieVon),
            [FormPaintMeasurementFields.overallBodyTo]: new FormControl(patchValue?.gesamtKarosserieBis),
            [FormPaintMeasurementFields.descriptions]: new FormControl(patchValue?.beschreibungen),
        });
    }

    static getMeasurementFormGroup(patchValue?: OldtimerLackmessungMessung): FormGroup {
        const formGroup = new FormGroup({});
        const additionalThicknesses = patchValue?.weitereDicken || [];
        const additionalThicknessesFormArray = new FormArray(
            additionalThicknesses.map(
                (thickness) =>
                    new FormGroup({
                        value: new FormControl(thickness?.value),
                    }),
            ),
        );

        paintMeasurementSingleMeasure.forEach((field) => {
            if (!field.name || FormFieldArrays.includes(field.type)) {
                return;
            }

            const validators = field.validators || [];
            if (field.required) {
                validators.push(Validators.required);
            }

            const controlPatchValue = patchValue ? patchValue[field.name] : null;
            const control = new FormControl(controlPatchValue, validators);
            formGroup.addControl(field.name, control);
        });

        const positionControl = new FormControl(patchValue?.position);
        formGroup.addControl(SinglePaintMeasureFields.position, positionControl);
        formGroup.addControl(SinglePaintMeasureFields.additionalThicknesses, additionalThicknessesFormArray);
        return formGroup;
    }
}
