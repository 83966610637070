<div class="main-content">
    <div class="toggle">
        <app-toggle-group [value]="display" (valueChange)="onDisplayChange($event)">
            <app-toggle [value]="displayEnum.MODELL" [disabled]="modelDisabled$ | async">
                {{ name + '.modell' | translate }}
            </app-toggle>
            <app-toggle [value]="displayEnum.MANUELL">
                {{ name + '.manuell' | translate }}
            </app-toggle>
            <app-toggle [value]="displayEnum.GESAMT">
                {{ name + '.gesamt' | translate }}
            </app-toggle>
        </app-toggle-group>
    </div>

    <ng-container [ngSwitch]="display">
        <ng-container *ngSwitchCase="displayEnum.MODELL">
            <app-produkt-detail-lackmessung-scene
                [form]="form"
                [modelFileConfigs]="modelFileConfigs"
                (modelLoad)="onModelLoad($event)"
                (positionSelect)="onPositionSelect($event)"
            />
        </ng-container>
        <ng-container *ngSwitchCase="displayEnum.MANUELL">
            <app-card>
                <app-action-list
                    [values]="positionen"
                    [valuePrefix]="name + '.'"
                    [searchable]="true"
                    (action)="onPositionSelect($event)"
                />
            </app-card>
        </ng-container>
        <ng-container *ngSwitchCase="displayEnum.GESAMT">
            <app-card>
                <app-produkt-detail-lackmessung-gesamt
                    [name]="name"
                    [form]="form"
                    [fields]="fields"
                    [produkt]="produkt"
                />
            </app-card>
        </ng-container>
    </ng-container>
</div>

<!-- side pnael -->
<app-side-panel class="side-panel" *ngIf="(viewport$ | async) === viewport.Desktop && (rows$ | async) as rows">
    <h4 class="pb-4">{{ (name + '.messungen' | translate) + '&nbsp;(' + rows.length + ')' }}</h4>
    <ng-container *ngTemplateOutlet="lackmessungMessungen; context: { rows: rows }" />
</app-side-panel>

<!-- bottom sheet -->
<ng-container *ngIf="(viewport$ | async) !== viewport.Desktop && (rows$ | async) as rows">
    <app-bottom-sheet [title]="(name + '.messungen' | translate) + '&nbsp;(' + rows.length + ')'" [seamless]="true">
        <ng-container *ngTemplateOutlet="lackmessungMessungen; context: { rows: rows }" />
    </app-bottom-sheet>
</ng-container>

<app-produkt-detail-notizen-button [notizen]="notizen$ | async" (notizenChange)="onNotizenChange($event)" />

<!-- templates -->
<ng-template #lackmessungMessungen let-rows="rows">
    <div class="header">
        <ng-container *ngFor="let field of messungenFields; trackBy: trackByField">
            <ng-container [ngSwitch]="field.name">
                <ng-container *ngSwitchCase="'position'">
                    <div class="group-header">
                        <label>
                            <strong>{{ name + '.' + field.name | translate }}</strong>
                        </label>
                        <label>{{ name + '.' + 'bilder' | translate }}</label>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'dicke'">
                    <div class="group-header">
                        <label>
                            <strong>{{ name + '.' + field.name | translate }}</strong>
                        </label>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'ergebnis'">
                    <div class="group-header">
                        <label>
                            <strong>{{ name + '.' + field.name | translate }}</strong>
                        </label>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'beschreibung'">
                    <div class="group-header">
                        <label>
                            <strong>{{ name + '.' + field.name | translate }}</strong>
                        </label>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>

    <app-drop-list [disabled]="produkt.status !== statusEnum.Offen" (cdkDropListDropped)="drop($event)" cdkDropList>
        <app-expansion-panel-custom
            #panel
            *ngFor="let item of messungen.getRawValue(); index as index; trackBy: trackByField"
            cdkDrag
        >
            <app-expansion-panel-header-custom>
                <div class="panel-header">
                    <ng-container *ngFor="let field of messungenFields; trackBy: trackByField">
                        <ng-container [ngSwitch]="field.name">
                            <ng-container *ngSwitchCase="'position'">
                                <div class="group-header">
                                    <label>
                                        <strong>
                                            {{
                                                (this.name + '.' + positionen.values[item.position]).toLowerCase()
                                                    | translate
                                            }}
                                        </strong>
                                    </label>
                                    <label>{{ item.bilder.length }}</label>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'dicke'">
                                <div class="group-header">
                                    <label>
                                        <strong>{{ item.dicke }}</strong>
                                    </label>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'ergebnis'">
                                <div class="group-header">
                                    <label class="no-wrap">
                                        <strong>{{ item.ergebnis }}</strong>
                                    </label>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'beschreibung'">
                                <div class="group-header">
                                    <label>
                                        <strong>{{ item.beschreibung | abbreviateText: 30 }}</strong>
                                    </label>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </app-expansion-panel-header-custom>
            <!--body of expansion panel-->
            <div class="button-group">
                <app-button class="button edit-button" (action)="onRowOpenByIndex(index, item, panel, $event)">
                    <mat-icon>edit</mat-icon>
                </app-button>
                <app-button class="button delete-button" (action)="onRowRemoveByIndex(index, panel, $event)">
                    <app-mat-icon class="new-style" [newStyle]="true" icon="icon-trash" />
                </app-button>
            </div>
        </app-expansion-panel-custom>
    </app-drop-list>
</ng-template>

<!-- dialog -->
<ng-template #dialog let-data="data">
    <app-produkt-detail-lackmessung-messung
        [form]="data.form"
        [name]="name"
        [fields]="data.fields"
        [produkt]="produkt"
    />
</ng-template>
