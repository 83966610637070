import { Type } from '@angular/core';
import { ValidatorFn } from '@angular/forms';
import { CompletionApi, CompletionResponse } from '@data/api-gateway/schema/completion-api.model';
import { ProduktArt } from '@data/domain/schema/enum';
import { DateStartView } from '@shared/standalone/form-fields/form-field-date-time/form-field-date-time.component';

export enum FormField {
    Input = 'input',
    Textarea = 'textarea',
    Select = 'select',
    Date = 'date',
    DateRange = 'dateRange',
    Label = 'label',
    TextModule = 'textModule',
    Array = 'array',
    List = 'list',
    Toggle = 'toggle',
    DenseArray = 'denseArray',
    Space = 'space',
    Wheels = 'wheels',
    ButtonGroupTabs = 'buttonGroupTabs',
    RadioGroup = 'radioGroup',
    Vin = 'vin',
    ImageUpload = 'imageUpload',
    StatusReport = 'statusReport',
    PaintMeasurement = 'paintMeasurement',
    Overview = 'overview',
}

export const FormFieldArrays = [FormField.Array, FormField.DenseArray];

export interface FormConfig {
    tabs: ConfigTab[];
}

export interface ConfigTab {
    label: string;
    name: string;
    icon?: string;
    route: string;
    isCard?: boolean;
    skipInput?: boolean;
    sections: ConfigSection[];
    layoutStyles?: string;
    legend?: Legend;
}

export interface Legend {
    entries: string[];
}

export interface ConfigSection {
    isCard: boolean;
    styles?: string;
    innerStyles?: string;
    name?: string;
    fields: ConfigField[];
}

export type ConfigField =
    | ConfigFieldBase
    | LabelField
    | InputField
    | TextareaField
    | SelectField
    | TextModuleField
    | ListField
    | ArrayField
    | WheelsField
    | DateField
    | DateRangeField
    | ButtonGroupTabsField
    | RadioGroupField
    | VinField
    | OverviewField
    | StatusReportField;

export interface ConfigFieldBase {
    name?: string;
    required?: boolean;
    defaultValue?: () => string;
    type: FormField;
    label?: string;
    placeholder?: string;
    validators?: ValidatorFn[];
    style?: string;
    updateOn?: 'blur' | 'change' | 'submit';
}

export interface LabelField extends ConfigFieldBase {
    type: FormField.Label;
    labelStyles?: string;
}

export interface InputField extends ConfigFieldBase {
    type: FormField.Input;
    inputType?: InputType;
    suffix?: string;
    inputStyle?: string;
    maxLength?: number;
    regex?: string;
    pipes?: InputPipes;
}

export interface InputPipes {
    decimalPipe?: boolean;
}

export interface TextareaField extends ConfigFieldBase {
    type: FormField.Textarea;
    rows?: number;
    maxLength?: number;
}

export interface DateField extends ConfigFieldBase {
    type: FormField.Date;
    showTime?: boolean;
    isDayDisabled?: boolean;
    startView?: DateStartView;
}

export interface DateRangeField extends ConfigFieldBase {
    type: FormField.DateRange;
    placeholderEnd?: string;
}

export interface SelectField extends ConfigFieldBase {
    type: FormField.Select;
    options: SelectFieldOption[];
}

export interface SelectFieldOption {
    label: string;
    value: string | number;
    dependency?: { path: string; values: string[] };
}

export interface TextModuleField extends ConfigFieldBase {
    settings: TextModuleSettings;
}

export interface TextModuleSettings {
    feature: string;
    feld: string;
    produktArt: ProduktArt;
    dialogTitle?: string;
}

export interface ArrayField extends ConfigFieldBase {
    type: FormField.Array | FormField.DenseArray;
    isCard?: boolean;
    fields: ConfigField[];
    settings: {
        emptyMessage: string;
        newItemLabel: string;
        isDescending?: boolean;
        previewTemplate?: PreviewTemplate[];
        hideLabel?: boolean;
        style?: string;
        minItems?: number;
        maxItems?: number;
    };
    completionApi?: CompletionApiConfig;
}

export interface PreviewTemplate {
    key: string;
    type?: FormField;
    format?: string;
    separator?: string;
    translationPrefix?: string;
}

export interface CompletionApiConfig {
    service: Type<CompletionApi<CompletionResponse>>;
    previewTemplate: PreviewTemplate[];
    label: string;
    placeholder?: string;
    minInputLength: number;
}

export interface ListField extends ConfigFieldBase {
    type: FormField.List;
    feature: string;
    feld: string;
}

export enum InputType {
    Text = 'text',
    Password = 'password',
    Number = 'number',
}

export interface ImageItem {
    images?: ReversibleImage[];
    label?: string;
}

export interface ListItem extends ImageItem {
    label: string;
    textbausteinId?: string;
}

export interface ReversibleImage {
    fileId?: string;
    originalFileId?: string;
}

export interface WheelsField extends ConfigFieldBase {
    type: FormField.Wheels;
}

export interface ButtonGroupTabsField extends ConfigFieldBase {
    type: FormField.ButtonGroupTabs;
    tabs: ConfigTab[];
    skipInput?: boolean;
}

export interface PaintMeasurementField extends ConfigFieldBase {
    type: FormField.PaintMeasurement;
    fields: ConfigField[];
}

export interface RadioGroupField extends ConfigFieldBase {
    type: FormField.RadioGroup;
    options: SelectFieldOption[];
}

export interface VinField extends ConfigFieldBase {
    type: FormField.Vin;
    placeholder?: string;
    enableVinSearch?: boolean;
}

export interface StatusReportField extends ConfigFieldBase {
    type: FormField.StatusReport;
    title: {
        formControlName: string;
        translationPrefix: string;
        placeholder: string;
    };
    options: StatusReportOption[];
}

export interface StatusReportOption {
    name: string;
    label: string;
    textModule: TextModuleSettings;
}

export interface OverviewField extends ConfigFieldBase {
    type: FormField.Overview;
    textModuleSettings: TextModuleSettings;
    instructionPdf?: {
        path: string;
        fileName: string;
    };
}
