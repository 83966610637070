import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { guid } from '@app/function/guid';
import { AuthService } from '@app/service/auth.service';
import { AnhangId } from '@data/domain/schema/enum';
import { Produkt } from '@data/domain/schema/type';
import { ProduktAbrechnungService } from '@data/domain/service/feature/produkt-abrechnung.service';
import { ProduktAnhaengeService } from '@data/domain/service/feature/produkt-anhaenge.service';
import { ProduktKalkulationService } from '@data/domain/service/feature/produkt-kalkulation.service';
import { environment } from '@env/environment';
import { DatCalculateProService } from '@modules/produkt/service/dat-calculate-pro-service';
import { Assert } from '@shared/helper/assert';
import { CurrencyFormatterService } from '@shared/service/form-controls/currency-formatter.service';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { finalize, first, map, take } from 'rxjs/operators';
import { ExternalDataServiceResponseBase } from '../schema/external-data-service';
import { KalkulatorischeFaktoren } from './einstellungen.service';

export interface DatKalkulationDto {
    dossierId: number;
    bezugsdatum: string;
    reparaturkostenNetto: number;
    reparaturkostenBrutto: number;
    dokument: string;
}

export interface DatKalkulationPostRequest {
    produktId: string;
    produktArt: number;
    dossierId?: number;
    vtiAuftragsnummer: string;
    identnummer: string;
    kennzeichen?: string;
    laufleistung?: number;
    erstzulassung?: string;
    datVxsVehicle: string; // fahrzeugExternalServiceReference -> vxsData
    kalkulatorischeFaktoren: KalkulatorischeFaktoren;
}

export interface DatKalkulationGetResponse extends ExternalDataServiceResponseBase {
    kalkulation?: DatKalkulationDto;
    message?: string;
}

export interface DatPageConfig {
    firstPage: DatFirstPage;
    currentPage: DatFirstPage;
}

export interface KalkulationIframeParamOptions {
    documentInput: Document;
    sphinx: any;
    datPageConfig: DatPageConfig;
    loading$: BehaviorSubject<boolean>;
    produkt: Produkt;
    form: UntypedFormGroup;
    openInTab: boolean;
    kalkulatorischeFaktoren?: KalkulatorischeFaktoren;
}

export enum DatProduktVariant {
    vehicleSelection = 'vehicleSelection',
    calculateExpert = 'calculateExpert',
}

export enum KalkulationStatus {
    NONE = 'NONE',
    STARTED = 'STARTED',
    SUCCEEDED = 'SUCCEEDED',
    FINISHED = 'FINISHED',
}

export enum DatFirstPage {
    MODEL = 'model',
    EQUIPMENT_PAGE = 'equipmentPage',
    GRAPHICAL_SELECTION_PAGE = 'graphicSelectionPage',
    ACTIVITY_RELATED = 'activityRelated',
    CALCULATION_RESULT = 'calculationResult',
    PRINT_AND_SEND = 'printAndSend',
}

export enum DatLastPage {
    CALCULATION_RESULT = 'calculationResult',
    PRINT_AND_SEND = 'printAndSend',
    GRAPHICAL_SELECTION_PAGE = 'graphicSelectionPage',
}

declare let DatTokenInformation: any;

@Injectable({
    providedIn: 'root',
})
export class DatKalkulationService {
    private readonly restApiUrl: string;
    private kalkulationStatus = new BehaviorSubject(KalkulationStatus.NONE);
    private isKalkulationActive = new BehaviorSubject(false);

    constructor(
        readonly produktKalkulationService: ProduktKalkulationService,
        readonly produktAnhaengeService: ProduktAnhaengeService,
        private readonly currencyFormatter: CurrencyFormatterService,
        private readonly authService: AuthService,
        private readonly datCalculateProService: DatCalculateProService,
        private readonly produktAbrechnungService: ProduktAbrechnungService,
        private readonly snackBarService: SnackBarService,
        private readonly httpClient: HttpClient,
    ) {
        Assert.notNullOrUndefined(produktKalkulationService, 'produktKalkulationService');
        Assert.notNullOrUndefined(produktAnhaengeService, 'produktAnhaengeService');
        Assert.notNullOrUndefined(authService, 'authService');
        Assert.notNullOrUndefined(datCalculateProService, 'datCalculateProService');
        Assert.notNullOrUndefined(produktAbrechnungService, 'produktAbrechnungService');
        Assert.notNullOrUndefined(snackBarService, 'snackBarService');
        Assert.notNullOrUndefined(httpClient, 'httpClient');
        this.restApiUrl = `${environment.aws.apiGateway.url}/dat/kalkulation`;

        this.authService
            .getClaims()
            .pipe(take(1))
            .subscribe({
                next: (claims) => {
                    if (claims?.istKalkulation) {
                        this.isKalkulationActive.next(true);
                    } else {
                        console.error('Could not get user claims.');
                    }
                },
                error: (err) => {
                    console.error('this.authService.getClaims()', err);
                },
            });
    }

    getIsKalkulationActive(): BehaviorSubject<boolean> {
        return this.isKalkulationActive;
    }

    get(dossierId: number, produktId: string): Observable<DatKalkulationGetResponse> {
        Assert.notNullOrUndefined(dossierId, 'dossierId');
        Assert.notNullOrUndefined(produktId, 'produktId');
        const params = {
            params: {
                dossierId: dossierId.toString(),
                produktId,
            },
        };
        return this.httpClient.get<DatKalkulationGetResponse>(this.restApiUrl, params);
    }

    post(request: DatKalkulationPostRequest): Observable<number> {
        Assert.notNullOrUndefined(request, 'request');
        return this.httpClient.post<number>(this.restApiUrl, request);
    }

    setupSphinx(token: any, dossierId: number, sphinx: any, firstPage: string, currentPage: string): void {
        const values = {
            az: dossierId,
            displayHeader: true,
            showProcessMenu: true,
            defaultReadyHandler: true,
            urlReadyHandler: `${window.location.href}/kalkulation/${dossierId}`,
            tires: false,
            hidePrintIcon: true,
        };

        sphinx.onError = function (response) {
            alert(`DAT login failed!\nReason: ${response.message}`);
        };

        sphinx.hostUrl = window.location.href;
        sphinx.credentials = {
            token,
        };

        sphinx.productVariant = DatProduktVariant.calculateExpert;
        sphinx.firstPage = firstPage;
        sphinx.lastPage = DatLastPage.CALCULATION_RESULT;
        sphinx.host = 'https://www.dat.de/VehicleRepairOnline';
        const loginInfo = sphinx.encryptPassword(new DatTokenInformation(token));
        const DAF = sphinx.getDAFXml(values);
        const callBackFunction = function (_object, _xml) {
            // This is intentional
        };
        sphinx.init(
            `${sphinx.host}/vehicleRepair/graphicalPartSelectionPage.tmpl`,
            currentPage,
            document.getElementById('iframeContainer'),
            'modelIFrame',
            null,
            callBackFunction,
        );
        sphinx.execute(loginInfo, DAF, function (e) {
            alert(e);
        });
    }

    initIframe(options: KalkulationIframeParamOptions): void {
        this.datCalculateProService
            .getToken()
            .pipe(first())
            .subscribe((token) => {
                if (token) {
                    if (options.produkt.kalkulation?.dossierId) {
                        this.setupSphinx(
                            token,
                            options.produkt.kalkulation?.dossierId,
                            options.sphinx,
                            options.datPageConfig.firstPage,
                            options.datPageConfig.currentPage,
                        );
                        if (options.openInTab) {
                            this.openIframeInTab(options.documentInput);
                        }
                    } else {
                        options.loading$.next(true);
                        this.createDossier(options.produkt, options.kalkulatorischeFaktoren)
                            .pipe(take(1))
                            .subscribe({
                                next: (dossierId) => {
                                    this.setupSphinx(
                                        token,
                                        dossierId,
                                        options.sphinx,
                                        options.datPageConfig.firstPage,
                                        options.datPageConfig.currentPage,
                                    );
                                    options.produkt.kalkulation.dossierId = dossierId;
                                    options.form.patchValue({ dossierId });
                                    this.produktKalkulationService.save(options.produkt.id, {
                                        id: options.produkt.id,
                                        dossierId,
                                    });
                                    const kalkulationAbrechnung =
                                        this.produktAbrechnungService.createAbrechnungKalkulation(
                                            options.produkt.id,
                                            dossierId.toString(),
                                        );
                                    this.produktAbrechnungService.saveAbrechnung(
                                        options.produkt.id,
                                        guid(),
                                        kalkulationAbrechnung,
                                    );
                                    options.loading$.next(false);
                                    if (options.openInTab) {
                                        this.openIframeInTab(options.documentInput);
                                    }
                                },
                                error: () => {
                                    options.loading$.next(false);
                                    this.snackBarService.error('Fehler beim erstellen des Dossiers');
                                },
                            });
                    }
                } else {
                    this.snackBarService.error('Fehler bei der Anmeldung bei DAT. Token konnte nicht erstellt werden.');
                }
            });
    }

    getKalkulationStatus(): BehaviorSubject<KalkulationStatus> {
        return this.kalkulationStatus;
    }

    addKalkulationStorageListener(produktId: string): void {
        localStorage.setItem(`kalkulation-${produktId}`, KalkulationStatus.STARTED);
        const that = this;
        window.addEventListener('storage', (event) => this.onKalkulationStatusChanged(event, that));
    }

    setKalkulationStatus(produktId: string, kalkulationStatus: KalkulationStatus): void {
        if (this.kalkulationStatus.value !== kalkulationStatus) {
            this.kalkulationStatus.next(kalkulationStatus);
            localStorage.setItem(`kalkulation-${produktId}`, kalkulationStatus);
        }
    }

    removeKalkulationStorageListener(produktId: string): void {
        localStorage.setItem(`kalkulation-${produktId}`, KalkulationStatus.FINISHED);
        const that = this;
        window.removeEventListener('storage', (event) =>
            ((service) => {
                switch (event.newValue) {
                    case KalkulationStatus.NONE:
                        service.kalkulationStatus.next(KalkulationStatus.NONE);
                        break;
                    case KalkulationStatus.STARTED:
                        service.kalkulationStatus.next(KalkulationStatus.STARTED);
                        break;
                    case KalkulationStatus.SUCCEEDED:
                        service.kalkulationStatus.next(KalkulationStatus.SUCCEEDED);
                        break;
                    case KalkulationStatus.FINISHED:
                        service.kalkulationStatus.next(KalkulationStatus.FINISHED);
                        break;
                }
            })(that),
        );
    }

    getKalkulationStatusSubscription(
        produkt: Produkt,
        kalkulationForm: UntypedFormGroup,
        loading$: BehaviorSubject<boolean>,
    ): Subscription {
        return this.getKalkulationStatus()
            .pipe(
                map((value) => {
                    if (value === KalkulationStatus.SUCCEEDED) {
                        loading$?.next(true);
                        this.setKalkulationStatus(produkt.id, KalkulationStatus.FINISHED);
                        this.get(produkt.kalkulation.dossierId, produkt.id)
                            .pipe(take(1))
                            .subscribe((response) => {
                                kalkulationForm.patchValue(
                                    this.getKalkulationPatchValue(produkt, response.kalkulation),
                                );
                                this.produktAnhaengeService.saveAnhang(produkt.id, `${produkt.id}/kalkulation`, {
                                    id: AnhangId.Kalkulation,
                                    bezeichnung: 'kalkulation.pdf',
                                    quelle: `${produkt.id}/kalkulation`,
                                });
                                this.produktKalkulationService.save(
                                    produkt.id,
                                    this.getKalkulationSaveMap(produkt, response.kalkulation),
                                );
                                loading$?.next(false);
                            });
                    }
                }),
                finalize(() => loading$?.next(false)),
            )
            .subscribe();
    }

    createDossier(produkt: Produkt, kalkulatorischeFaktoren?: KalkulatorischeFaktoren): Observable<number> {
        Assert.notNullOrUndefined(produkt, 'produkt');
        return this.post(this.getDatKalkulationPostRequest(produkt, kalkulatorischeFaktoren)).pipe(take(1));
    }

    private getKalkulationPatchValue(produkt: Produkt, response: DatKalkulationDto): any {
        return {
            bezugsdatum: response?.bezugsdatum,
            dossierId: response?.dossierId,
            reparaturkostenNetto: this.currencyFormatter.format(response?.reparaturkostenNetto),
            reparaturkostenBrutto: this.currencyFormatter.format(response?.reparaturkostenBrutto),
            dokument: `${produkt.id}/kalkulation`,
        };
    }

    private getKalkulationSaveMap(produkt: Produkt, response: DatKalkulationDto): any {
        return {
            id: produkt.id,
            bezugsdatum: response.bezugsdatum,
            dossierId: response.dossierId,
            reparaturkostenNetto: response.reparaturkostenNetto,
            reparaturkostenBrutto: response.reparaturkostenBrutto,
            dokument: `${produkt.id}/kalkulation`,
        };
    }

    private getDatKalkulationPostRequest(
        produkt: Produkt,
        kalkulatorischeFaktoren?: KalkulatorischeFaktoren,
    ): DatKalkulationPostRequest {
        return {
            produktId: produkt.id,
            produktArt: produkt.art,
            vtiAuftragsnummer: produkt.auftrag?.vorgangsnummer,
            identnummer: produkt.fahrzeug?.identnummer,
            kennzeichen: produkt.fahrzeug?.kennzeichen,
            laufleistung: produkt.fahrzeug?.laufleistung,
            erstzulassung: produkt.fahrzeug?.erstzulassung,
            datVxsVehicle: produkt.fahrzeug?.fahrzeugExternalServiceReference?.vxsData,
            kalkulatorischeFaktoren: kalkulatorischeFaktoren || null,
        };
    }

    private openIframeInTab(documentInput: Document): void {
        setTimeout(() => {
            const iframe = documentInput.getElementById('sphinx.iFrame') as HTMLIFrameElement;
            iframe.setAttribute('sandbox', 'allow-scripts');

            const tab = window.open(iframe.src, '_blank');
            if (tab) tab.focus();
        }, 500);
    }

    private onKalkulationStatusChanged(storageEvent: StorageEvent, that: DatKalkulationService): void {
        switch (storageEvent.newValue) {
            case KalkulationStatus.NONE:
                that.kalkulationStatus.next(KalkulationStatus.NONE);
                break;
            case KalkulationStatus.STARTED:
                that.kalkulationStatus.next(KalkulationStatus.STARTED);
                break;
            case KalkulationStatus.SUCCEEDED:
                that.kalkulationStatus.next(KalkulationStatus.SUCCEEDED);
                break;
            case KalkulationStatus.FINISHED:
                that.kalkulationStatus.next(KalkulationStatus.FINISHED);
                break;
        }
    }
}
