import { Injectable } from '@angular/core';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { GraphQLResponse, saveVtiWerte, SaveVtiWerteData } from '../../graphql/mutations';
import { Produkt, VtiWerte, VtiWerteInput } from '../../schema/type';
import { ProduktService } from '../produkt.service';
import { ProduktFeatureService } from './produkt-feature.service';

@Injectable({
    providedIn: 'root',
})
export class ProduktVtiWerteService extends ProduktFeatureService<VtiWerte, VtiWerteInput> {
    constructor(awsAppSyncClientProvider: AwsAppSyncClientProvider, produktService: ProduktService) {
        super(awsAppSyncClientProvider, produktService);
    }

    protected mapSaveInput(produktId: string, feature: VtiWerte): VtiWerteInput {
        const input: VtiWerteInput = {
            ...feature,
            id: produktId,
        };
        return input;
    }

    protected getSaveMutation(): any {
        return saveVtiWerte;
    }

    protected getSaveVariables(input: VtiWerteInput): any {
        return {
            vtiWerte: input,
        };
    }

    protected getSaveOptimisticResponse(input: VtiWerteInput): any {
        return {
            saveVtiWerte: {
                ...input,
                __typename: 'VtiWerte',
                wertAmMarktBemerkungen: (input.wertAmMarktBemerkungen || []).map((bemerkung) => ({
                    ...bemerkung,
                    __typename: 'Textbaustein',
                })),
            },
        };
    }

    protected getSaveResponse(response: GraphQLResponse<SaveVtiWerteData>): VtiWerte {
        return response.data.saveVtiWerte;
    }

    protected update(produkt: Produkt, feature: VtiWerte): void {
        produkt.vtiWerte = {
            ...produkt.vtiWerte,
            ...feature,
        };
    }
}
