import { OLDTIMER_NOTIZ_FIELDS } from '@data/domain/fragments/notiz.fragment';
import { TEXTBAUSTEIN_FIELDS } from '@data/domain/fragments/textbaustein.fragment';
import gql from 'graphql-tag';

export const getOldtimerProduktQuery = gql`
    query OldtimerProdukt($id: ID!) {
        oldtimerProdukt(id: $id) {
            id
            art
            idParent
            status
            erstelltAm
            abgeschlossenAm
            abgeschlossenVon
            anzahlBilder
            auftrag {
                auftragsbeschreibung {
                    ...TextbausteinFields
                }
                auftragsnummer
                vorgangsnummer
                auftragsdatum
                fertigstellungsdatum
                wiederbeschaffungswert
                besichtigungen {
                    besichtigungsdatum
                    besichtigtDurch
                    strasseHausnummer
                    postleitzahl
                    ort
                    halterAnwesend
                    anwesende {
                        name
                    }
                    besichtigungsbemerkungen {
                        ...TextbausteinFields
                    }
                    besichtigungsbedingungen {
                        ...TextbausteinFields
                    }
                }
            }
            unterlagen {
                positionen {
                    label
                    textbausteinId
                    images {
                        fileId
                        originalFileId
                    }
                }
            }
            raeder {
                raederDetails {
                    radSaetze {
                        achsen {
                            seiten {
                                raeder {
                                    art
                                    bemerkungen {
                                        ...TextbausteinFields
                                    }
                                    dot
                                    durchmesser
                                    felgenhersteller
                                    felgentyp
                                    messmittel
                                    ort
                                    profiltiefe
                                    reifenbreite
                                    reifenhersteller
                                    reifentyp
                                    querschnitt
                                    runflat
                                    tragfaehigkeit
                                    vmax
                                    zustand
                                }
                            }
                        }
                    }
                    sonstiges {
                        bemerkungen {
                            ...TextbausteinFields
                        }
                        profiltiefe
                        notlaufeinrichtung
                    }
                }
            }
            adressen {
                adressenListe {
                    adressarten {
                        ...TextbausteinFields
                    }
                    anrede
                    email
                    externeKennung
                    firma
                    mobil
                    name
                    ort
                    postleitzahl
                    strasseNr
                    telefon
                    vorname
                }
            }
            fahrzeug {
                erstzulassung
                erstzulassungBemerkung
                kennzeichen
                kennzeichenArt
                zugelassen
                huAblaufdatum
                paragraph23Stvzo
                identnummer
                fahrzeugart
                fahrzeugartBemerkung
                bauform
                bauformBemerkung
                hersteller
                modell
                anzahlVorbesitzer
                serviceHistory
                laufleistungen {
                    laufleistung
                    laufleistungEinheit
                    laufleistungBeschreibung
                    laufleistungBemerkung
                }
                lenkung
                anzahlAchsen
                anzahlSitze
                anzahlTueren
                antriebsVariante
                antriebsVarianteBemerkung {
                    ...TextbausteinFields
                }
                energieQuelle
                energieQuelleBemerkung {
                    ...TextbausteinFields
                }
                anzahlGaenge
                getriebeArt
                motorArt
                motorArtBemerkung
                motorArbeitsverfahren
                anzahlZylinder
                motorBauform
                motorBauformBemerkung
                hubraum
                hubraumBemerkung {
                    ...TextbausteinFields
                }
                leistung
                leistungBemerkung {
                    ...TextbausteinFields
                }
                leergewicht
                zulaessigesGesamtgewicht
                lackierung
                folierung
                lackfarbe
            }
            historie {
                produktionsnummern {
                    getriebe
                    getriebeBilder {
                        fileId
                        originalFileId
                    }
                    hinterachse
                    hinterachseBilder {
                        fileId
                        originalFileId
                    }
                    motor
                    motorBilder {
                        fileId
                        originalFileId
                    }
                    rahmen
                    rahmenBilder {
                        fileId
                        originalFileId
                    }
                    bemerkung
                }
                nutzungsart {
                    nutzungsart
                    zeitraum {
                        from
                        to
                    }
                    bemerkung
                }
                reparatur {
                    reparaturart
                    datum
                    laufleistung
                    laufleistungEinheit
                    zustandsbericht {
                        untergruppe {
                            ...TextbausteinFields
                        }
                        beschaedigung {
                            ...TextbausteinFields
                        }
                        reparatur {
                            ...TextbausteinFields
                        }
                    }
                }
                wartung {
                    wartungsart
                    datum
                    laufleistung
                    laufleistungEinheit
                    durchgefuehrtVon
                }
                zulassung {
                    grund
                    zeitraum {
                        from
                        to
                    }
                    bemerkung
                }
            }
            lackmessung {
                lackmessungDetails {
                    beschreibungen {
                        ...TextbausteinFields
                    }
                    gesamtKarosserieBis
                    gesamtKarosserieVon
                    messungen {
                        position
                        dicke
                        weitereDicken {
                            value
                        }
                        beschreibung
                        bilder {
                            fileId
                            originalFileId
                        }
                        bemerkungen {
                            ...TextbausteinFields
                        }
                    }
                }
            }
            uebersicht {
                kategorien {
                    textbaustein {
                        ...TextbausteinFields
                    }
                    bilder {
                        datei {
                            fileId
                            originalFileId
                        }
                        bildbeschreibung
                    }
                    isImagesLimited
                    isExpanded
                }
            }
            notizen {
                ...OldtimerNotizFields
            }
        }
    }

    ${OLDTIMER_NOTIZ_FIELDS}
    ${TEXTBAUSTEIN_FIELDS}
`;
