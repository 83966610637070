<span>{{ feature() }} - {{ feldDisplayName() }}</span>

<app-input [form]="data().form" [name]="'kurztext'" [displayName]="'textbausteine.kurztext'" [disabled]="readonly()" type="text" />
<ng-container [formGroup]="data().form">
    <gtuema-max-character-textfield
        class="drucktext"
        [labelText]="'Drucktext'"
        [formControlName]="'langtext'"
        [inputHeight]="'40vh'"
        maxLength="4000"
    />
</ng-container>

@if (this.mode() === EditMode.Verwaltung) {
  <label>{{ 'textbausteine.standard.label' | translate }}</label>
  <app-slide-toggle [form]="data().form" [name]="'standard'" [displayName]="'textbausteine.standard.name'" />
}