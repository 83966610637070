import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { guid } from '@app/function/guid';
import { ProduktArt, WartungEinheit, WartungEinheitZeit } from '@data/domain/schema/enum';
import { Wartung } from '@data/domain/schema/type';
import { FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { CurrencyFormatterService } from '@shared/service/form-controls/currency-formatter.service';
import { ThousandsSeperatorFormatterService } from '@shared/service/form-controls/thousands-seperator-formatter.service';
import { FormViewModelBaseFactory } from '../form-view-base.factory';
import { ProduktDetailTextbausteinFormViewFactory } from '../produkt-detail-textbaustein-form-view.factory';
import { ProduktDetailWartungPositionFormViewFactory } from './produkt-detail-wartung-position-form-view.factory';

@Injectable({
    providedIn: 'root',
})
export class ProduktDetailWartungFormViewFactory extends FormViewModelBaseFactory<Wartung> {
    constructor(
        private readonly positionFormViewFactory: ProduktDetailWartungPositionFormViewFactory,
        private readonly thousandsSeperatorFormatterService: ThousandsSeperatorFormatterService,
        private readonly currencyFormatterService: CurrencyFormatterService,
        private readonly produktDetailTextbausteinFormViewFactory: ProduktDetailTextbausteinFormViewFactory,
    ) {
        super();
        Assert.notNullOrUndefined(positionFormViewFactory, 'positionFormViewFactory');
        Assert.notNullOrUndefined(thousandsSeperatorFormatterService, 'thousandsSeperatorFormatterService');
        Assert.notNullOrUndefined(currencyFormatterService, 'currencyFormatterService');
    }

    protected createField(model: Wartung, name: string, produktArt: ProduktArt): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.Wartung.fields;
        switch (name) {
            case fields.LetzterServiceNichtBekannt.name:
                return new ViewFormControl(model.letzterServiceNichtBekannt);
            case fields.LetzterServiceBei.name:
                return new ViewFormControl(model.letzterServiceBei, {
                    formatter: this.thousandsSeperatorFormatterService,
                });
            case fields.LetzterServiceFaelligEinheit.name:
                return new ViewFormControl(
                    model.letzterServiceFaelligEinheit === null
                        ? WartungEinheit.Km
                        : model.letzterServiceFaelligEinheit,
                );
            case fields.LetzterServiceAm.name:
                return new ViewFormControl(model.letzterServiceAm);
            case fields.LetzterServiceAmDayHidden.name:
                return new ViewFormControl(model.letzterServiceAmDayHidden || false, {
                    updateOn: 'change',
                });
            case fields.NaechsterServiceFaelligNichtBekannt.name:
                return new ViewFormControl(model.naechsterServiceFaelligNichtBekannt);
            case fields.NaechsterServiceFaelligIn.name:
                return new ViewFormControl(model.naechsterServiceFaelligIn, {
                    formatter: this.thousandsSeperatorFormatterService,
                });
            case fields.NaechsterServiceFaelligEinheit.name:
                return new ViewFormControl(
                    model.naechsterServiceFaelligEinheit === null
                        ? WartungEinheit.Km
                        : model.naechsterServiceFaelligEinheit,
                );
            case fields.NaechsterServiceFaelligInZeit.name:
                return new ViewFormControl(model.naechsterServiceFaelligInZeit, {
                    formatter: this.thousandsSeperatorFormatterService,
                });
            case fields.NaechsterServiceFaelligEinheitZeit.name:
                return new ViewFormControl(
                    model.naechsterServiceFaelligEinheitZeit === null
                        ? WartungEinheitZeit.Tage
                        : model.naechsterServiceFaelligEinheitZeit,
                );
            case fields.NaechsterServiceFaelligAm.name:
                return new ViewFormControl(model.naechsterServiceFaelligAm);
            case fields.NaechsterServiceFaelligAmDayHidden.name:
                return new ViewFormControl(model.naechsterServiceFaelligAmDayHidden || false, {
                    updateOn: 'change',
                });
            case fields.NaechsterServiceFaelligKosten.name:
                return new ViewFormControl(model.naechsterServiceFaelligKosten, {
                    formatter: this.currencyFormatterService,
                });
            case fields.NaechsterServiceFaelligExternalId.name:
                return new ViewFormControl(model.naechsterServiceFaelligExternalId || guid());
            case fields.NaechsteHauptAbgasUntersuchungNichtBekannt.name:
                return new ViewFormControl(model.naechsteHauptAbgasUntersuchungNichtBekannt);
            case fields.NaechsteHauptAbgasUntersuchung.name:
                if (produktArt === ProduktArt.AlphaController) {
                    return new ViewFormControl(model.naechsteHauptAbgasUntersuchung, {
                        validators: Validators.required,
                    });
                } else {
                    return new ViewFormControl(model.naechsteHauptAbgasUntersuchung);
                }
            case fields.HauptAbgasUntersuchungFaelligKosten.name:
                return new ViewFormControl(model.hauptAbgasUntersuchungFaelligKosten, {
                    formatter: this.currencyFormatterService,
                });
            case fields.NaechsteHauptAbgasUntersuchungExternalId.name:
                return new ViewFormControl(model.naechsteHauptAbgasUntersuchungExternalId || guid());
            case fields.ZahnriemenwechselBei.name:
                return new ViewFormControl(model.zahnriemenwechselBei, {
                    formatter: this.thousandsSeperatorFormatterService,
                });
            case fields.ZahnriemenwechselFaelligEinheit.name:
                return new ViewFormControl(
                    model.zahnriemenwechselFaelligEinheit === null
                        ? WartungEinheit.Km
                        : model.zahnriemenwechselFaelligEinheit,
                );
            case fields.ZahnriemenwechselAm.name:
                return new ViewFormControl(model.zahnriemenwechselAm);
            case fields.ZahnriemenwechselAmDayHidden.name:
                return new ViewFormControl(model.zahnriemenwechselAmDayHidden || false, {
                    updateOn: 'change',
                });
            case fields.ZahnriemenFaelligKosten.name:
                return new ViewFormControl(model.zahnriemenFaelligKosten, {
                    formatter: this.currencyFormatterService,
                });
            case fields.ZahnriemenFaelligExternalId.name:
                return new ViewFormControl(model.zahnriemenFaelligExternalId || guid());
            default:
                throw new Error(`Could not create field for name: '${name}'.`);
        }
    }

    protected createArray(model: Wartung, arrayName: string, arrayFields: FeatureFields): ViewFormArray {
        const fields = PRODUKT_CONFIG_FEATURES.Wartung.fields;
        switch (arrayName) {
            case fields.Positionen.name:
                const positionen = model.positionen || [];
                return new ViewFormArray(
                    positionen.map((position) => this.positionFormViewFactory.create(position, arrayFields)),
                );
            case fields.NaechsterServiceBeschreibung.name:
                const beschreibung = model.naechsterServiceBeschreibung || [];
                return new ViewFormArray(
                    beschreibung.map((naechsterServiceBeschreibung) =>
                        this.produktDetailTextbausteinFormViewFactory.create(naechsterServiceBeschreibung, arrayFields),
                    ),
                );
            default:
                throw new Error(`Could not create array for name: '${arrayName}'.`);
        }
    }
}
