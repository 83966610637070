<div class="main-content">
    <app-card>
        <ng-container *ngFor="let field of fields; trackBy: trackByField">
            <ng-container [ngSwitch]="field.name">
                <ng-container *ngSwitchCase="'bezugsdatum'">
                    <ng-container *ngTemplateOutlet="dateDAT; context: { field: field }" />
                    <ng-container *ngIf="!hasExternalServiceIdentifier">
                        <p class="form-warn">
                            {{ 'werte.externalServiceIdentifier.info' | translate }}
                        </p>
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'roundValue'">
                    <h4 class="pb-4">{{ 'werte.ueberschrift.dat' | translate }}</h4>
                    <app-select
                        [form]="form"
                        [name]="'roundValue'"
                        [displayName]="'werte.' + 'roundValue'"
                        [values]="werteRoundValue"
                        [valuePrefix]="'werte.'"
                        [hasEmptyField]="false"
                    />
                </ng-container>
                <ng-container *ngSwitchCase="'haendlereinkaufswert'">
                    <ng-container
                        *ngTemplateOutlet="
                            currencyprint;
                            context: {
                                value: 'haendlereinkaufswert',
                                print: 'haendlereinkaufswertDrucken',
                                readonly: (roundValue$ | async) === enumRoundValue.Netto,
                            }
                        "
                    />
                </ng-container>
                <ng-container *ngSwitchCase="'haendlereinkaufswertNetto'">
                    <ng-container
                        *ngTemplateOutlet="
                            currencyprint;
                            context: {
                                value: 'haendlereinkaufswertNetto',
                                print: 'haendlereinkaufswertNettoDrucken',
                                readonly: (roundValue$ | async) === enumRoundValue.Brutto,
                            }
                        "
                    />
                </ng-container>
                <ng-container *ngSwitchCase="'haendlerverkaufswert'">
                    <ng-container
                        *ngTemplateOutlet="
                            currencyprint;
                            context: {
                                value: 'haendlerverkaufswert',
                                print: 'haendlerverkaufswertDrucken',
                                readonly: (roundValue$ | async) === enumRoundValue.Netto,
                            }
                        "
                    />
                </ng-container>
                <ng-container *ngSwitchCase="'haendlerverkaufswertNetto'">
                    <ng-container
                        *ngTemplateOutlet="
                            currencyprintbesteuerung;
                            context: {
                                value: 'haendlerverkaufswertNetto',
                                readonly: (roundValue$ | async) === enumRoundValue.Brutto,
                                print: 'haendlerverkaufswertNettoDrucken',
                                besteuerung: 'haendlerverkaufswertBesteuerung',
                            }
                        "
                    />
                </ng-container>
                <ng-container *ngSwitchCase="'neuwert'">
                    <ng-container
                        *ngTemplateOutlet="currencyprint; context: { value: 'neuwert', print: 'neuwertDrucken' }"
                    />
                </ng-container>
                <ng-container *ngSwitchCase="'relativerWert'">
                    <ng-container
                        *ngTemplateOutlet="
                            relativerWert;
                            context: { value: 'relativerWert', print: 'relativerWertDrucken' }
                        "
                    />
                </ng-container>
            </ng-container>
        </ng-container>
    </app-card>

    <app-card *ngIf="this.produkt.art !== produktArt.VtiTooling">
        <h4 class="pb-4">Werte mit Aufrechnung der Abzüge</h4>
        <ng-container *ngFor="let field of fields; trackBy: trackByField">
            <ng-container [ngSwitch]="field.name">
                <ng-container *ngSwitchCase="'aufwendungen'">
                    <ng-container
                        *ngTemplateOutlet="
                            currencyprint;
                            context: {
                                value: 'aufwendungen',
                                print: 'aufwendungenDrucken',
                                readonly: (aufwendungenReadonly$ | async),
                                manuelleWerteDrucken: (manuelleWerteDrucken$ | async),
                                click: 'click',
                            }
                        "
                    />
                </ng-container>
                <ng-container *ngSwitchCase="'aufwendungenDetailsDrucken'">
                    <app-slide-toggle
                        [form]="form"
                        [name]="'aufwendungenDetailsDrucken'"
                        [readonly]="aufwendungenDetailsDisabled"
                        [class.disabled]="aufwendungenDetailsDisabled"
                    >
                        {{ 'werte.aufwendungenDetailsDrucken' | translate }}
                    </app-slide-toggle>
                </ng-container>
                <!--        <ng-container *ngSwitchCase="'haendlereinkaufswertAbzuege'">-->
                <!--          <ng-container *ngTemplateOutlet="currencyprint, context: { value: 'haendlereinkaufswertAbzuege',-->
                <!--            print: 'haendlereinkaufswertAbzuegeDrucken' , readonly: true, manuelleWerteDrucken: (manuelleWerteDrucken$ | async)}">-->
                <!--          </ng-container>-->
                <!--        </ng-container>-->
                <ng-container *ngSwitchCase="'haendlereinkaufswertAbzuegeNetto'">
                    <ng-container
                        *ngTemplateOutlet="
                            currencyprint;
                            context: {
                                value: 'haendlereinkaufswertAbzuegeNetto',
                                print: 'haendlereinkaufswertAbzuegeNettoDrucken',
                                readonly: true,
                                manuelleWerteDrucken: (manuelleWerteDrucken$ | async),
                            }
                        "
                    />
                </ng-container>
                <!--        <ng-container *ngSwitchCase="'haendlerverkaufswertAbzuege'">-->
                <!--          <ng-container *ngTemplateOutlet="currencyprint, context: { value: 'haendlerverkaufswertAbzuege',-->
                <!--            print: 'haendlerverkaufswertAbzuegeDrucken' , readonly: true, manuelleWerteDrucken: (manuelleWerteDrucken$ | async)}">-->
                <!--          </ng-container>-->
                <!--        </ng-container>-->
                <ng-container *ngSwitchCase="'haendlerverkaufswertAbzuegeNetto'">
                    <ng-container
                        *ngTemplateOutlet="
                            currencyprint;
                            context: {
                                value: 'haendlerverkaufswertAbzuegeNetto',
                                print: 'haendlerverkaufswertAbzuegeNettoDrucken',
                                readonly: true,
                                manuelleWerteDrucken: (manuelleWerteDrucken$ | async),
                            }
                        "
                    />
                </ng-container>
            </ng-container>
        </ng-container>
    </app-card>

    <app-card *ngIf="this.produkt.art !== produktArt.VtiTooling">
        <h4 class="pb-4">Manuelle Werte</h4>

        <app-slide-toggle class="pb-4" [form]="form" [name]="'manuelleWerteDrucken'" (change)="onManuelleWerteDruckenChange($event)">
            Manuelle Werte drucken aktivieren
        </app-slide-toggle>

        <ng-container *ngIf="manuelleWerteDrucken$ | async">
            <label>
                {{ (name + '.manuelleWerte' | translate) + '&nbsp;(' + manuelleWerte.controls.length + ')&nbsp;' }}
            </label>
            <app-mini-fab
                class="add-manueller-wert"
                [color]="'primary'"
                (click)="onAddManuelleWerteClick()"
                mat-icon-button
            >
                <mat-icon>add</mat-icon>
            </app-mini-fab>
            <ng-container *ngTemplateOutlet="manuelleWerteTabelle" />
        </ng-container>

        <ng-container *ngFor="let field of fields; trackBy: trackByField">
            <ng-container [ngSwitch]="field.name || field.arrayName">
                <ng-container *ngSwitchCase="'wertAmMarkt'">
                    <ng-container *ngTemplateOutlet="currency; context: { field: field }" />
                </ng-container>
                <ng-container *ngSwitchCase="'wertAmMarktBemerkung'">
                    <app-input [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" type="text" />
                </ng-container>
                <ng-container *ngSwitchCase="'wertAmMarktBemerkungen'">
                    <app-textbausteine
                        #wertAmMarktBemerkungen
                        [form]="form"
                        [name]="field.arrayName"
                        [displayName]="name + '.' + field.arrayName"
                        [feature]="'Werte'"
                        [produktArt]="this.produkt.art"
                    />
                </ng-container>
                <ng-container *ngSwitchCase="'restwertAusUnfallschaden'">
                    <ng-container
                        *ngTemplateOutlet="
                            currency;
                            context: { field: field, placeholder: name + '.manuelleWerte' | translate }
                        "
                    />
                </ng-container>
                <ng-container *ngSwitchCase="'restwertAusUnfallschadenNetto'">
                    <ng-container
                        *ngTemplateOutlet="
                            currency;
                            context: { field: field, placeholder: name + '.manuelleWerte' | translate }
                        "
                    />
                </ng-container>
            </ng-container>
        </ng-container>
    </app-card>

    <app-card *ngIf="this.produkt.art !== produktArt.VtiTooling">
        <h4 class="pb-4">{{ name + '.wertbeeinflussendeFaktoren' | translate }}</h4>
        <ng-container *ngFor="let field of fields; trackBy: trackByField">
            <ng-container [ngSwitch]="field.name">
                <ng-container *ngSwitchCase="'werterhoehendesZubehoer'">
                    <ng-container *ngTemplateOutlet="currency; context: { field: field }" />
                </ng-container>
                <ng-container *ngSwitchCase="'werterhoehenderWartungszustand'">
                    <ng-container *ngTemplateOutlet="currency; context: { field: field }" />
                </ng-container>
                <ng-container *ngSwitchCase="'werterhoehenderReparaturzustand'">
                    <ng-container *ngTemplateOutlet="currency; context: { field: field }" />
                </ng-container>
                <ng-container *ngSwitchCase="'zweiterRadsatzAnteilig'">
                    <ng-container *ngTemplateOutlet="currency; context: { field: field }" />
                </ng-container>
            </ng-container>
        </ng-container>
    </app-card>
</div>

<!-- side panel -->
<app-side-panel
    class="side-panel"
    *ngIf="(viewport$ | async) !== viewport.Mobile && this.produkt.art !== produktArt.VtiTooling"
>
    <label>{{ name + '.total' | translate }}</label>
    <ng-container *ngTemplateOutlet="werteKosten" />
</app-side-panel>

<!-- bottom sheet -->
<app-bottom-sheet
    *ngIf="(viewport$ | async) === viewport.Mobile && this.produkt.art !== produktArt.VtiTooling"
    [title]="name + '.total' | translate"
    [seamless]="true"
>
    <ng-container *ngTemplateOutlet="werteKosten" />
</app-bottom-sheet>

<!-- notizen button -->
<app-produkt-detail-notizen-button [notizen]="notizen$ | async" (notizenChange)="onNotizenChange($event)" />

<!-- Templates -->
<ng-template #werteKosten>
    <ng-container *ngFor="let positionen of positionen$ | async; index as i">
        <app-form-table [rows]="positionen" [header]="i === 0" [footer]="i !== 0" style="display: flex">
            <app-form-table-column
                [displayName]="name + '.bezeichnung'"
                [footerDisplayName]="name + '.total.' + i"
                [fullWidth]="true"
                name="bezeichnung"
                type="text"
            />
            <app-form-table-column [displayName]="name + '.wert'" name="wert" type="currency" />
        </app-form-table>
    </ng-container>
</ng-template>

<ng-template #currency let-field="field" let-placeholder="placeholder">
    <app-input
        [form]="form"
        [name]="field.name"
        [displayName]="name + '.' + field.name"
        [placeholder]="placeholder"
        type="text"
        inputmode="numeric"
    >
        <ng-container appSuffix>&nbsp;€</ng-container>
    </app-input>
</ng-template>

<ng-template
    #currencyprint
    let-value="value"
    let-print="print"
    let-readonly="readonly"
    let-manuelleWerteDrucken="manuelleWerteDrucken"
    let-click="click"
>
    <app-form-group cols="8">
        <app-form-field colspan="7">
            <app-input
                [form]="form"
                [name]="value"
                [displayName]="name + '.' + value"
                [readonly]="readonly"
                type="text"
                inputmode="numeric"
            >
                <ng-container [class.readonly]="readonly" appSuffix>&nbsp;€</ng-container>
            </app-input>
        </app-form-field>
        <app-form-field colspan="1">
            <app-print-button
                class="print-button"
                *ngIf="click !== 'click'"
                [form]="form"
                [name]="print"
                [disabled]="manuelleWerteDrucken"
            />
            <app-print-button
                class="print-button"
                *ngIf="click === 'click'"
                [form]="form"
                [name]="print"
                [disabled]="manuelleWerteDrucken"
                (click)="onClickedCurrencyPrintButton()"
            />
        </app-form-field>
    </app-form-group>
</ng-template>

<ng-template
    #relativerWert
    let-value="value"
    let-print="print"
    let-readonly="readonly"
    let-manuelleWerteDrucken="relativerWertDrucken"
>
    <app-form-group cols="8">
        <app-form-field colspan="7">
            <app-input
                [form]="form"
                [name]="value"
                [displayName]="name + '.' + value"
                [readonly]="readonly"
                type="text"
                inputmode="numeric"
            >
                <ng-container [class.readonly]="readonly" appSuffix>&nbsp;%</ng-container>
            </app-input>
        </app-form-field>
        <app-form-field colspan="1">
            <app-print-button class="print-button" [form]="form" [name]="print" [disabled]="manuelleWerteDrucken" />
        </app-form-field>
    </app-form-group>
</ng-template>

<ng-template
    #currencyprintbesteuerung
    let-value="value"
    let-print="print"
    let-readonly="readonly"
    let-besteuerung="besteuerung"
>
    <app-form-group cols="8">
        <app-form-field colspan="7">
            <app-input
                [form]="form"
                [name]="value"
                [displayName]="name + '.' + value"
                [readonly]="readonly"
                type="text"
                inputmode="numeric"
            >
                <ng-container [class.readonly]="readonly" appSuffix>&nbsp;€</ng-container>
            </app-input>
        </app-form-field>
        <app-form-field colspan="1">
            <app-print-button class="print-button" [form]="form" [name]="print" />
        </app-form-field>
    </app-form-group>
    <app-form-group cols="1">
        <app-form-field colspan="1">
            <app-select
                [form]="form"
                [name]="besteuerung"
                [displayName]="name + '.' + besteuerung"
                [values]="besteuerungArt"
                [valuePrefix]="name + '.'"
            />
        </app-form-field>
    </app-form-group>
</ng-template>

<ng-template #percent let-field="field">
    <app-input
        [form]="form"
        [name]="field.name"
        [displayName]="name + '.' + field.name"
        type="text"
        inputmode="numeric"
    >
        <ng-container appSuffix>&nbsp;%</ng-container>
    </app-input>
</ng-template>

<ng-template #dateDAT let-field="field">
    <app-date
        [form]="form"
        [name]="field.name"
        [displayName]="name + '.' + field.name"
        [readonly]="hasExternalServiceIdentifier"
        [hasDatepicker]="!hasExternalServiceIdentifier"
    >
        <ng-container *ngIf="!(datRequestActive$ | async); else loading" appSuffix>
            <app-mini-fab
                *ngIf="hasExternalServiceIdentifier"
                [color]="'basic'"
                [disabled]="!hasExternalServiceIdentifier || (datRequestActive$ | async)"
                (action)="onGetWerteClick($event)"
            >
                <app-mat-icon icon="search" />
            </app-mini-fab>
        </ng-container>
    </app-date>

    <ng-container *ngTemplateOutlet="fahrzeug" />
</ng-template>

<ng-template #fahrzeug>
    <ng-container *ngFor="let field of fahrzeugFields; trackBy: trackByField">
        <ng-container [ngSwitch]="field.name || field.groupName">
            <ng-container *ngSwitchCase="'zulassungGroup'">
                <app-date
                    [form]="fahrzeugForm"
                    [name]="'erstzulassung'"
                    [displayName]="'fahrzeug.erstzulassung'"
                    [hasDatepicker]="false"
                    disabled="true"
                />
            </ng-container>
            <ng-container *ngSwitchCase="'laufleistung'">
                <app-form-group cols="21">
                    <app-form-field colspan="8">
                        <app-input
                            [form]="fahrzeugForm"
                            [name]="field.name"
                            [displayName]="'fahrzeug.' + field.name"
                            type="text"
                            inputmode="numeric"
                            disabled="true"
                        />
                    </app-form-field>
                    <app-form-field colspan="5">
                        <app-select
                            [form]="fahrzeugForm"
                            [name]="'laufleistungEinheit'"
                            [displayName]="'fahrzeug.' + 'laufleistungEinheit'"
                            [values]="fahrzeugLaufleistungEinheit"
                            [valuePrefix]="'fahrzeug.'"
                            [hasEmptyField]="false"
                            disabled="true"
                        />
                    </app-form-field>
                    <app-form-field colspan="8">
                        <app-select
                            [form]="fahrzeugForm"
                            [name]="'laufleistungBeschreibung'"
                            [displayName]="'fahrzeug.' + 'laufleistungBeschreibung'"
                            [values]="fahrzeugLaufleistungBeschreibung"
                            [valuePrefix]="'fahrzeug.'"
                            [hasEmptyField]="false"
                            disabled="true"
                        />
                    </app-form-field>
                </app-form-group>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #loading>
    <app-progress-spinner class="loading-spinner" [diameter]="25" />
</ng-template>

<ng-template #manuelleWerteTabelle let-field="field">
    <!--header-->
    <div class="header" *ngIf="manuelleWerte.controls.length">
        <ng-container *ngFor="let field of manuelleWerteFields; trackBy: trackByField">
            <ng-container [ngSwitch]="field.name || field.groupName">
                <ng-container *ngSwitchCase="'bezeichnung'">
                    <div class="group-header">
                        <label>{{ name + '.' + 'bezeichnung' | translate }}</label>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'wert'">
                    <div class="group-header">
                        <label>{{ name + '.' + 'wert' | translate }}</label>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>

    <!--header of expansion panel-->
    <div class="drop-list" (cdkDropListDropped)="drop($event)" cdkDropList>
        <app-expansion-panel-custom
            #panel
            *ngFor="let item of manuelleWerte.getRawValue(); index as index; trackBy: trackByField"
            [tableColumn]="2"
            cdkDrag
        >
            <app-expansion-panel-header-custom>
                <ng-container *ngFor="let field of manuelleWerteFields; trackBy: trackByField">
                    <ng-container [ngSwitch]="field.name">
                        <ng-container *ngSwitchCase="'bezeichnung'">
                            <div class="group-header">
                                <label>{{ item.bezeichnung }}</label>
                            </div>
                        </ng-container>

                        <ng-container *ngSwitchCase="'wert'">
                            <div class="group-header" *ngIf="isNumber(item.wert)">
                                <label>{{ currencyFormatter.format(item.wert) }}</label>
                            </div>
                            <div class="group-header" *ngIf="!isNumber(item.wert)">
                                <label>{{ item.wert }}</label>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <app-icon-button class="button drag-button" cdkDragHandle>
                    <app-mat-icon [icon]="'drag_indicator'" />
                </app-icon-button>
            </app-expansion-panel-header-custom>

            <!--body of expansion panel-->
            <div class="button-group">
                <button
                    class="button edit-button"
                    (click)="onRowOpenByIndex(index, item, panel, $event)"
                    type="button"
                    mat-icon-button
                >
                    <mat-icon>edit</mat-icon>
                </button>
                <button
                    class="button delete-button"
                    (click)="onRowRemoveByIndex(index, panel, $event)"
                    type="button"
                    mat-icon-button
                >
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </app-expansion-panel-custom>
    </div>
</ng-template>

<ng-template #dialog let-data="data">
    <app-produkt-detail-werte-manuelle-werte [form]="data.form" [name]="name" [fields]="data.fields" />
</ng-template>
