import { DragDropModule } from '@angular/cdk/drag-drop';
import { DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localDe from '@angular/common/locales/de';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CoreModule } from '@app/core.module';
import { AuthInterceptor } from '@app/interceptor/auth.interceptor';
import { ErrorHandlerService } from '@app/service/error-handler.service';
import { environment } from '@env/environment';
import { AuthModule } from '@modules/auth/auth.module';
import { DashboardModule } from '@modules/dashboard/dashboard.module';
import { EinstellungenModule } from '@modules/einstellungen/einstellungen.module';
import { ProduktModule } from '@modules/produkt/produkt.module';
import { TextbausteineModule } from '@modules/textbausteine-verwalten/textbausteine.module';
import { VerwaltungModule } from '@modules/verwaltung/verwaltung.module';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from '@shared/shared.module';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContentLayoutComponent } from './layout';
import { FooterComponent } from './layout/footer/footer.component';
import { HelpMenuComponent } from './layout/help-menu/help-menu.component';
import { NavComponent } from './layout/nav/nav.component';
import { UserMenuComponent } from './layout/user-menu/user-menu.component';

registerLocaleData(localDe, 'de');

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        ContentLayoutComponent,
        NavComponent,
        FooterComponent,
        HelpMenuComponent,
        UserMenuComponent,
    ],
    bootstrap: [AppComponent],
    imports: [
        // angular
        BrowserModule,
        BrowserAnimationsModule,
        DragDropModule,
        // core & shared
        CoreModule,
        SharedModule,
        // 3rd party
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
            compiler: {
                provide: TranslateCompiler,
                useClass: TranslateMessageFormatCompiler,
            },
        }),
        // app
        AppRoutingModule,
        // modules
        AuthModule,
        DashboardModule,
        VerwaltungModule,
        TextbausteineModule,
        EinstellungenModule,
        ProduktModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            registrationStrategy: 'registerImmediately',
        }),
        HammerModule,
        MatTooltipModule,
        MatMenuModule,
        MatIconModule,
        MatDividerModule,
    ],
    providers: [
        MatDialogModule,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: 'de' },
        { provide: MAT_DATE_LOCALE, useValue: 'de' },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                ...new MatDialogConfig(),
                maxWidth: '95vw',
                maxHeight: '95vh',
            },
        },
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        DecimalPipe,
        DatePipe,
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AppModule {}
