import { Injectable } from '@angular/core';
import { QueryOptions } from '@apollo/client';
import { AwsAppSyncClientProvider, FetchPolicy } from '@app/provider/aws-app-sync-client.provider';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { DataProxy } from 'apollo-cache';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, take, timeout } from 'rxjs/operators';
import {
    createTextbaustein,
    CreateTextbausteintData,
    deleteTextbaustein,
    GraphQLResponse,
    saveTextbaustein,
    SaveTextbausteintData,
} from '../graphql/mutations';
import { getTextbausteine, GetTextbausteineData } from '../graphql/queries';
import { ProduktArt } from '../schema/enum';
import { Textbaustein, TextbausteinInput } from '../schema/type';

const GET_NETWORK_TIMEOUT = 1000 * 5;

@Injectable({
    providedIn: 'root',
})
export class TextbausteineService {
    constructor(private readonly awsAppSyncClientProvider: AwsAppSyncClientProvider) {
        Assert.notNullOrUndefined(awsAppSyncClientProvider, 'awsAppSyncClientProvider');
    }

    getTextbausteine(feature: string, feld: string): Observable<Textbaustein[]> {
        const variables = {
            feature,
            feld,
        };
        return this.query<GetTextbausteineData, Textbaustein[]>(
            {
                query: getTextbausteine,
                variables: { ...variables },
            },
            (response) => response.getTextbausteine,
            GET_NETWORK_TIMEOUT,
        );
    }

    sortByErstelltAmAscending(textbausteine: Textbaustein[] = []): Textbaustein[] {
        return textbausteine.sort((a, b) => new Date(b.erstelltAm).getTime() - new Date(a.erstelltAm).getTime());
    }

    createTextbaustein(textbaustein: TextbausteinInput): Observable<Textbaustein> {
        const client = this.awsAppSyncClientProvider.provide();
        const mutatePromise = client
            .mutate<CreateTextbausteintData>({
                mutation: createTextbaustein,
                variables: {
                    textbaustein: {
                        ...textbaustein,
                    },
                },
                optimisticResponse: {
                    createTextbaustein: {
                        ...textbaustein,
                        __typename: 'Textbaustein',
                    },
                },
                update: (store: DataProxy) => {
                    this.updateGetCache(
                        store,
                        (textbausteine: Textbaustein[]) => {
                            if (!textbausteine.find((element) => element.id === textbaustein.id)) {
                                textbausteine.push(textbaustein);
                            }
                            return textbausteine;
                        },
                        textbaustein.feature,
                        textbaustein.feld,
                    );
                },
            })
            .then((result) => {
                return result;
            });
        return from(mutatePromise).pipe(
            map((response: GraphQLResponse<CreateTextbausteintData>) => response.data.createTextbaustein),
        );
    }

    saveTextbaustein(textbaustein: TextbausteinInput): Observable<Textbaustein> {
        const client = this.awsAppSyncClientProvider.provide();
        const mutatePromise = client.mutate({
            mutation: saveTextbaustein,
            variables: {
                textbaustein: {
                    ...textbaustein,
                },
            },
            optimisticResponse: {
                saveTextbaustein: {
                    ...textbaustein,
                    __typename: 'Textbaustein',
                },
            },
            update: (store: DataProxy, { data: { saveTextbaustein } }) => {
                this.updateGetCache(
                    store,
                    (textbausteine: Textbaustein[]) => {
                        const changedTextbaustein = textbausteine.find((element) => element.id === textbaustein.id);
                        const index = textbausteine.indexOf(changedTextbaustein);
                        textbausteine[index] = saveTextbaustein;
                        return textbausteine;
                    },
                    textbaustein.feature,
                    textbaustein.feld,
                );
            },
        });

        return from(mutatePromise).pipe(
            map((response: GraphQLResponse<SaveTextbausteintData>) => response.data.saveTextbaustein),
        );
    }

    deleteTextbaustein(id: string, feature: string, feld: string): Observable<boolean> {
        const client = this.awsAppSyncClientProvider.provide();

        const mutatePromise = client.mutate({
            mutation: deleteTextbaustein,
            variables: {
                id,
            },
            optimisticResponse: {
                deleteTextbaustein: true,
            },
            update: (store: DataProxy) => {
                this.updateGetCache(
                    store,
                    (textbausteine: Textbaustein[]) => {
                        const filteredTextbausteine = textbausteine.filter((element) => element.id !== id);
                        return filteredTextbausteine;
                    },
                    feature,
                    feld,
                );
            },
        });
        return from(mutatePromise).pipe(map((response) => response.data.deleteTextbaustein));
    }

    private query<TResponse, TResult>(
        options: QueryOptions,
        get: (response: TResponse) => TResult,
        due: number,
    ): Observable<TResult> {
        const client = this.awsAppSyncClientProvider.provide();
        client.hydrated();

        const cache$ = from(
            client.query<TResponse>({
                ...options,
                fetchPolicy: FetchPolicy.CacheOnly,
            }),
        );
        const network$ = from(
            client.query<TResponse>({
                ...options,
                fetchPolicy: FetchPolicy.CacheFirst,
            }),
        );

        return cache$.pipe(
            mergeMap((cache) => {
                if (cache && cache.data && get(cache.data)) {
                    return network$.pipe(
                        timeout(due),
                        catchError(() => {
                            return of(cache);
                        }),
                    );
                } else {
                    return network$;
                }
            }),
            map((response) => get(response.data)),
        );
    }

    createTextbausteinFormGroup(textbaustein: Textbaustein): ViewFormGroup {
        return new ViewFormGroup({
            id: new ViewFormControl(textbaustein.id),
            kurztext: new ViewFormControl(textbaustein.kurztext),
            langtext: new ViewFormControl(textbaustein.langtext),
            verfuegbarkeit: new ViewFormControl(textbaustein.verfuegbarkeit),
            produkte: new ViewFormControl(textbaustein.produkte),
            feature: new ViewFormControl(textbaustein.feature),
            feld: new ViewFormControl(textbaustein.feld),
            tags: new ViewFormControl(textbaustein.tags),
            standard: new ViewFormControl(textbaustein.standard),
            erstelltAm: new ViewFormControl(textbaustein.erstelltAm),
            erstelltVon: new ViewFormControl(textbaustein.erstelltVon),
        });
    }

    prefillWithStandardTextbausteine(
        feature: string,
        field: string,
        produktArt: ProduktArt,
        form: ViewFormGroup,
    ): void {
        this.getTextbausteine(feature, field)
            .pipe(take(1))
            .subscribe({
                next: (result) => {
                    const resultFiltered = result.filter(
                        (value) => value.standard && value.produkte.includes(produktArt),
                    );
                    resultFiltered.forEach((baustein) =>
                        (form.get(field) as ViewFormArray).push(this.createTextbausteinFormGroup(baustein)),
                    );
                },
            });
    }

    private updateGetCache(
        store: DataProxy,
        update: (textbausteine: Textbaustein[]) => Textbaustein[],
        feature: string,
        feld: string,
    ): void {
        let data: GetTextbausteineData = {
            getTextbausteine: null,
        };

        const variables = {
            feature,
            feld,
        };

        try {
            data = store.readQuery<GetTextbausteineData>({
                query: getTextbausteine,
                variables: { ...variables },
            });
        } catch (error) {
            console.warn("Could not readQuery 'getTextbausteine' from store: ", error);
        }

        data.getTextbausteine = update(data.getTextbausteine || []);

        data.getTextbausteine.forEach((textbaustein) => {
            if (!textbaustein.__typename) {
                textbaustein.__typename = 'Textbaustein';
            }
        });

        store.writeQuery({
            query: getTextbausteine,
            data,
            variables: { ...variables },
        });
    }
}
