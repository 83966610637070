import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    input,
    OnInit,
    output,
    Signal,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatChipListbox } from '@angular/material/chips';
import { MatDialogRef } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { ProduktArt } from '@data/domain/schema/enum';
import { Textbaustein } from '@data/domain/schema/type';
import { TextbausteineService } from '@data/domain/service/textbausteine.service';
import { FeatureFields } from '@modules/produkt/config/produkt-config';
import { FormControlBase } from '@shared/component/form-controls/form-control-base.component';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { FormControlFocusService } from '@shared/service/form-controls/form-control-focus.service';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { take } from 'rxjs/operators';

interface ProduktDetailTextbausteineDialogData {
    form: ViewFormGroup;
    feature: string;
    feld: string;
}

interface ProduktDetailTextbausteineDialogEditData {
    form: ViewFormGroup;
    feature: string;
    fields: FeatureFields;
}

@Component({
    selector: 'app-textbausteine',
    templateUrl: './textbausteine.component.html',
    styleUrls: ['./textbausteine.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextbausteineComponent extends FormControlBase implements OnInit {
    seperators = [ENTER, COMMA];
    viewControl = new UntypedFormControl();

    optionsFiltered: Signal<string[]>;

    viewControlArray: ViewFormArray;

    feature = input<string>('');
    produktArt = input<ProduktArt>(ProduktArt.None);
    multiple = input<boolean>(true);

    textbausteinEventEmitter = output<Textbaustein>();
    dialogClose = output<ViewFormArray>();

    @ViewChild(MatInput, { static: true, read: ElementRef })
    input: ElementRef<HTMLElement>;

    @ViewChild('list', { static: true })
    chipList: MatChipListbox;

    @ViewChild('dialogEdit', { static: true })
    dialogEditTemplate: TemplateRef<any>;

    @ViewChild('dialog', { static: true })
    dialogTemplate: TemplateRef<any>;

    @ViewChild('delete', { static: true })
    deleteTemplate: TemplateRef<any>;

    private dialogRef: MatDialogRef<any>;

    constructor(
        formControlFocusService: FormControlFocusService,
        private readonly textbausteineService: TextbausteineService,
        private readonly templateDialogService: TemplateDialogService,
    ) {
        super(formControlFocusService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.viewControlArray = this.form?.get(this.name) as ViewFormArray;
    }

    blur(): void {
        this.input.nativeElement.blur();
    }

    focus(): void {
        this.input.nativeElement.focus();
    }

    onChipRemoveClicked(index: number): void {
        Assert.notNullOrUndefined(index, 'index');

        const buttons = [
            this.templateDialogService.getCancelButtonSetting(),
            this.templateDialogService.getDeleteButtonSetting(),
        ];
        this.templateDialogService
            .openTemplate('textbausteine.delete', buttons, this.deleteTemplate)
            .pipe(take(1))
            .subscribe((result) => {
                if (result?.name === this.templateDialogService.getDeleteButtonSetting().title) {
                    this.removeOption(index);
                }
            });
    }

    onAddTextbausteinClicked(): void {
        const buttons = [this.templateDialogService.getCloseButtonSetting()];
        const data: ProduktDetailTextbausteineDialogData = {
            form: null,
            feature: this.feature(),
            feld: this.name,
        };

        this.dialogRef = this.templateDialogService.open('textbausteine.add', buttons, this.dialogTemplate, data, true);
    }

    onTextbausteinAdd($event: Textbaustein): void {
        this.addTextbaustein($event);
        this.dialogClose.emit(this.viewControlArray);
        this.dialogRef.close();
    }

    private removeOption(index: number): void {
        this.viewControlArray.removeAt(index);
    }

    onChipClicked(value: any): void {
        const buttons = [
            this.templateDialogService.getSaveButtonSetting(),
        ];
        const data: ProduktDetailTextbausteineDialogEditData = {
            form: value,
            feature: this.feature(),
            fields: [],
        };

        this.templateDialogService
            .openTemplate('textbausteine.edit', buttons, this.dialogEditTemplate, data, true)
            .pipe(take(1))
            .subscribe();
    }

    getKurztext(): string[] {
        return this.control.value.map((value) => value.value.kurztext);
    }

    private addTextbaustein(textbaustein: Textbaustein): void {
        this.viewControlArray.push(this.textbausteineService.createTextbausteinFormGroup(textbaustein));
        this.chipList.focus();
    }
}
