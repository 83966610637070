<mat-form-field>
    <input #input matInput />
    <ng-container matSuffix>
        <button [disabled]="input.value.length === 0" (click)="onAddClick(input)" mat-button>
            <app-mat-icon class="new-style" [newStyle]="true" icon="icon-add" />
        </button>
    </ng-container>
</mat-form-field>

@if (!loading()) {
    <mat-radio-group class="single" #group>
        @for (textbaustein of textbausteine(); track textbaustein) {
            <div class="list-item key">
                <mat-radio-button [value]="textbaustein" (change)="textbausteinEventEmitter.emit(textbaustein)">
                    {{ textbaustein.kurztext }}
                </mat-radio-button>

                <div class="show-icon-button">
                    @if (isGTUEStandard(textbaustein)) {
                        <app-icon-button (click)="onShowTextbausteinClicked(textbaustein)">
                            <app-mat-icon icon="remove_red_eye" />
                        </app-icon-button>
                        <app-icon-button>
                            <app-mat-icon svgIcon="gtue_logo" />
                        </app-icon-button>
                    } @else {
                        <app-icon-button (click)="onEditTextbausteinClicked(textbaustein)">
                            <app-mat-icon icon="edit" />
                        </app-icon-button>
                        <app-icon-button (click)="onDeleteTextbausteinClicked(textbaustein)">
                            <app-mat-icon icon="delete" />
                        </app-icon-button>
                    }
                </div>
            </div>
        }
    </mat-radio-group>
} @else {
    <app-progress-spinner class="spinner" [diameter]="80" />
}

<!-- Show dialog -->
<ng-template #textbausteinDialogTemplate let-data="data">
    <app-textbausteine-dialog-edit
        [feature]="feature()"
        [feld]="feld()"
        [mode]="data.mode"
        [form]="data.form"
        [data]="data"
        [feldDisplayName]="feldDisplayName()"
    />
</ng-template>

<!-- Delete dialog -->
<ng-template #dialogDelete>
    <app-delete-dialog />
</ng-template>
