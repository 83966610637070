<app-card *ngIf="produkt?.idParent">
    <app-produkt-detail-parent-form [idParent]="produkt?.idParent" />
</app-card>

<app-card
    [class.max-width-50]="(viewport$ | async) !== viewport.Mobile && this.produkt?.art === produktArt.CarGarantie"
>
    <ng-container *ngFor="let field of fields; trackBy: trackByField">
        <ng-container [ngSwitch]="field.name || field.groupName || field.arrayName">
            <!-- Art -->
            <ng-container *ngSwitchCase="'art'">
                <app-radio
                    [form]="form"
                    [name]="field.name"
                    [displayName]="name + '.' + field.name"
                    [values]="art"
                    [valuePrefix]="name + '.'"
                    [disabled]="artDisabled"
                />
            </ng-container>
            <!-- Nummer -->
            <ng-container *ngSwitchCase="'nummer'">
                <app-input [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" type="text" />
            </ng-container>
            <ng-container *ngSwitchCase="'vorgangsnummer'">
                <app-input [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" type="text" />
            </ng-container>
            <!-- ErstellungsTagGroup -->
            <ng-container *ngSwitchCase="'erstellungsTagGroup'">
                <ng-container
                    *ngTemplateOutlet="
                        dateTime;
                        context: { date: 'erstellungsTag', time: 'erstellungsUhrzeit', startView: 'month' }
                    "
                />
            </ng-container>
            <!-- BesichtigungsTagGroup -->
            <ng-container *ngSwitchCase="'besichtigungsTagGroup'">
                <ng-container
                    *ngTemplateOutlet="
                        dateTime;
                        context: { date: 'besichtigungsTag', time: 'besichtigungsUhrzeit', startView: 'month' }
                    "
                />
            </ng-container>
            <!-- FertigstellungsTagGroup -->
            <ng-container *ngSwitchCase="'fertigstellungsTagGroup'">
                <ng-container
                    *ngTemplateOutlet="
                        dateTime;
                        context: {
                            date: 'fertigstellungsTag',
                            time: 'fertigstellungsUhrzeit',
                            startView: 'month',
                            addHours: 1,
                        }
                    "
                />
            </ng-container>
            <!-- Bemerkungen -->
            <ng-container *ngSwitchCase="'bemerkungen'">
                <app-textbausteine
                    #bemerkungen
                    [form]="form"
                    [name]="'bemerkungen'"
                    [displayName]="'Bemerkungen'"
                    [feature]="'Auftrag'"
                    [produktArt]="this.produkt?.art"
                />
            </ng-container>
            <!-- Auftragsbeschreibung -->
            <ng-container *ngSwitchCase="'auftragsbeschreibung'">
                <app-textbausteine
                    #auftragsbeschreibung
                    [form]="form"
                    [name]="'auftragsbeschreibung'"
                    [displayName]="'Auftragsbeschreibung'"
                    [feature]="'Auftrag'"
                    [produktArt]="this.produkt?.art"
                />
            </ng-container>
            <!-- Besichtigungsbedingungen -->
            <ng-container *ngSwitchCase="'besichtigungsbedingungen'">
                <app-textbausteine
                    #besichtigungsbedingungen
                    [form]="form"
                    [name]="'besichtigungsbedingungen'"
                    [displayName]="'Besichtigungsbedingungen'"
                    [feature]="'Auftrag'"
                    [produktArt]="this.produkt?.art"
                />
            </ng-container>
            <!-- Bemerkung -->
            <ng-container *ngSwitchCase="'bemerkung'">
                <app-textarea [form]="form" [name]="field.name" [displayName]="name + '.' + field.name">
                    <ng-container appHint>
                        {{ name + '.' + field.name + '.hint' | translate }}
                    </ng-container>
                </app-textarea>
            </ng-container>
        </ng-container>
    </ng-container>
</app-card>

<app-card *ngIf="this.produkt?.art !== produktArt.CarGarantie">
    <ng-container *ngFor="let field of fields; trackBy: trackByField">
        <ng-container [ngSwitch]="field.name || field.groupName || field.arrayName">
            <!-- Hinweise -->
            <ng-container *ngSwitchCase="'hinweise'">
                <h4>{{ name + '.hinweise' | translate }}</h4>
                <app-selection-list [multiline]="true" [header]="name + '.hinweise.header'">
                    <ng-container appHint>
                        {{ name + '.hinweise.hint' | translate }}
                    </ng-container>
                    <app-list-option
                        *ngFor="let hinweis of form.get('hinweise').controls; trackBy: trackByInstance; index as id"
                        [form]="hinweis"
                        [name]="'vorhanden'"
                        [displayName]="
                            (produkt?.art === produktArt.AlphaController && hinweis.value.name === 'probefahrt'
                                ? 'ac.'
                                : '') +
                            name +
                            '.' +
                            hinweis.value.name
                        "
                        [id]="id + 1"
                    />
                </app-selection-list>
            </ng-container>
        </ng-container>
    </ng-container>
</app-card>

<app-produkt-detail-notizen-button [notizen]="notizen$ | async" (notizenChange)="onNotizenChange($event)" />

<!-- Templates -->
<ng-template #dateTime let-date="date" let-time="time" let-startView="startView" let-addHours="addHours">
    <app-form-group cols="10">
        <app-form-field colspan="6">
            <app-date
                [form]="form"
                [name]="date"
                [displayName]="name + '.' + date"
                [startView]="startView"
                (focusInputField)="prefillDatumUhrzeit(date, time, addHours)"
            />
        </app-form-field>
        <app-form-field colspan="4">
            <app-input [form]="form" [name]="time" [displayName]="name + '.' + time" type="time" />
        </app-form-field>
    </app-form-group>
</ng-template>
