import { Component, input, OnInit, output, signal, TemplateRef, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { guid } from '@app/function/guid';
import { AuthService } from '@app/service/auth.service';
import { ProduktArt } from '@data/domain/schema/enum';
import { Textbaustein } from '@data/domain/schema/type';
import { TextbausteineService } from '@data/domain/service/textbausteine.service';
import { FeatureFields } from '@modules/produkt/config/produkt-config';
import { Verfuegbarkeit } from '@modules/textbausteine-verwalten/config/textbausteine-verwalten.config';
import { EditMode } from '@shared/component/popup-modal/textbausteine-dialog-edit/textbausteine-dialog-edit.component';
import { ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { of, timer } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';

interface NewTextbausteinData {
    form: ViewFormGroup;
    fields: FeatureFields;
    mode: EditMode;
}

@Component({
    selector: 'app-textbausteine-list',
    templateUrl: './textbausteine-list.component.html',
    styleUrls: ['./textbausteine-list.component.scss'],
})
export class TextbausteineListComponent implements OnInit {
    feature = input<string>('');
    feld = input<string>('');
    feldDisplayName = input<string>('');
    produktArt = input<ProduktArt>(ProduktArt.None);

    textbausteine = signal<Textbaustein[]>([]);
    loading = signal(false);

    textbausteinEventEmitter = output<Textbaustein>();

    @ViewChild('textbausteinDialogTemplate', { static: true })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    textbausteinDialogTemplate: TemplateRef<any>;

    @ViewChild('dialogDelete', { static: true })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dialogDeleteTemplate: TemplateRef<any>;

    EditMode = EditMode;

    private user = '';
    private buero = '';

    constructor(
        private readonly textbausteineService: TextbausteineService,
        private readonly authService: AuthService,
        private readonly templateDialogService: TemplateDialogService,
    ) {
        this.authService
            .getClaims()
            .pipe(take(1))
            .subscribe((value) => {
                this.user = value['custom:personal_nummer'];
                this.buero = value['custom:buero_id'];
            });
    }

    ngOnInit(): void {
        this.reloadTextbausteine();
    }

    onAddClick(input: MatInput): void {
        if (input?.value?.length <= 0) {
            return;
        }
        this.createNewTextbaustein(input.value);
    }

    isGTUEStandard(value: Textbaustein): boolean {
        if (value?.verfuegbarkeit) {
            return value.verfuegbarkeit.includes(Verfuegbarkeit.GTUE);
        }
        return false;
    }

    onShowTextbausteinClicked(value: Textbaustein): void {
        const buttons = [this.templateDialogService.getCloseButtonSetting()];
        this.openTextbausteinDialog(value, this.textbausteinDialogTemplate, buttons, EditMode.Textbaustein);
    }

    onEditTextbausteinClicked(value: Textbaustein): void {
        const buttons = [
            this.templateDialogService.getCancelButtonSetting(),
            this.templateDialogService.getSaveButtonSetting(),
        ];
        this.openTextbausteinDialog(value, this.textbausteinDialogTemplate, buttons, EditMode.Verwaltung);
    }

    private openTextbausteinDialog(
        value: Textbaustein,
        dialog: TemplateRef<any>,
        buttons = [],
        mode = EditMode.Textbaustein,
    ): void {
        const data = {
            form: new ViewFormGroup({
                id: new ViewFormControl(value.id),
                kurztext: new ViewFormControl(value.kurztext),
                langtext: new ViewFormControl(value.langtext),
                verfuegbarkeit: new ViewFormControl(value.verfuegbarkeit),
                produkte: new ViewFormControl(value.produkte),
                standard: new ViewFormControl(value.standard),
                feature: new ViewFormControl(value.feature),
                feld: new ViewFormControl(value.feld),
                tags: new ViewFormControl(value.tags),
                erstelltAm: new ViewFormControl(value.erstelltAm),
                erstelltVon: new ViewFormControl(value.erstelltVon),
            }),
            fields: [],
            mode: mode,
        };

        const title = 'textbausteine.edit';

        this.templateDialogService
            .openTemplate(title, buttons, dialog, data, true)
            .pipe(take(1))
            .subscribe((result) => {
                if (result?.name === this.templateDialogService.getSaveButtonSetting().title) {
                    this.loading.set(true);
                    this.textbausteineService
                        .saveTextbaustein(result.data.form.value)
                        .pipe(take(1))
                        .subscribe({
                            next: (_textbaustein) => {
                                this.reloadTextbausteine();
                            },
                            error: (error) => {
                                console.error('saveTextbausteine - error: ', error);
                                this.loading.set(false);
                            },
                        });
                }
            });
    }

    private createNewTextbaustein(value: string): void {
        const form = new ViewFormGroup({
            kurztext: new ViewFormControl(value),
            langtext: new ViewFormControl(value),
            verfuegbarkeit: new ViewFormControl([Verfuegbarkeit.Benutzer]),
            produkte: new ViewFormControl([this.produktArt()]),
            standard: new ViewFormControl(false),
            erstelltAm: new ViewFormControl(new Date().toISOString()),
        });

        const title = 'textbausteine.add';
        const buttons = [
            this.templateDialogService.getCancelButtonSetting(),
            this.templateDialogService.getSaveButtonSetting(),
        ];
        const data: NewTextbausteinData = {
            form,
            fields: [],
            mode: EditMode.Verwaltung,
        };

        this.templateDialogService
            .openTemplate(title, buttons, this.textbausteinDialogTemplate, data)
            .pipe(take(1))
            .subscribe((result) => {
                if (result?.name === this.templateDialogService.getSaveButtonSetting().title) {
                    const newTextbaustein: Textbaustein = {
                        id: guid(),
                        buero: this.buero,
                        kurztext: result.data.form.get('kurztext').value,
                        langtext: result.data.form.get('langtext').value,
                        verfuegbarkeit: result.data.form.get('verfuegbarkeit').value,
                        produkte: result.data.form.get('produkte').value,
                        feature: this.feature(),
                        feld: this.feld(),
                        tags: [],
                        standard: result.data.form.get('standard').value,
                        erstelltAm: new Date().toISOString(),
                        erstelltVon: this.user,
                    };

                    this.loading.set(true);
                    this.textbausteineService
                        .createTextbaustein(newTextbaustein)
                        .pipe(take(1))
                        .subscribe({
                            next: (_textbaustein) => {
                                this.textbausteinEventEmitter.emit(newTextbaustein);
                                this.reloadTextbausteine();
                            },
                        });
                }
            });
    }

    onDeleteTextbausteinClicked(textbaustein: Textbaustein): void {
        const title = 'textbausteine.delete';
        const buttons = [
            this.templateDialogService.getCancelButtonSetting(),
            this.templateDialogService.getDeleteButtonSetting(),
        ];

        this.templateDialogService
            .openTemplate(title, buttons, this.dialogDeleteTemplate)
            .pipe(take(1))
            .subscribe((result) => {
                if (result?.name === this.templateDialogService.getDeleteButtonSetting().title) {
                    this.loading.set(true);
                    this.textbausteineService
                        .deleteTextbaustein(textbaustein.id, this.feature(), this.feld())
                        .pipe(take(1))
                        .subscribe({
                            complete: () => {
                                this.reloadTextbausteine(900);
                            },
                        });
                }
            });
    }

    private sortTextbausteine(textbausteine: Textbaustein[]): Textbaustein[] {
        return this.textbausteineService
            .sortByErstelltAmAscending(textbausteine)
            .filter((value) => (this.produktArt() ? value.produkte.includes(this.produktArt()) : true));
    }

    private reloadTextbausteine(delay: number = 900): void {
        const textbausteine$ = this.textbausteineService.getTextbausteine(this.feature(), this.feld());

        timer(delay)
            .pipe(
                switchMap(() => textbausteine$),
                take(1),
                catchError((error) => {
                    console.error('getTextbausteine - error: ', error);
                    return of(undefined);
                }),
            )
            .subscribe((textbausteine) => {
                this.loading.set(false);
                if (!textbausteine) return;

                const sortedTextbausteine = this.sortTextbausteine(textbausteine);
                this.textbausteine.set(sortedTextbausteine);
            });
    }
}
