import gql from 'graphql-tag';
import {
    Abschluss,
    AcAbschluss,
    Auftrag,
    Ausstattung,
    CgAbschluss,
    CgFeststellung,
    Fahrzeug,
    Fehlteile,
    Feststellungen,
    Kalkulation,
    Lackmessung,
    NfzRaeder,
    Produkt,
    Raeder,
    Restwertprognose,
    Schaden,
    Textbaustein,
    Uebersicht,
    Unterlagen,
    Vorschaden,
    VtiAbschluss,
    VtiAuftrag,
    VtiWerte,
    Wartung,
    Werte,
} from '../schema/type';

export interface GraphQLResponse<TData> {
    data: TData;
}

// Produkt

export interface CreateProduktData {
    createProdukt: Produkt;
}

export const createProdukt = gql`
    mutation CreateProdukt($id: ID!, $art: Int!) {
        createProdukt(id: $id, art: $art) {
            id
            idParent
            art
            status
            identnummer
            versandArt
            rechnungsArt
            erstelltAm
            abgeschlossenAm
            abgeschlossenVon
            abrechnungen {
                id
                abrechnungen {
                    id
                    externalProvider
                    externalService
                    identifier
                    datum
                    mitarbeiter
                }
            }
            auftrag {
                id
                art
                nummer
                vorgangsnummer
                auftragstyp
                bezugsAuftragsnummer
                kommentar
                erstellungsTag
                erstellungsUhrzeit
                besichtigungsTag
                besichtigungsUhrzeit
                fertigstellungsTag
                fertigstellungsUhrzeit
                bemerkungen {
                    id
                    kurztext
                    langtext
                    verfuegbarkeit
                    produkte
                    feature
                    feld
                    tags
                    standard
                    erstelltAm
                    erstelltVon
                }
                auftragsbeschreibung {
                    id
                    kurztext
                    langtext
                    verfuegbarkeit
                    produkte
                    feature
                    feld
                    tags
                    standard
                    erstelltAm
                    erstelltVon
                }
                besichtigungsbedingungen {
                    id
                    kurztext
                    langtext
                    verfuegbarkeit
                    produkte
                    feature
                    feld
                    tags
                    standard
                    erstelltAm
                    erstelltVon
                }
                bemerkung
                hinweise {
                    id
                    name
                    vorhanden
                }
                notizen {
                    id
                    createdAt
                    datei
                    sprachnachricht
                    textnachricht
                }
            }
            vtiAuftrag {
                id
                art
                nummer
                vorgangsnummer
                auftragstyp
                bezugsAuftragsnummer
                bemerkung
                notizen {
                    id
                    createdAt
                    datei
                    sprachnachricht
                    textnachricht
                }
            }
            fahrzeug {
                id
                kennzeichen
                identnummer
                datECode
                typCode
                herstellerCode
                erstzulassung
                letzteZulassung
                laufleistung
                laufleistungEinheit
                laufleistungBeschreibung
                laufleistungen {
                    id
                    wert
                    einheit
                    beschreibung
                }
                fahrzeugart
                fahrzeugartManuell
                bauform
                bauformManuell
                hersteller
                modell
                untertyp
                lackcode
                innenausstattung
                polsterfarbe
                polstermaterial
                hubraum
                leistung
                zylinder
                schadstoffklasse
                leergewicht
                zulaessigesGesamtgewicht
                masseLaenge
                masseBreite
                masseHoehe
                anzahlTueren
                anzahlSitze
                antriebstechnologie
                antriebsart
                getriebeart
                anzahlGaenge
                anzahlVorbesitzer
                scheckheftGepflegt
                hvBatterie
                acFahrzeugart
                acBauform
                acLackfarbe
                acLackierung
                acAntriebstechnologie
                acGetriebeart
                acSchadstoffklasse
                acEhemaligeNutzung
                bemerkungen {
                    id
                    kurztext
                    langtext
                    verfuegbarkeit
                    produkte
                    feature
                    feld
                    tags
                    standard
                    erstelltAm
                    erstelltVon
                }
                fahrzeugExternalServiceReference {
                    id
                    source
                    identifier
                    container
                    constructionTime
                    vxsData
                }
                vinAbfrageErfolgt
                notizen {
                    id
                    createdAt
                    datei
                    sprachnachricht
                    textnachricht
                }
            }
            adressen {
                id
                adressen {
                    id
                    createdAt
                    firma
                    anrede
                    anredeFreitext
                    vorname
                    name
                    strasseNr
                    postleitzahl
                    ort
                    telefon
                    mobil
                    email
                    arten
                    externalId
                    bestellnummer
                }
                notizen {
                    id
                    createdAt
                    datei
                    sprachnachricht
                    textnachricht
                }
            }
            raeder {
                id
                radSaetze {
                    id
                    lagerort
                    rads {
                        id
                        radPosition
                        hersteller
                        reifenTyp
                        radFelge
                        reifenbreite
                        querschnitt
                        bauart
                        felgengroesse
                        tragfaehigkeit1
                        geschwindigkeitsindex
                        zulaessig
                        profil
                        felgenhersteller
                        runflat
                        dot
                    }
                }
                notlaufeinrichtung
                notlaufeinrichtungProfiltiefe
                bemerkungen {
                    id
                    kurztext
                    langtext
                    verfuegbarkeit
                    produkte
                    feature
                    feld
                    tags
                    standard
                    erstelltAm
                    erstelltVon
                }
                notizen {
                    id
                    createdAt
                    datei
                    sprachnachricht
                    textnachricht
                }
            }
            nfzRaeder {
                id
                radSaetze {
                    id
                    lagerort
                    achsen {
                        id
                        seiten {
                            id
                            achsenRaeder {
                                id
                                position
                                hersteller
                                typ
                                felge
                                reifenbreite
                                querschnitt
                                bauart
                                felgengroesse
                                tragfaehigkeit
                                geschwindigkeitsindex
                                zustand
                                profiltiefe
                                reifenDruck
                                felgenart
                                felgenhersteller
                                runflat
                                dot
                            }
                            seite
                        }
                        bewertung
                    }
                }
                notlaufeinrichtung
                notlaufeinrichtungProfiltiefe
                bemerkungen {
                    id
                    kurztext
                    langtext
                    verfuegbarkeit
                    produkte
                    feature
                    feld
                    tags
                    standard
                    erstelltAm
                    erstelltVon
                }
                notizen {
                    id
                    createdAt
                    datei
                    sprachnachricht
                    textnachricht
                }
            }
            vorschaden {
                id
                positionen {
                    id
                    obergruppe
                    untergruppe
                    art
                    reparatur
                    bilder
                    schadendatum
                    schadenhoehe
                    wertminderung
                }
                notizen {
                    id
                    createdAt
                    datei
                    sprachnachricht
                    textnachricht
                }
            }
            fehlteile {
                id
                positionen {
                    id
                    externalId
                    bezeichnung
                    preis
                }
                notizen {
                    id
                    createdAt
                    datei
                    sprachnachricht
                    textnachricht
                }
            }
            uebersicht {
                id
                deckblatt
                vorneLinks
                vorneRechts
                hintenLinks
                hintenRechts
                innenraum
                sonstiges {
                    id
                    bilder
                    bezeichnung
                    bezeichnungen {
                        id
                        kurztext
                        langtext
                        verfuegbarkeit
                        produkte
                        feature
                        feld
                        tags
                        standard
                        erstelltAm
                        erstelltVon
                    }
                }
                fahrzeugVorhanden
                notizen {
                    id
                    createdAt
                    datei
                    sprachnachricht
                    textnachricht
                }
            }
            schaden {
                id
                positionen {
                    id
                    obergruppe
                    untergruppe
                    beschaedigungart
                    reparaturweg
                    bilder
                    intensitaet
                    preis
                    minderwert
                }
                beschreibungen {
                    id
                    kurztext
                    langtext
                    verfuegbarkeit
                    produkte
                    feature
                    feld
                    tags
                    standard
                    erstelltAm
                    erstelltVon
                }
                notizen {
                    id
                    createdAt
                    datei
                    sprachnachricht
                    textnachricht
                }
            }
            cgFeststellung {
                id
                versicherungsart
                positionenBauteileversichert {
                    id
                    bezeichnung
                    baugruppe
                    prueftext
                    bilder
                }
                positionenFahrzeugversichert {
                    id
                    bezeichnung
                    baugruppe
                    prueftext
                    bilder
                }
                verschleissbedingt
                diagnose
                internerKommentar
                notizen {
                    id
                    createdAt
                    datei
                    sprachnachricht
                    textnachricht
                }
            }
            feststellungen {
                id
                umfaenge {
                    id
                    obergruppeName
                    obergruppe
                    beschreibung {
                        id
                        kurztext
                        langtext
                        verfuegbarkeit
                        produkte
                        feature
                        feld
                        tags
                        standard
                        erstelltAm
                        erstelltVon
                    }
                }
                positionen {
                    id
                    obergruppe
                    untergruppe
                    zustand {
                        id
                        kurztext
                        langtext
                        verfuegbarkeit
                        produkte
                        feature
                        feld
                        tags
                        standard
                        erstelltAm
                        erstelltVon
                    }
                    beschaedigungart
                    reparaturweg
                    bilder
                    preis
                }
                beschreibungen {
                    id
                    kurztext
                    langtext
                    verfuegbarkeit
                    produkte
                    feature
                    feld
                    tags
                    standard
                    erstelltAm
                    erstelltVon
                }
                notizen {
                    id
                    createdAt
                    datei
                    sprachnachricht
                    textnachricht
                }
            }
            ausstattung {
                id
                gruppen {
                    id
                    name
                    sonderausstattung
                    teile {
                        id
                        name
                        vorhanden
                        externalServiceId
                    }
                }
                individualaufbauten {
                    id
                    kurztext
                    langtext
                    verfuegbarkeit
                    produkte
                    feature
                    feld
                    tags
                    standard
                    erstelltAm
                    erstelltVon
                }
                notizen {
                    id
                    createdAt
                    datei
                    sprachnachricht
                    textnachricht
                }
            }
            unterlagen {
                id
                schluesselFahrzeug
                schluesselAHK
                fernbedienungStandheizung
                ladekabelEAuto
                positionen {
                    id
                    bezeichnung
                    bilder
                }
                notizen {
                    id
                    createdAt
                    datei
                    sprachnachricht
                    textnachricht
                }
            }
            wartung {
                id
                letzterServiceNichtBekannt
                letzterServiceBei
                letzterServiceFaelligEinheit
                letzterServiceAm
                letzterServiceAmDayHidden
                naechsterServiceFaelligNichtBekannt
                naechsterServiceFaelligIn
                naechsterServiceFaelligEinheit
                naechsterServiceFaelligInZeit
                naechsterServiceFaelligEinheitZeit
                naechsterServiceFaelligAm
                naechsterServiceFaelligAmDayHidden
                naechsterServiceFaelligKosten
                naechsterServiceFaelligExternalId
                naechsterServiceBeschreibung {
                    id
                    kurztext
                    langtext
                    verfuegbarkeit
                    produkte
                    feature
                    feld
                    tags
                    standard
                    erstelltAm
                    erstelltVon
                }
                naechsteHauptAbgasUntersuchungNichtBekannt
                naechsteHauptAbgasUntersuchung
                hauptAbgasUntersuchungFaelligKosten
                naechsteHauptAbgasUntersuchungExternalId
                zahnriemenwechselBei
                zahnriemenwechselFaelligEinheit
                zahnriemenwechselAm
                zahnriemenwechselAmDayHidden
                zahnriemenFaelligKosten
                zahnriemenFaelligExternalId
                positionen {
                    id
                    externalId
                    bezeichnung
                    kosten
                }
                notizen {
                    id
                    createdAt
                    datei
                    sprachnachricht
                    textnachricht
                }
            }
            werte {
                id
                bezugsdatum
                roundValue
                haendlereinkaufswert
                haendlereinkaufswertDrucken
                haendlereinkaufswertNetto
                haendlereinkaufswertNettoDrucken
                haendlerverkaufswert
                haendlerverkaufswertBesteuerung
                haendlerverkaufswertDrucken
                haendlerverkaufswertNetto
                haendlerverkaufswertNettoDrucken
                datHvkNettoDifferenz
                datHvkNettoRegel
                aufwendungen
                aufwendungenDrucken
                aufwendungenDetailsDrucken
                haendlereinkaufswertAbzuege
                haendlereinkaufswertAbzuegeDrucken
                haendlereinkaufswertAbzuegeNetto
                haendlereinkaufswertAbzuegeNettoDrucken
                haendlerverkaufswertAbzuege
                haendlerverkaufswertAbzuegeDrucken
                haendlerverkaufswertAbzuegeNetto
                haendlerverkaufswertAbzuegeNettoDrucken
                manuelleWerteDrucken
                manuelleWerte {
                    bezeichnung
                    wert
                }
                wertAmMarkt
                wertAmMarktBemerkung
                wertAmMarktBemerkungen {
                    id
                    kurztext
                    langtext
                    verfuegbarkeit
                    produkte
                    feature
                    feld
                    tags
                    standard
                    erstelltAm
                    erstelltVon
                }
                restwertAusUnfallschaden
                restwertAusUnfallschadenNetto
                neuwert
                neuwertDrucken
                relativerWert
                relativerWertDrucken
                werterhoehendesZubehoer
                werterhoehenderWartungszustand
                werterhoehenderReparaturzustand
                zweiterRadsatzAnteilig
                externalServicesReferenceId
                notizen {
                    id
                    createdAt
                    datei
                    sprachnachricht
                    textnachricht
                }
            }
            vtiWerte {
                id
                bezugsdatum
                haendlereinkaufswert
                haendlereinkaufswertDrucken
                haendlereinkaufswertNetto
                haendlereinkaufswertNettoDrucken
                haendlerverkaufswert
                haendlerverkaufswertBesteuerung
                haendlerverkaufswertDrucken
                haendlerverkaufswertNetto
                haendlerverkaufswertNettoDrucken
                wertAmMarkt
                wertAmMarktBemerkungen {
                    id
                    kurztext
                    langtext
                    verfuegbarkeit
                    produkte
                    feature
                    feld
                    tags
                    standard
                    erstelltAm
                    erstelltVon
                }
                neuwert
                neuwertDrucken
                relativerWert
                relativerWertDrucken
                externalServicesReferenceId
                notizen {
                    id
                    createdAt
                    datei
                    sprachnachricht
                    textnachricht
                }
            }
            lackmessung {
                id
                messungen {
                    id
                    position
                    bezeichnung
                    dicke
                    ergebnis
                    beschreibung
                    bilder
                    bemerkungen {
                        id
                        kurztext
                        langtext
                        verfuegbarkeit
                        produkte
                        feature
                        feld
                        tags
                        standard
                        erstelltAm
                        erstelltVon
                    }
                }
                gesamtKarosserieVon
                gesamtKarosserieBis
                untersuchungsErgebnis
                beschreibungen {
                    id
                    kurztext
                    langtext
                    verfuegbarkeit
                    produkte
                    feature
                    feld
                    tags
                    standard
                    erstelltAm
                    erstelltVon
                }
                notizen {
                    id
                    createdAt
                    datei
                    sprachnachricht
                    textnachricht
                }
            }
            anhaenge {
                id
                anhaenge {
                    id
                    quelle
                    bezeichnung
                }
            }
            vtiAbschluss {
                id
            }
            cgAbschluss {
                id
                fazit {
                    id
                    kurztext
                    langtext
                    verfuegbarkeit
                    produkte
                    feature
                    feld
                    tags
                    standard
                    erstelltAm
                    erstelltVon
                }
                abschlussBemerkung {
                    id
                    kurztext
                    langtext
                    verfuegbarkeit
                    produkte
                    feature
                    feld
                    tags
                    standard
                    erstelltAm
                    erstelltVon
                }
            }
            acAbschluss {
                id
                acVersendet
            }
            abschluss {
                id
                fazit {
                    id
                    kurztext
                    langtext
                    verfuegbarkeit
                    produkte
                    feature
                    feld
                    tags
                    standard
                    erstelltAm
                    erstelltVon
                }
                abschlussBemerkung {
                    id
                    kurztext
                    langtext
                    verfuegbarkeit
                    produkte
                    feature
                    feld
                    tags
                    standard
                    erstelltAm
                    erstelltVon
                }
            }
            restwertprognose {
                id
                bezugsdatum
                vertragsnummer
                vertragsbeginn
                vertragsende
                vereinbarteKilometer
                tatsaechlicheKilometer
                differenzKilometer
                datDossierId
                restwertNetto
                restwertBrutto
                dokument
            }
            kalkulation {
                id
                bezugsdatum
                dossierId
                reparaturkostenNetto
                reparaturkostenBrutto
                dokument
            }
        }
    }
`;

// Abrechnungen

export const saveAbrechnung = gql`
    mutation SaveAbrechnung($id: ID!, $abrechnungId: ID!, $abrechnung: AbrechnungInput!) {
        saveAbrechnung(id: $id, abrechnungId: $abrechnungId, abrechnung: $abrechnung)
    }
`;

// Vti-Abschluss

export interface SaveVtiAbschlussData {
    saveVtiAbschluss: VtiAbschluss;
}

export const saveVtiAbschluss = gql`
    mutation SaveVtiAbschluss($id: ID!, $vtiAbschluss: VtiAbschlussInput!) {
        saveVtiAbschluss(id: $id, vtiAbschluss: $vtiAbschluss) {
            id
        }
    }
`;

// Cg-Abschluss

export interface SaveCgAbschlussData {
    saveCgAbschluss: CgAbschluss;
}

export const saveCgAbschluss = gql`
    mutation SaveCgAbschluss($id: ID!, $cgAbschluss: CgAbschlussInput!) {
        saveCgAbschluss(id: $id, cgAbschluss: $cgAbschluss) {
            id
            fazit {
                id
                kurztext
                langtext
                verfuegbarkeit
                produkte
                feature
                feld
                tags
                standard
                erstelltAm
                erstelltVon
            }
            abschlussBemerkung {
                id
                kurztext
                langtext
                verfuegbarkeit
                produkte
                feature
                feld
                tags
                standard
                erstelltAm
                erstelltVon
            }
        }
    }
`;

// Ac-Abschluss

export interface SaveAcAbschlussData {
    saveAcAbschluss: AcAbschluss;
}

export const saveAcAbschluss = gql`
    mutation SaveAcAbschluss($id: ID!, $acAbschluss: AcAbschlussInput!) {
        saveAcAbschluss(id: $id, acAbschluss: $acAbschluss) {
            id
            acVersendet
        }
    }
`;

// Abschluss

export interface SaveAbschlussData {
    saveAbschluss: Abschluss;
}

export const saveAbschluss = gql`
    mutation SaveAbschluss($id: ID!, $abschluss: AbschlussInput!) {
        saveAbschluss(id: $id, abschluss: $abschluss) {
            id
            fazit {
                id
                kurztext
                langtext
                verfuegbarkeit
                produkte
                feature
                feld
                tags
                standard
                erstelltAm
                erstelltVon
            }
            abschlussBemerkung {
                id
                kurztext
                langtext
                verfuegbarkeit
                produkte
                feature
                feld
                tags
                standard
                erstelltAm
                erstelltVon
            }
        }
    }
`;

// Auftrag

export interface SaveAuftragData {
    saveAuftrag: Auftrag;
}

export const saveAuftrag = gql`
    mutation SaveAuftrag($id: ID!, $auftrag: AuftragInput!) {
        saveAuftrag(id: $id, auftrag: $auftrag) {
            id
            art
            nummer
            vorgangsnummer
            auftragstyp
            bezugsAuftragsnummer
            kommentar
            erstellungsTag
            erstellungsUhrzeit
            besichtigungsTag
            besichtigungsUhrzeit
            fertigstellungsTag
            fertigstellungsUhrzeit
            bemerkungen {
                id
                kurztext
                langtext
                verfuegbarkeit
                produkte
                feature
                feld
                tags
                standard
                erstelltAm
                erstelltVon
            }
            auftragsbeschreibung {
                id
                kurztext
                langtext
                verfuegbarkeit
                produkte
                feature
                feld
                tags
                standard
                erstelltVon
            }
            besichtigungsbedingungen {
                id
                kurztext
                langtext
                verfuegbarkeit
                produkte
                feature
                feld
                tags
                standard
                erstelltAm
                erstelltVon
            }
            bemerkung
            hinweise {
                id
                name
                vorhanden
            }
        }
    }
`;

export const saveAuftragNotiz = gql`
    mutation SaveAuftragNotiz($id: ID!, $notizId: ID!, $notiz: NotizInput!) {
        saveAuftragNotiz(id: $id, notizId: $notizId, notiz: $notiz)
    }
`;

export const deleteAuftragNotiz = gql`
    mutation DeleteAuftragNotiz($id: ID!, $notizId: ID!) {
        deleteAuftragNotiz(id: $id, notizId: $notizId)
    }
`;

// VTI Auftrag

export interface SaveVtiAuftragData {
    saveVtiAuftrag: VtiAuftrag;
}

export const saveVtiAuftrag = gql`
    mutation SaveVtiAuftrag($id: ID!, $vtiAuftrag: VtiAuftragInput!) {
        saveVtiAuftrag(id: $id, vtiAuftrag: $vtiAuftrag) {
            id
            art
            nummer
            vorgangsnummer
            auftragstyp
            bezugsAuftragsnummer
            bemerkung
        }
    }
`;

export const saveVtiAuftragNotiz = gql`
    mutation SaveVtiAuftragNotiz($id: ID!, $notizId: ID!, $notiz: NotizInput!) {
        saveVtiAuftragNotiz(id: $id, notizId: $notizId, notiz: $notiz)
    }
`;

export const deleteVtiAuftragNotiz = gql`
    mutation DeleteVtiAuftragNotiz($id: ID!, $notizId: ID!) {
        deleteVtiAuftragNotiz(id: $id, notizId: $notizId)
    }
`;

// Wartung

export interface SaveWartungData {
    saveWartung: Wartung;
}

export const saveWartung = gql`
    mutation SaveWartung($id: ID!, $wartung: WartungInput!) {
        saveWartung(id: $id, wartung: $wartung) {
            id
            letzterServiceNichtBekannt
            letzterServiceBei
            letzterServiceFaelligEinheit
            letzterServiceAm
            letzterServiceAmDayHidden
            naechsterServiceFaelligNichtBekannt
            naechsterServiceFaelligIn
            naechsterServiceFaelligEinheit
            naechsterServiceFaelligInZeit
            naechsterServiceFaelligEinheitZeit
            naechsterServiceFaelligAm
            naechsterServiceFaelligAmDayHidden
            naechsterServiceFaelligKosten
            naechsterServiceFaelligExternalId
            naechsterServiceBeschreibung {
                id
                kurztext
                langtext
                verfuegbarkeit
                produkte
                feature
                feld
                tags
                standard
                erstelltAm
                erstelltVon
            }
            naechsteHauptAbgasUntersuchungNichtBekannt
            naechsteHauptAbgasUntersuchung
            hauptAbgasUntersuchungFaelligKosten
            naechsteHauptAbgasUntersuchungExternalId
            zahnriemenwechselBei
            zahnriemenwechselFaelligEinheit
            zahnriemenwechselAm
            zahnriemenwechselAmDayHidden
            zahnriemenFaelligKosten
            zahnriemenFaelligExternalId
            positionen {
                id
                externalId
                bezeichnung
                kosten
            }
        }
    }
`;

export const saveWartungNotiz = gql`
    mutation SaveWartungNotiz($id: ID!, $notizId: ID!, $notiz: NotizInput!) {
        saveWartungNotiz(id: $id, notizId: $notizId, notiz: $notiz)
    }
`;

export const deleteWartungNotiz = gql`
    mutation DeleteWartungNotiz($id: ID!, $notizId: ID!) {
        deleteWartungNotiz(id: $id, notizId: $notizId)
    }
`;

// Unterlagen

export interface SaveUnterlagenData {
    saveUnterlagen: Unterlagen;
}

export const saveUnterlagen = gql`
    mutation SaveUnterlagen($id: ID!, $unterlagen: UnterlagenInput!) {
        saveUnterlagen(id: $id, unterlagen: $unterlagen) {
            id
            schluesselFahrzeug
            schluesselAHK
            fernbedienungStandheizung
            ladekabelEAuto
            positionen {
                id
                bezeichnung
                bilder
            }
        }
    }
`;

export const saveUnterlagenNotiz = gql`
    mutation SaveUnterlagenNotiz($id: ID!, $notizId: ID!, $notiz: NotizInput!) {
        saveUnterlagenNotiz(id: $id, notizId: $notizId, notiz: $notiz)
    }
`;

export const deleteUnterlagenNotiz = gql`
    mutation DeleteUnterlagenNotiz($id: ID!, $notizId: ID!) {
        deleteUnterlagenNotiz(id: $id, notizId: $notizId)
    }
`;

// Fahrzeug
export interface SaveFahrzeugData {
    saveFahrzeug: Fahrzeug;
}

export const saveFahrzeug = gql`
    mutation SaveFahrzeug($id: ID!, $fahrzeug: FahrzeugInput!) {
        saveFahrzeug(id: $id, fahrzeug: $fahrzeug) {
            id
            kennzeichen
            identnummer
            datECode
            typCode
            herstellerCode
            erstzulassung
            letzteZulassung
            laufleistung
            laufleistungEinheit
            laufleistungBeschreibung
            laufleistungen {
                id
                wert
                einheit
                beschreibung
            }
            fahrzeugart
            fahrzeugartManuell
            bauform
            bauformManuell
            hersteller
            modell
            untertyp
            lackcode
            innenausstattung
            polsterfarbe
            polstermaterial
            hubraum
            leistung
            zylinder
            schadstoffklasse
            leergewicht
            zulaessigesGesamtgewicht
            masseLaenge
            masseBreite
            masseHoehe
            anzahlTueren
            anzahlSitze
            antriebstechnologie
            acAntriebstechnologie
            antriebsart
            getriebeart
            anzahlGaenge
            anzahlVorbesitzer
            scheckheftGepflegt
            hvBatterie
            acFahrzeugart
            acBauform
            acLackfarbe
            acLackierung
            acAntriebstechnologie
            acGetriebeart
            acSchadstoffklasse
            acEhemaligeNutzung
            bemerkungen {
                id
                kurztext
                langtext
                verfuegbarkeit
                produkte
                feature
                feld
                tags
                standard
                erstelltAm
                erstelltVon
            }
            fahrzeugExternalServiceReference {
                id
                source
                identifier
                container
                constructionTime
                vxsData
            }
            vinAbfrageErfolgt
        }
    }
`;

export const saveFahrzeugNotiz = gql`
    mutation SaveFahrzeugNotiz($id: ID!, $notizId: ID!, $notiz: NotizInput!) {
        saveFahrzeugNotiz(id: $id, notizId: $notizId, notiz: $notiz)
    }
`;

export const deleteFahrzeugNotiz = gql`
    mutation DeleteFahrzeugNotiz($id: ID!, $notizId: ID!) {
        deleteFahrzeugNotiz(id: $id, notizId: $notizId)
    }
`;

// Werte
export interface SaveWerteData {
    saveWerte: Werte;
}

export const saveWerte = gql`
    mutation SaveWerte($id: ID!, $werte: WerteInput!) {
        saveWerte(id: $id, werte: $werte) {
            id
            bezugsdatum
            roundValue
            haendlereinkaufswert
            haendlereinkaufswertDrucken
            haendlereinkaufswertNetto
            haendlereinkaufswertNettoDrucken
            haendlerverkaufswert
            haendlerverkaufswertDrucken
            haendlerverkaufswertBesteuerung
            haendlerverkaufswertNetto
            haendlerverkaufswertNettoDrucken
            datHvkNettoDifferenz
            datHvkNettoRegel
            aufwendungen
            aufwendungenDrucken
            aufwendungenDetailsDrucken
            haendlereinkaufswertAbzuege
            haendlereinkaufswertAbzuegeDrucken
            haendlereinkaufswertAbzuegeNetto
            haendlereinkaufswertAbzuegeNettoDrucken
            haendlerverkaufswertAbzuege
            haendlerverkaufswertAbzuegeDrucken
            haendlerverkaufswertAbzuegeNetto
            haendlerverkaufswertAbzuegeNettoDrucken
            manuelleWerteDrucken
            manuelleWerte {
                bezeichnung
                wert
            }
            wertAmMarkt
            wertAmMarktBemerkung
            wertAmMarktBemerkungen {
                id
                kurztext
                langtext
                verfuegbarkeit
                produkte
                feature
                feld
                tags
                standard
                erstelltAm
                erstelltVon
            }
            restwertAusUnfallschaden
            restwertAusUnfallschadenNetto
            neuwert
            neuwertDrucken
            relativerWert
            relativerWertDrucken
            werterhoehendesZubehoer
            werterhoehenderWartungszustand
            werterhoehenderReparaturzustand
            zweiterRadsatzAnteilig
            externalServicesReferenceId
        }
    }
`;

export const saveWerteNotiz = gql`
    mutation SaveWerteNotiz($id: ID!, $notizId: ID!, $notiz: NotizInput!) {
        saveWerteNotiz(id: $id, notizId: $notizId, notiz: $notiz)
    }
`;

export const deleteWerteNotiz = gql`
    mutation DeleteWerteNotiz($id: ID!, $notizId: ID!) {
        deleteWerteNotiz(id: $id, notizId: $notizId)
    }
`;

// Werte
export interface SaveRestwertprognoseData {
    saveRestwertprognose: Restwertprognose;
}

export const saveRestwertprognose = gql`
    mutation SaveRestwertprognose($id: ID!, $restwertprognose: RestwertprognoseInput!) {
        saveRestwertprognose(id: $id, restwertprognose: $restwertprognose) {
            id
            bezugsdatum
            vertragsnummer
            vertragsbeginn
            vertragsende
            vereinbarteKilometer
            tatsaechlicheKilometer
            differenzKilometer
            datDossierId
            restwertNetto
            restwertBrutto
            dokument
        }
    }
`;

export const saveRestwertprognoseNotiz = gql`
    mutation SaveRestwertprognoseNotiz($id: ID!, $notizId: ID!, $notiz: NotizInput!) {
        saveRestwertprognoseNotiz(id: $id, notizId: $notizId, notiz: $notiz)
    }
`;

export const deleteRestwertprognoseNotiz = gql`
    mutation DeleteRestwertprognoseNotiz($id: ID!, $notizId: ID!) {
        deleteRestwertprognoseNotiz(id: $id, notizId: $notizId)
    }
`;

// Werte
export interface SaveVtiWerteData {
    saveVtiWerte: VtiWerte;
}

export const saveVtiWerte = gql`
    mutation SaveVtiWerte($id: ID!, $vtiWerte: VtiWerteInput!) {
        saveVtiWerte(id: $id, vtiWerte: $vtiWerte) {
            id
            bezugsdatum
            haendlereinkaufswert
            haendlereinkaufswertDrucken
            haendlereinkaufswertNetto
            haendlereinkaufswertNettoDrucken
            haendlerverkaufswert
            haendlerverkaufswertDrucken
            haendlerverkaufswertBesteuerung
            haendlerverkaufswertNetto
            haendlerverkaufswertNettoDrucken
            wertAmMarkt
            wertAmMarktBemerkungen {
                id
                kurztext
                langtext
                verfuegbarkeit
                produkte
                feature
                feld
                tags
                standard
                erstelltAm
                erstelltVon
            }
            neuwert
            neuwertDrucken
            relativerWert
            relativerWertDrucken
            externalServicesReferenceId
        }
    }
`;

export const saveVtiWerteNotiz = gql`
    mutation SaveVtiWerteNotiz($id: ID!, $notizId: ID!, $notiz: NotizInput!) {
        saveVtiWerteNotiz(id: $id, notizId: $notizId, notiz: $notiz)
    }
`;

export const deleteVtiWerteNotiz = gql`
    mutation DeleteVtiWerteNotiz($id: ID!, $notizId: ID!) {
        deleteVtiWerteNotiz(id: $id, notizId: $notizId)
    }
`;

// Raeder

export interface SaveRaederData {
    saveRaeder: Raeder;
}

export const saveRaeder = gql`
    mutation SaveRaeder($id: ID!, $raeder: RaederInput!) {
        saveRaeder(id: $id, raeder: $raeder) {
            id
            radSaetze {
                id
                lagerort
                rads {
                    id
                    radPosition
                    hersteller
                    reifenTyp
                    radFelge
                    reifenbreite
                    querschnitt
                    bauart
                    felgengroesse
                    tragfaehigkeit1
                    geschwindigkeitsindex
                    zulaessig
                    profil
                    felgenhersteller
                    runflat
                    dot
                }
            }
            notlaufeinrichtung
            notlaufeinrichtungProfiltiefe
            bemerkungen {
                id
                kurztext
                langtext
                verfuegbarkeit
                produkte
                feature
                feld
                tags
                standard
                erstelltAm
                erstelltVon
            }
        }
    }
`;

export const saveRaederNotiz = gql`
    mutation SaveRaederNotiz($id: ID!, $notizId: ID!, $notiz: NotizInput!) {
        saveRaederNotiz(id: $id, notizId: $notizId, notiz: $notiz)
    }
`;

export const deleteRaederNotiz = gql`
    mutation DeleteRaederNotiz($id: ID!, $notizId: ID!) {
        deleteRaederNotiz(id: $id, notizId: $notizId)
    }
`;

// NFZ Raeder

export interface SaveNfzRaederData {
    saveNfzRaeder: NfzRaeder;
}

export const saveNfzRaeder = gql`
    mutation SaveNfzRaeder($id: ID!, $nfzRaeder: NfzRaederInput!) {
        saveNfzRaeder(id: $id, nfzRaeder: $nfzRaeder) {
            id
            radSaetze {
                id
                lagerort
                achsen {
                    id
                    seiten {
                        id
                        achsenRaeder {
                            id
                            position
                            hersteller
                            typ
                            felge
                            reifenbreite
                            querschnitt
                            bauart
                            felgengroesse
                            tragfaehigkeit
                            geschwindigkeitsindex
                            zustand
                            profiltiefe
                            reifenDruck
                            felgenart
                            felgenhersteller
                            runflat
                            dot
                        }
                        seite
                    }
                    bewertung
                }
            }
            notlaufeinrichtung
            notlaufeinrichtungProfiltiefe
            bemerkungen {
                id
                kurztext
                langtext
                verfuegbarkeit
                produkte
                feature
                feld
                tags
                standard
                erstelltAm
                erstelltVon
            }
        }
    }
`;

export const saveNfzRaederNotiz = gql`
    mutation SaveNfzRaederNotiz($id: ID!, $notizId: ID!, $notiz: NotizInput!) {
        saveNfzRaederNotiz(id: $id, notizId: $notizId, notiz: $notiz)
    }
`;

export const deleteNfzRaederNotiz = gql`
    mutation DeleteNfzRaederNotiz($id: ID!, $notizId: ID!) {
        deleteNfzRaederNotiz(id: $id, notizId: $notizId)
    }
`;

// Vorschaden

export interface SaveVorschadenData {
    saveVorschaden: Vorschaden;
}

export const saveVorschaden = gql`
    mutation SaveVorschaden($id: ID!, $vorschaden: VorschadenInput!) {
        saveVorschaden(id: $id, vorschaden: $vorschaden) {
            id
            positionen {
                id
                externalId
                obergruppe
                untergruppe
                art
                reparatur
                bilder
                schadendatum
                schadenhoehe
                wertminderung
                extern
                bestaetigt
            }
        }
    }
`;

export const saveVorschadenNotiz = gql`
    mutation SaveVorschadenNotiz($id: ID!, $notizId: ID!, $notiz: NotizInput!) {
        saveVorschadenNotiz(id: $id, notizId: $notizId, notiz: $notiz)
    }
`;

export const deleteVorschadenNotiz = gql`
    mutation DeleteVorschadenNotiz($id: ID!, $notizId: ID!) {
        deleteVorschadenNotiz(id: $id, notizId: $notizId)
    }
`;

// Schaden

export interface SaveSchadenData {
    saveSchaden: Schaden;
}

export const saveSchaden = gql`
    mutation SaveVorschaden($id: ID!, $schaden: SchadenInput!) {
        saveSchaden(id: $id, schaden: $schaden) {
            id
            positionen {
                id
                externalId
                obergruppe
                untergruppe
                beschaedigungart
                reparaturweg
                bilder
                intensitaet
                preis
                minderwert
                extern
                bestaetigt
            }
            beschreibungen {
                id
                kurztext
                langtext
                verfuegbarkeit
                produkte
                feature
                feld
                tags
                standard
                erstelltAm
                erstelltVon
            }
        }
    }
`;

export const saveSchadenNotiz = gql`
    mutation SaveSchadenNotiz($id: ID!, $notizId: ID!, $notiz: NotizInput!) {
        saveSchadenNotiz(id: $id, notizId: $notizId, notiz: $notiz)
    }
`;

export const deleteSchadenNotiz = gql`
    mutation DeleteSchadenNotiz($id: ID!, $notizId: ID!) {
        deleteSchadenNotiz(id: $id, notizId: $notizId)
    }
`;

// Feststellungen

export interface SaveFeststellungenData {
    saveFeststellungen: Feststellungen;
}

export const saveFeststellungen = gql`
    mutation SaveFeststellungen($id: ID!, $feststellungen: FeststellungenInput!) {
        saveFeststellungen(id: $id, feststellungen: $feststellungen) {
            id
            umfaenge {
                id
                obergruppeName
                obergruppe
                beschreibung {
                    id
                    kurztext
                    langtext
                    verfuegbarkeit
                    produkte
                    feature
                    feld
                    tags
                    standard
                    erstelltAm
                    erstelltVon
                }
            }
            positionen {
                id
                externalId
                obergruppe
                untergruppe
                zustand {
                    id
                    kurztext
                    langtext
                    verfuegbarkeit
                    produkte
                    feature
                    feld
                    tags
                    standard
                    erstelltAm
                    erstelltVon
                }
                beschaedigungart
                reparaturweg
                bilder
                preis
                extern
                bestaetigt
            }
            beschreibungen {
                id
                kurztext
                langtext
                verfuegbarkeit
                produkte
                feature
                feld
                tags
                standard
                erstelltAm
                erstelltVon
            }
        }
    }
`;

export const saveFeststellungenNotiz = gql`
    mutation SaveFeststellungenNotiz($id: ID!, $notizId: ID!, $notiz: NotizInput!) {
        saveFeststellungenNotiz(id: $id, notizId: $notizId, notiz: $notiz)
    }
`;

export const deleteFeststellungenNotiz = gql`
    mutation DeleteFeststellungenNotiz($id: ID!, $notizId: ID!) {
        deleteFeststellungenNotiz(id: $id, notizId: $notizId)
    }
`;

// CgFeststellung

export interface SaveCgFeststellungData {
    saveCgFeststellung: CgFeststellung;
}

export const saveCgFeststellung = gql`
    mutation SaveCgFeststellung($id: ID!, $cgFeststellung: CgFeststellungInput!) {
        saveCgFeststellung(id: $id, cgFeststellung: $cgFeststellung) {
            id
            versicherungsart
            positionenBauteileversichert {
                id
                bezeichnung
                baugruppe
                prueftext
                bilder
            }
            positionenFahrzeugversichert {
                id
                bezeichnung
                baugruppe
                prueftext
                bilder
            }
            verschleissbedingt
            diagnose
            internerKommentar
        }
    }
`;

export const saveCgFeststellungNotiz = gql`
    mutation SaveCgFeststellungNotiz($id: ID!, $notizId: ID!, $notiz: NotizInput!) {
        saveCgFeststellungNotiz(id: $id, notizId: $notizId, notiz: $notiz)
    }
`;

export const deleteCgFeststellungNotiz = gql`
    mutation DeleteCgFeststellungNotiz($id: ID!, $notizId: ID!) {
        deleteCgFeststellungNotiz(id: $id, notizId: $notizId)
    }
`;

// Kalkulation

export interface SaveKalkulationData {
    saveKalkulation: Kalkulation;
}

export const saveKalkulation = gql`
    mutation SaveKalkulation($id: ID!, $kalkulation: KalkulationInput!) {
        saveKalkulation(id: $id, kalkulation: $kalkulation) {
            id
            bezugsdatum
            dossierId
            reparaturkostenNetto
            reparaturkostenBrutto
            dokument
        }
    }
`;

export const saveKalkulationNotiz = gql`
    mutation SaveKalkulationNotiz($id: ID!, $notizId: ID!, $notiz: NotizInput!) {
        saveKalkulationNotiz(id: $id, notizId: $notizId, notiz: $notiz)
    }
`;

export const deleteKalkulationNotiz = gql`
    mutation DeleteKalkulationNotiz($id: ID!, $notizId: ID!) {
        deleteKalkulationNotiz(id: $id, notizId: $notizId)
    }
`;

// Uebersicht

export interface SaveUebersichtData {
    saveUebersicht: Uebersicht;
}

export const saveUebersicht = gql`
    mutation SaveUebersicht($id: ID!, $uebersicht: UebersichtInput!) {
        saveUebersicht(id: $id, uebersicht: $uebersicht) {
            id
            deckblatt
            vorneLinks
            vorneRechts
            hintenLinks
            hintenRechts
            innenraum
            sonstiges {
                id
                bilder
                bezeichnung
                bezeichnungen {
                    id
                    kurztext
                    langtext
                    verfuegbarkeit
                    produkte
                    feature
                    feld
                    tags
                    standard
                    erstelltAm
                    erstelltVon
                }
            }
            fahrzeugVorhanden
        }
    }
`;

export const saveUebersichtNotiz = gql`
    mutation SaveUebersichtNotiz($id: ID!, $notizId: ID!, $notiz: NotizInput!) {
        saveUebersichtNotiz(id: $id, notizId: $notizId, notiz: $notiz)
    }
`;

export const deleteUebersichtNotiz = gql`
    mutation DeleteUebersichtNotiz($id: ID!, $notizId: ID!) {
        deleteUebersichtNotiz(id: $id, notizId: $notizId)
    }
`;
// Fehlteile

export interface SaveFehlteileData {
    saveFehlteile: Fehlteile;
}

export const saveFehlteile = gql`
    mutation SaveFehlteile($id: ID!, $fehlteile: FehlteileInput!) {
        saveFehlteile(id: $id, fehlteile: $fehlteile) {
            id
            positionen {
                id
                externalId
                bezeichnung
                preis
            }
        }
    }
`;

export const saveFehlteileNotiz = gql`
    mutation SaveFehlteileNotiz($id: ID!, $notizId: ID!, $notiz: NotizInput!) {
        saveFehlteileNotiz(id: $id, notizId: $notizId, notiz: $notiz)
    }
`;

export const deleteFehlteileNotiz = gql`
    mutation DeleteFehlteileNotiz($id: ID!, $notizId: ID!) {
        deleteFehlteileNotiz(id: $id, notizId: $notizId)
    }
`;
// Adressen

export const saveAdresse = gql`
    mutation SaveAdresse($id: ID!, $adressId: ID!, $adresse: AdresseInput!) {
        saveAdresse(id: $id, adressId: $adressId, adresse: $adresse)
    }
`;

export const deleteAdresse = gql`
    mutation DeleteAdresse($id: ID!, $adressId: ID!) {
        deleteAdresse(id: $id, adressId: $adressId)
    }
`;

export const saveAdressenNotiz = gql`
    mutation SaveAdressenNotiz($id: ID!, $notizId: ID!, $notiz: NotizInput!) {
        saveAdressenNotiz(id: $id, notizId: $notizId, notiz: $notiz)
    }
`;

export const deleteAdressenNotiz = gql`
    mutation DeleteAdressenNotiz($id: ID!, $notizId: ID!) {
        deleteAdressenNotiz(id: $id, notizId: $notizId)
    }
`;

// Ausstattung

export interface SaveAusstattungData {
    saveAusstattung: Ausstattung;
}

export const saveAusstattung = gql`
    mutation SaveAusstattung($id: ID!, $ausstattung: AusstattungInput!) {
        saveAusstattung(id: $id, ausstattung: $ausstattung) {
            id
            gruppen {
                id
                name
                sonderausstattung
                teile {
                    id
                    name
                    vorhanden
                    externalServiceId
                }
            }
            individualaufbauten {
                id
                kurztext
                langtext
                verfuegbarkeit
                produkte
                feature
                feld
                tags
                standard
                erstelltAm
                erstelltVon
            }
        }
    }
`;

export const saveAusstattungNotiz = gql`
    mutation SaveAusstattungNotiz($id: ID!, $notizId: ID!, $notiz: NotizInput!) {
        saveAusstattungNotiz(id: $id, notizId: $notizId, notiz: $notiz)
    }
`;

export const deleteAusstattungNotiz = gql`
    mutation DeleteAusstattungNotiz($id: ID!, $notizId: ID!) {
        deleteAusstattungNotiz(id: $id, notizId: $notizId)
    }
`;

// Lackmessung

export interface SaveLackmessungData {
    saveLackmessung: Lackmessung;
}

export const saveLackmessung = gql`
    mutation SaveLackmessung($id: ID!, $lackmessung: LackmessungInput!) {
        saveLackmessung(id: $id, lackmessung: $lackmessung) {
            id
            messungen {
                id
                position
                bezeichnung
                dicke
                ergebnis
                beschreibung
                bilder
                bemerkungen {
                    id
                    kurztext
                    langtext
                    verfuegbarkeit
                    produkte
                    feature
                    feld
                    tags
                    standard
                    erstelltAm
                    erstelltVon
                }
            }
            gesamtKarosserieVon
            gesamtKarosserieBis
            untersuchungsErgebnis
            beschreibungen {
                id
                kurztext
                langtext
                verfuegbarkeit
                produkte
                feature
                feld
                tags
                standard
                erstelltAm
                erstelltVon
            }
        }
    }
`;

export const saveLackmessungNotiz = gql`
    mutation SaveLackmessungNotiz($id: ID!, $notizId: ID!, $notiz: NotizInput!) {
        saveLackmessungNotiz(id: $id, notizId: $notizId, notiz: $notiz)
    }
`;

export const deleteLackmessungNotiz = gql`
    mutation DeleteLackmessungNotiz($id: ID!, $notizId: ID!) {
        deleteLackmessungNotiz(id: $id, notizId: $notizId)
    }
`;

export interface SaveProduktartData {
    saveProduktart: Produkt;
}

export const saveProduktart = gql`
    mutation SaveProduktart($id: ID!, $art: Int!) {
        saveProduktart(id: $id, art: $art)
    }
`;

export const saveAnhang = gql`
    mutation SaveAnhang($id: ID!, $anhaengeId: ID!, $anhang: AnhangInput!) {
        saveAnhang(id: $id, anhaengeId: $anhaengeId, anhang: $anhang)
    }
`;

export const deleteAnhang = gql`
    mutation DeleteAnhang($id: ID!, $anhaengeId: ID!) {
        deleteAnhang(id: $id, anhaengeId: $anhaengeId)
    }
`;

export interface CreateTextbausteintData {
    createTextbaustein: Textbaustein;
}

export const createTextbaustein = gql`
    mutation CreateTextbaustein($textbaustein: TextbausteinInput!) {
        createTextbaustein(textbaustein: $textbaustein) {
            id
            kurztext
            langtext
            verfuegbarkeit
            produkte
            feature
            feld
            tags
            standard
            erstelltAm
            erstelltVon
        }
    }
`;

export interface SaveTextbausteintData {
    saveTextbaustein: Textbaustein;
}

export const saveTextbaustein = gql`
    mutation SaveTextbaustein($textbaustein: TextbausteinInput!) {
        saveTextbaustein(textbaustein: $textbaustein) {
            id
            kurztext
            langtext
            verfuegbarkeit
            produkte
            feature
            feld
            tags
            standard
            erstelltAm
            erstelltVon
        }
    }
`;

export interface DeleteTextbausteinData {
    deleteVorschaden: boolean;
}

export const deleteTextbaustein = gql`
    mutation DeleteTextbaustein($id: ID!) {
        deleteTextbaustein(id: $id)
    }
`;
