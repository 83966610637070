import { File as CustomFile, FileUploadImage } from '@app/class/file';
import { FileData } from '@app/class/file-data';
import { FileSize } from '@app/enum/file-size.enum';
import { FileService } from '@app/service/file.service';
import { ProduktDetailFileService } from '@modules/produkt/service/produkt-detail-file.service';
import { ReversibleImage } from '@shared/models/form-field.model';
import { catchError, forkJoin, from, map, Observable, of, retry } from 'rxjs';

export function getDataFromBlob(blob: Blob): Promise<ArrayBuffer> {
    return new Promise<ArrayBuffer>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = event.target.result as ArrayBuffer;
            resolve(data);
        };
        reader.onerror = (error) => reject(error);
        reader.readAsArrayBuffer(blob);
    });
}

export function readFile(file: File): Observable<FileData<ArrayBuffer>> {
    const promise = new Promise<FileData<ArrayBuffer>>((resolve, reject) => {
        getDataFromBlob(file).then(
            (data) => {
                const fileData: FileData<ArrayBuffer> = {
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    data,
                };
                resolve(fileData);
            },
            (error) => reject(error),
        );
    });
    return from(promise);
}

export function getFilesFromEvent(event: Event): Observable<{ url: string; file: CustomFile }[] | undefined> {
    const input = event.target as HTMLInputElement;
    const files = input.files;
    if (!files) return of(undefined);

    const readFiles$: Observable<FileData<ArrayBuffer>>[] = [];

    for (let i = 0; i < files.length; i++) {
        const file = files.item(i);
        if (!file) continue;

        readFiles$.push(readFile(file));
    }

    input.value = '';

    return forkJoin(readFiles$).pipe(
        map((files) => {
            return files.map((file) => {
                const blob = new Blob([file.data], { type: file.type });
                const url = URL.createObjectURL(blob);
                return { url, file: { ...file } };
            });
        }),
    );
}

export function loadImages(
    images: ReversibleImage[],
    fileService: FileService,
): Observable<{ images: FileUploadImage[]; nonDownloadableImages: ReversibleImage[] }> {
    if (!images || images.length === 0) return of({ images: [], nonDownloadableImages: [] });
    const nonDownloadableImages = [];

    const images$ = images.map((image) => {
        const file$ = fileService.get(image.fileId, FileSize.Fullscreen);
        const originalFile$ = image.originalFileId
            ? fileService.get(image.originalFileId, FileSize.Fullscreen)
            : of(undefined);
        return forkJoin([file$, originalFile$]).pipe(
            retry({ delay: 1000, count: 3 }),
            catchError((_err) => {
                nonDownloadableImages.push(image);
                return of(undefined);
            }),
        );
    });

    return forkJoin(images$).pipe(
        map((items) => {
            const filteredItems = items.filter((item) => item !== undefined);
            const downloadedImages = filteredItems.map(([file, originalFile]) => {
                const blob = new Blob([file.data], { type: file.type });
                const url = URL.createObjectURL(blob);
                return { url, file, originalFile } satisfies FileUploadImage;
            });

            return { images: downloadedImages, nonDownloadableImages };
        }),
    );
}

export function saveImages(
    images: (FileUploadImage | undefined)[],
    produktId: string,
    produktDetailFileService: ProduktDetailFileService,
): Observable<ReversibleImage[]> {
    const images$ = images.map((image) => {
        const existingFileId = image?.fileId || image?.file?.id;
        const fileUpload$ = existingFileId ? of(existingFileId) : produktDetailFileService.put(image.file, produktId);

        let originalFileUpload$: Observable<string | undefined> = of(undefined);
        if (image?.originalFile) {
            const existingOriginalFileId = image.originalFileId || image.originalFile?.id;
            originalFileUpload$ = existingOriginalFileId
                ? of(existingOriginalFileId)
                : produktDetailFileService.put(image.originalFile, produktId);
        }

        return forkJoin([fileUpload$, originalFileUpload$]);
    });

    return forkJoin(images$).pipe(
        map((images) => {
            return images.map(([fileId, originalFileId]) => {
                return { fileId, originalFileId };
            });
        }),
    );
}
