import { Injectable } from '@angular/core';
import { File } from '@app/class/file';
import { FileSize } from '@app/enum/file-size.enum';
import { environment } from '@env/environment';
import { Assert } from '@shared/helper/assert';
import * as AWS from 'aws-sdk';
import { from, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { CognitoService } from './cognito.service';

@Injectable({
    providedIn: 'root',
})
export class S3FileService {
    constructor(
        private readonly cognito: CognitoService,
        private readonly authService: AuthService,
    ) {
        Assert.notNullOrUndefined(cognito, 'cognito');
        Assert.notNullOrUndefined(authService, 'authService');
    }

    upload(file: File): Observable<void> {
        Assert.notNullOrUndefined(file, 'file');
        file.name = encodeURIComponent(file.name);
        return this.cognito.secureContext().pipe(
            mergeMap(() => this.authService.getClaims()),
            mergeMap((claims) => {
                const bueroId = claims['custom:buero_id'];

                const uploadPromise = new Promise<void>((resolve, reject) => {
                    const s3 = new AWS.S3({ apiVersion: '2006-03-01' });
                    s3.upload(
                        {
                            Bucket: environment.aws.s3.upload.name,
                            Key: this.getFileUploadKey(bueroId, file),
                            Body: file.data,
                            ContentType: file.type,
                            ContentLength: file.size,
                            Metadata: {
                                name: file.name,
                            },
                        },
                        (error) => {
                            if (error) {
                                reject(error);
                            } else {
                                resolve();
                            }
                        },
                    );
                });
                return from(uploadPromise);
            }),
        );
    }

    download(fileId: string, fileSize: FileSize): Observable<File> {
        Assert.notNullOrEmpty(fileId, 'fileId');
        Assert.notNullOrUndefined(fileSize, 'fileSize');

        return this.cognito.secureContext().pipe(
            mergeMap(() => this.authService.getClaims()),
            mergeMap((claims) => {
                const bueroId = claims['custom:buero_id'];

                const uploadPromise = new Promise<File>((resolve, reject) => {
                    const s3 = new AWS.S3({ apiVersion: '2006-03-01' });
                    s3.getObject(
                        {
                            Bucket: environment.aws.s3.upload.name,
                            Key: this.getFileDownloadKey(bueroId, fileId, fileSize),
                        },
                        (error, data) => {
                            if (error) {
                                reject(error);
                            } else {
                                const file: File = {
                                    data: (data.Body as Uint8Array).buffer,
                                    name: data.Metadata.name,
                                    size: data.ContentLength,
                                    type: data.ContentType,
                                    id: fileId,
                                };
                                resolve(file);
                            }
                        },
                    );
                });
                return from(uploadPromise);
            }),
        );
    }

    downloadEinstellungenImage(fileId: string): Observable<File> {
        Assert.notNullOrEmpty(fileId, 'fileId');

        return this.cognito.secureContext().pipe(
            mergeMap(() => {
                const uploadPromise = new Promise<File>((resolve, reject) => {
                    const s3 = new AWS.S3({ apiVersion: '2006-03-01' });
                    s3.getObject(
                        {
                            Bucket: environment.aws.s3.einstellungen.name,
                            Key: fileId,
                        },
                        (error, data) => {
                            if (error) {
                                reject(error);
                            } else {
                                const file: File = {
                                    data: (data.Body as Uint8Array).buffer,
                                    name: data.Metadata.name,
                                    size: data.ContentLength,
                                    type: data.ContentType,
                                    id: fileId,
                                };
                                resolve(file);
                            }
                        },
                    );
                });
                return from(uploadPromise);
            }),
        );
    }

    private getFileUploadKey(bueroId: string, file: File): string {
        const extension = this.getFileExtension(file.type);
        return `uploads/${bueroId}/${file.id}${extension}`;
    }

    private getFileDownloadKey(bueroId: string, fileId: string, fileSize: FileSize): string {
        const fileName = this.getFileNameBySize(fileSize);
        return `processed/${bueroId}/${fileId}${fileName}`;
    }

    private getFileExtension(contentType: string): string {
        switch (contentType) {
            case 'image/png':
                return '.png';
            case 'image/jpg':
            case 'image/jpeg':
                return '.jpg';
            case 'application/pdf':
                return '.pdf';
            default:
                return '';
        }
    }

    private getFileNameBySize(fileSize: FileSize): string {
        switch (fileSize) {
            case FileSize.Thumbnail:
                return '/240.jpg';
            case FileSize.Fullscreen:
                return '/800.jpg';
            default:
                return '';
        }
    }
}
